import firebase from "./firebase";
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const endpoint = `${process.env.REACT_APP_SHEETS_ENDPOINT}`;
const db = firebase.firestore();

function submit(data) {
  return db.collection("contacts").add({
    name: data.name,
    email: data.email,
    subject: data.subject,
    message: data.message,
    createdAt: serverTimestamp(),
  });

  // return fetch(endpoint, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify([[data.name, data.email, data.message, data.message]]),
  // }).then((r) => r.json());
}

const contact = { submit };

export default contact;
