const Page5 = ({}) => {
  return (
    <div id="page_5">
      <div className="col-md-12 content">
        <p>
          Some third parties such as our business partners and service providers
          provide us with Personal Data about you, such as the following:
        </p>
        <p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            Information from our service providers : We receive information
            collected by third parties about your use of the Services. For
            example, we may use analytics service providers to analyze how you
            interact and engage with the Services and our advertisements, so we
            can learn and make enhancements to offer you a better experience.
            Some of these entities may use Cookies, web beacons and other
            technologies to collect information about your use of the Services
            and other websites, which may include tracking activity across time
            and unaffiliated properties, including your IP address, web browser,
            pages viewed, time spent on pages, links clicked and conversion
            information. Third parties may also help us provide you with
            customer support and provide us with information so that we may help
            you use our Services.
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            Account information for third party services : If you provide your{" "}
            third-party account credentials to us or otherwise sign in to the
            Services through a third party site or service, some content and/or
            information in those accounts (“ Third Party Account Information ”)
            may be transmitted into your account with us if you authorize such
            transmissions. Such Third Party Account Information transmitted to
            our Services is covered by this Privacy Policy, for example,
            information from your public profile, if the third party service and
            your account settings allow such sharing. The information we receive
            will depend on the policies and your account settings with the third
            party service.
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            Information from our advertising partners : We receive information
            about you from some of our service providers who assist us with
            marketing or promotional services related to how you interact with
            our websites, applications, products, services, advertisements or
            communications.
          </p>
        </p>
        <h5>
          <strong className="text-white">
            How Do We Use Your Personal Data?
          </strong>
        </h5>
        <p>
          We process Personal Data to develop, operate, improve, understand and
          personalize our Services. For example, we use Personal Data to:
        </p>

        <p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Communicate with you about the Services, including Service
              announcements, updates or offers
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Provide support and assistance for the Services</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Personalize website content and communications based on your
              preferences
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Recommend products and services that we think may be of interest
              to you
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Create and manage user profiles</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Process orders</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Respond to user inquiries</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Fulfill user requests</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Comply with our legal or contractual obligations</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Resolve disputes</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Protect against or deter fraudulent, illegal or harmful actions
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Enforce our Terms of Use</span>
          </p>
        </p>
      </div>
    </div>
  );
};

export default Page5;
