import PrivacyPage1 from "./Privacy/Page1";
import PrivacyPage2 from "./Privacy/Page2";
import PrivacyPage3 from "./Privacy/Page3";
import PrivacyPage4 from "./Privacy/Page4";
import PrivacyPage5 from "./Privacy/Page5";
import PrivacyPage6 from "./Privacy/Page6";
import PrivacyPage7 from "./Privacy/Page7";
import PrivacyPage8 from "./Privacy/Page8";
import PrivacyPage9 from "./Privacy/Page9";

const PrivacyAndPolicySection = ({}) => {
    return (
        //privacy policy.........
        <div id="menu1" class="tab-pane in active">
            {/* <h3> second tab content here</h3> */}
            <PrivacyPage1/>
            <PrivacyPage2/>
            <PrivacyPage3/>
            <PrivacyPage4/>
            <PrivacyPage5/>
            <PrivacyPage6/>
            <PrivacyPage7/>
            <PrivacyPage8/>
            <PrivacyPage9/>
        </div>
    );
}

export default PrivacyAndPolicySection;