const Page1 = ({classes}) => {
    return (
        <div id="page_1">
            <div className="col-md-12 text-center">
                    <h3 className="terms-heading text-white">Terms & Conditions</h3>
                </div>
                <div className="col-md-12 content">
                    <h5>
                        <strong className="text-white">Effective date: April 30, 2021</strong>
                    </h5>
                    <p className="terms-content">Welcome to GZI. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms
                        or the Services, please contact us at:
                    </p>
                    <p className="terms-content">sean.adler@gzi.finance</p>
                    <p className="terms-content">These Terms of Use (the “Terms”) are a binding contract between you and GZI Hub, Inc. (“GZI,” “we” and “us”). Unless you agree to and accept all of the Terms, you will not have the right to use the Services in any manner. Your usage
                        of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the Privacy Policy
                        and Copyright Dispute Policy. Your use of or participation in certain Copyright Dispute Policy. Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”),
                        including without limitation terms from our third party data providers. Additional Terms are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also
                        comply with these Additional Terms.</p>
                    <p className="terms-content">Please read these Terms carefully. They cover important information about Services provided to you. These Terms include information about future changes to these Terms, automatic renewals, limitations of liability, a class action waiver
                        and resolution of disputes by arbitration instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS, IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE
                        SERVICES IN ANY MANNER.</p>
                    <p className="terms-content">ARBITRATION NOTICE AND CLASS ACTION WAIVER:EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THEARBITRATION AGREEMENT SECTION BELOW,YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND
                        YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT ORCLASS-WIDE ARBITRATION. </p>
                    <h5>
                        <strong className="text-white">What Does This Privacy Policy Cover?</strong>
                    </h5>
                    <p className="terms-content">We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at https://gzihub.com/
                        , send you an email, and/or notify you by some other means.</p>
                    <p className="terms-content">If you do not agree with the new Terms, you are free to reject them. Unfortunately, your rejection of such new Terms means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms
                        is effective, your usage means you agree to all of the changes to the Terms.
                    </p>
                    <p className="terms-content">Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.
                    </p>

                    <h5>
                        <strong className="text-white">What About My Privacy?</strong>
                    </h5>
                    <p className="terms-content">GZI takes the privacy of its users very seriously. For the current GZI Privacy Policy, please click here.</p>
                    <p className="terms-content">Children’s Online Privacy Protection Act </p>
                    <p className="terms-content">The Children’s Online Privacy Protection Act (“ COPPA ”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13 years of
                        age. We do not knowingly collect or solicit personally identifiable information from children under 13 years of age, if you are a child under 13 years of age, please do not attempt to register for or otherwise use the Services
                        or send us any personal information. If we learn we have collected personal information from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age
                        may have provided us personal information, please contact us at [sean.adler@gzi.finance]. </p>

                    <h5>
                        <strong className="text-white">What Are The Basics Of Using GZI?</strong>
                    </h5>
                    <p className="terms-content">You represent and warrant that you are of legal age to form a binding contract.
                    </p>
                    <p className="terms-content">In order to use most of our Services, you will be required to sign up for an account, select a password and username (“GZI User ID”), and provide us with certain information or data, such as your contact information. You promise to
                        provide us with accurate, complete, and updated registration information about yourself. You may not select as your GZI User ID a name that you do not have the right to use, or another person’s name with the intent to impersonate
                        that person. Additionally, you may be able to access certain parts or features of the Services by using your account credentials from other services (each, a “Third-Party Account”), such as those offered by Google and Facebook.
                        By using the Services through a Third-Party Account, you permit us to access certain information from such account for use by the Services. You are ultimately in control of how much information is accessible to us and may exercise
                        such control by adjusting your privacy settings on your Third-Party Account. You may not transfer your account to, or share your access credentials with, anyone else without our prior written permission. You must protect the security
                        of your account and your password and any other access credentials. You are responsible for any activity associated with your account. </p>
                    <p className="terms-content">The Services are only intended for your own individual, internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party, and you shall not use the Services in any other manner. You shall only use
                        the Services in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you are not authorized to use the Services. We will not be responsible for your using the
                        Services in a way that breaks the law.
                    </p>
                    <p className="terms-content">Furthermore, you can only use the Services if you: </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Are subscribed in a personal Capacity and are not currently acting as a registered or qualified professional securities trader or investment advisor with any national or state exchange,
                        regulatory authority, professional association or recognized professional body,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Are not currently acting as an investment advisor or as a trader to the public for the investment of corporate funds, whether or not you may have at some time been qualified to do so,
                        and
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Are not using the Services to fulfil administrative, sales or support functions as an employee of any bank, brokerage or financial institution.
                    </p>
                    <h5>
                        <strong className="text-white">What About Messaging?
                        </strong>
                    </h5>
                    <p className="terms-content">As part of the Services, you may receive communications through the Services, including messages that GZI sends you (for example, via email [or SMS]). [When signing up to receive text messages in connection with the Services, you will
                        receive a welcome message and instructions
                    </p>
                    <p className="terms-content">on how to stop receiving messages. By signing up for the Services and providing us with your wireless number, you confirm that you want GZI to send you information that we think may be of interest to you, which may include GZI using
                        automated dialing technology to text you at the wireless number you provided, and you agree to receive communications from GZI, and you represent and warrant that each person you register for the Services or for whom you provide
                        a wireless phone number has consented to receive communications from GZI.]
                    </p>
                    <p className="terms-content">You agree to indemnify and hold GZI harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of
                        the foregoing.</p>
                    <h5>
                        <strong className="text-white">Are There Restrictions In How I Can Use The Services?
                            </strong>
                    </h5>
                    <p className="terms-content">You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that:</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Infringes or violates the intellectual property rights or any other rights of anyone else (including GZI),</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws, security laws or any other purpose not reasonably intended by GZI,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable,</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Jeopardizes the security of your GZI account or the GZI account of anyone else (including, without limitation, allowing someone else to log in to the Services as you),</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Attempts, in any manner, to obtain the password, account, or other security information from any other user,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Violates the security of any computer network, or cracks any passwords or security encryption codes,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise
                        interferes with the proper working of the Services (including, without limitation, by placing an unreasonable load on the Services’ infrastructure),
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>“Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means),
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Copies or stores any significant portion of the Content,</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Downloads any Content unless expressly permitted by GZI in writing,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Links or hyperlink/hypertext links to the Services or the Content or frames any portion of the Services of Content or otherwise attempts to make the Services or Content appear to be
                        from a different source, </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Is intended to manipulate any market or price, from a different source,</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Is in violation of your employer’s policies, or</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Pertains or relates to any asset class for which you are a proprietary trader for your employer.</p>
                    <p className="terms-content d-flex">A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>
                    <h5>
                        <strong className="text-white">What Are My Rights In The Services?
                            </strong>
                    </h5>
                    <p className="terms-content ">The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, information, financial tools, investment write-ups, newsletters,
                        blog posts, general text, research, analysis, summaries, reports, graphs, User Submissions (as defined below), the selection or arrangement of any of the foregoing, and so forth (all of the foregoing, the “Content”) are protected
                        by copyright and/or other intellectual property laws. You agree to keep all copyright or other proprietary notices intact and abide by all copyright notices, trademark rules, information, and restrictions contained in any Content
                        you access through the Services. You shall not use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content
                        not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including GZI’s) rights. </p>

                    <p className="terms-content ">Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non- sublicensable and non-transferable license to locally view Content solely for purposes of using the Services. Use, reproduction, modification,
                        distribution or storage of any Content for other than purposes of using the Services as set forth in these Terms is expressly prohibited without prior written permission from us. You understand and acknowledge that GZI owns the
                        Services and GZI and GZI’s licensors own all Content provided on or through the Services. You will not modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section),
                        create derivative works based on, or otherwise exploit any of the Services, including without limitation any Content that you do not own.</p>
                    <p className="terms-content ">The Services include access to financial data feeds and other related tools and applications, such as articles, summaries, and newsletters (collectively, “Data Feeds”), including those provided by third parties. In addition to the
                        terms set forth herein, each Data Feed may be subject to its own terms, restrictions and limitations (which shall be included in Additional Terms). Data Feeds provided by third parties may require you to enter into an agreement
                        directly with the third-party</p>
                    <p className="terms-content ">provider of such Data Feed, and you acknowledge and agree that we are not a party to such third- party terms. The terms for any Data Feed you access or use are hereby incorporated into these Terms by reference, and you hereby indemnify
                        and hold GZI harmless from all liability arising out of or relating to any third party terms related to such Data Feeds.</p>

                    <h5>
                        <strong className="text-white">What About Anything I Contribute To The Services – Do I Have To Grant Any Licenses To GZI Or To
                                Other Users? </strong>
                    </h5>
                    <p className="terms-content ">User Submissions</p>
                    <p className="terms-content ">Anything you post, upload, share, store, or otherwise provide through the Services, including, but not limited to, data, information (including financial information about you and your assets), responses, investment write-ups, information,
                        models, projections, profile information, forum posts, comments, user-to-user messages, and any other Content of any kind, is your “User Submission”. Some User Submissions may be viewable by other users. You are solely responsible
                        for all User Submissions you contribute to the Services. You represent, warrant and covenant that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations
                        and these Terms. You acknowledge that all Content, including User Submissions, accessed by you while using the Services is at your own risk and you will be solely responsible for any damage or loss to you or any other party resulting
                        therefrom.
                    </p>
                    <p className="terms-content ">You agree that you will not post, upload, share, store, or otherwise provide through the Services any User Submissions that: (i) infringe any third party’s copyrights or other rights (e.g., trademark, privacy rights, etc.), (ii) contain
                        sexually explicit content or pornography, (iii) contain hateful, defamatory, or discriminatory content or incites hatred against any individual or group, (iv) exploit minors, (v) depict violence or any unlawful acts, (vi) depict
                        animal cruelty or extreme violence towards animals, (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other
                        dubious money-making ventures, (viii) contain any fraudulent or inaccurate information, (ix) is based on your knowledge of any material non-public information or constitute material, non- public information, or (x) that violate
                        any law or agreement.</p>
                    <p className="terms-content ">When you post any User Content, you also agree to abide by the following rules at the time of posting such User Content: (i) you shall disclose the existence of any material long or short position (including without limitation stocks,
                        options or other instruments) in any stock mentioned in any User Content or any intent to change your position with respect to such stock (e.g. buy or sell) in a way that would be material to the market, (ii) you shall not post
                        any User Content regarding a particular stock with the intention to boost or reduce such stock’s price, (iii) you shall disclose any and all material relationships with any companies’ stocks you refer to in any User Content you
                        post and/or any parties that stand to gain in any way from the viewpoint you present in any User Content you post, (iv) you shall not, at any time, post anything that could constitute material non- public information under applicable
                        laws, rules or regulations and (v) you hereby waive any and all rights against us and hold us harmless in connection with any claims relating to any action taken by us as part of an investigation of a suspected violation of these
                        Terms, including without limitation removal of any Content (including User Content) from the Services and/or suspension or termination of your access to the Services.</p>

                    <h5>
                        <strong className="text-white">Licenses</strong>
                    </h5>
                    <p className="terms-content ">In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions (see below for more information). Please note that all of the
                        following licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also your personally identifiable information.
                    </p>
                    <p className="terms-content ">By submitting User Submissions through the Services, you hereby do and shall grant GZI a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate,
                        reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our successors’ and assigns’) businesses, including
                        without limitation for promoting and redistributing part or all of this site or the Services (and derivative works thereof), or for any other existing or future GZI products and services, in any media formats and through any media
                        channels (including, without limitation, third party websites and feeds), and including after your termination of your account or the Services. You also hereby do and shall grant each user of this site and/or the Services a non-exclusive,
                        perpetual license to access and your User Submissions through this site and/or the Services in accordance with the terms herein. For clarity, the foregoing license grants to us and our users do not affect your other ownership or
                        license rights in your User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to
                        us without infringement or violation of any third-party rights or obligations, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, trade secret rights, confidentiality obligations
                        or any other intellectual property or proprietary rights.</p>

                    <p className="terms-content ">Finally, you understand and agree that GZI, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions
                        to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so. We also reserve the right to access, read, preserve, and disclose any information as we
                        reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or, governmental, exchange, self-regulatory organization, market data vendor or employer request, (ii) enforce these Terms, including
                        investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of us, our users
                        and the public.</p>
                    <h5>
                        <strong className="text-white">Can I Share The Content I See On The Services?
                        </strong>
                    </h5>
                    <p className="terms-content ">Certain features of the Services allow you to share information with others, which may include sharing within the Services themselves as well as sharing outside of the Services, such as through your social networks or other Third-Party
                        Accounts. When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a “share” button on or near the Content. If you
                        share information from the Services with others through your Third-Party Accounts, such as your social networks, you authorize GZI to share that information with the applicable Third-Party Account
                    </p>
                    <p className="terms-content ">provider. Please review the policies of any Third-Party Account providers you share information with or through for additional information about how they may use your information. If you redistribute Content, you must be able to edit
                        or delete any Content you redistribute, and you must edit or delete it promptly upon our request. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist,
                        all the restrictions in this section still apply.
                    </p>

                    <h5>
                        <strong className="text-white">What If I See Something On The Services That Infringes My Copyright?
                        </strong>
                    </h5>
                    <p className="terms-content ">You may have heard of the Digital Millennium Copyright Act (the “DMCA”), as it relates to online service providers, like GZI, being asked to remove material that allegedly violates someone’s copyright. We respect others’ intellectual
                        property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged </p>
                    <p className="terms-content ">infringers. To learn more about the DMCA, please visit</p>
                    <p className="terms-content "><a href="http://www.copyright.gov/legislation/dmca.pdf.">http://www.copyright.gov/legislation/dmca.pdf.</a></p>

                    <h5>
                        <strong className="text-white">Who Is Responsible For What I See And Do On The Services?
                        </strong>
                    </h5>
                    <p className="terms-content ">Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk.
                        We cannot guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services. Any user may or may not have a position in any investment described
                        in User Content. GZI has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with
                        through the Services, including without limitation any information provided in Data Feeds. You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary
                        to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current.
                    </p>
                    <p className="terms-content ">The Services may contain links or connections to third-party websites or services that are not owned or controlled by GZI, including without limitation third party Data Feeds. The Services may also contain opinions regarding securities
                        mentioned in our Services or other services, which opinions are made by third parties and not GZI. GZI will not and cannot monitor, verify, censor or edit the content of any third-party site or service, and we are not responsible
                        for any Content, including any data or opinions, provided by any third parties. All Content and performance data made available on or through the Services is supplied by sources we believe to be reliable, but any analysis, calculation,
                        report or opinion based on such information is not guaranteed by us, these sources, the information providers, or any other person or entity, and may not be complete. Quotes about exchanges may be delayed or may not be current,
                        such as if an exchange is closed and quotes only reflect information as of the close of the last day of trading. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and
                        that GZI is not responsible for such risks. All Content on the Services is presented only as of the date published or indicated, and may be superseded by subsequent market events or for other reasons. In addition, you are responsible
                        for setting the cache settings on your browser to ensure you are receiving the most recent data. Your interactions with organizations and/or individuals found on or through the
                    </p>
                    <p className="terms-content ">Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You agree
                        that GZI shall not be responsible or liable for any loss or damage of any sort incurred as a result of any such dealings.
                    </p>
                    <p className="terms-content ">You are responsible for all your activity in connection with the Services. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction. For example, before selling
                        or buying any investment, you should consult with a qualified broker or other financial professional to verify pricing information. Furthermore, past performance is not an indication of future results. We cannot control and have
                        no duty to take any action regarding how you may interpret and use any Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired
                        or not acquired Content through the Services. We aren’t liable for any errors or omissions in any Content or for any damages or loss you might suffer in connection with it.
                    </p>
                    <p className="terms-content ">If there is a dispute between participants on our site or with respect to our Services, or between users and any third party, you agree that GZI is under no obligation to become involved. In the event that you have a dispute with one
                        or more other users, you release GZI, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising
                        out of or in any way related to such disputes and/or our Services. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you
                        visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.
                    </p>
                    <p className="terms-content ">You shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing party does not know or suspect
                        to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
                    </p>

                    <h5>
                        <strong className="text-white">Is GZI A Financial Advisor?
                        </strong>
                    </h5>
                    <p className="terms-content ">GZI is not a registered investment advisor or broker/dealer. The materials and information accessible on or through the Services should be used solely for informational purposes. No Content published as part of the Services constitutes
                        a recommendation that any particular investment, security, portfolio of securities, transaction or investment strategy is tailored to the investment needs of any specific person so it may not be suitable for you. You should not
                        rely solely upon the research or opinions herein for purposes of transacting securities or other investments. You should always conduct your own research and due diligence and obtain professional advice before making any investment
                        decision. You hereby acknowledge and agree that we do not operate the Services as an offer to, or solicitation of, any potential clients or investors for the provision by us of investment management, advisory or any other service.
                        You agree not to construe any Content or materials listed on the Services as tax, legal, insurance or investment advice or as an offer to sell, or as a solicitation of an offer to buy, any security or other financial instrument.
                        None of the GZI Parties (defined below) nor any other users will be liable for any loss or damage caused by reliance on any information obtained through or from the Services or any Content (including without limitation any User
                        Content). NO GUARANTEE CAN BE MADE IF YOU INVEST BASED ON THE INFORMATION PROVIDED ON OR THROUGH THE SERVICES.
                    </p>
                    <h5>
                        <strong className="text-white">Will GZI Ever Change The Services?
                        </strong>
                    </h5>
                    <p className="terms-content ">We are always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or
                        all of the Services. We will try to give you notice when we make a material change to the Services that would adversely affect you, but this is not always practical. We reserve the right to remove any Content from the Services
                        at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.
                    </p>

                    <h5>
                        <strong className="text-white">Do The Services Cost Anything?
                        </strong>
                    </h5>
                    <p className="terms-content ">The Services may be free or we may charge a fee for using the Services. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using
                        such Services, you must pay all applicable fees for such Services. Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility.
                        You should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the Services.
                    </p>

                    <h5>
                        <strong className="text-white">What If I Want To Stop Using The Services?
                        </strong>
                    </h5>
                    <p className="terms-content ">You are free to stop using the Services at any time by contacting us at [sean.adler@gzi.finance], please refer to our Privacy Policy,as well as the licenses above, to understand how we treat information you provide to us after you have stopped
                        using our Services.
                    </p>
                    <p className="terms-content ">GZI is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. GZI has the sole right to decide whether you are in violation of
                        any of the restrictions set forth in these Terms.
                    </p>
                    <p className="terms-content ">Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.
                    </p>
                    <p className="terms-content ">If you have deleted your account by mistake, contact us immediately at [sean.adler@gzi.finance]. We will try to help, but unfortunately, we cannot promise that we can recover or restore anything.
                    </p>
                    <p className="terms-content ">Upon termination of your account by you or us at any time, or as otherwise required by us in our sole discretion, you shall immediately purge, delete and destroy any Content or information obtained from the Services from your systems
                        or otherwise in your control, whether in tangible, intangible or electronic form.
                    </p>
                    <p className="terms-content ">Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations
                        on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.
                    </p>
                    <h5>
                        <strong className="text-white">What About Mobile Applications?
                        </strong>
                    </h5>
                    <p className="terms-content ">You acknowledge and agree that the availability of our mobile application is dependent on the third-party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an
                        “App Store”). Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple App Store set forth below. You agree
                        to comply with, and your license to use our application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than,
                        or otherwise conflict with, the terms and conditions of these Terms of Service, the more restrictive or conflicting terms and conditions in these Terms of Service apply.
                    </p>
                    <h5>
                        <strong className="text-white">I use the GZI App available via the Apple App Store – should I know anything about that?
                        </strong>
                    </h5>
                    <p className="terms-content ">These Terms apply to your use of all the Services, including the iOS applications (the “Application”) available via the Apple, Inc. (“Apple”) App Store, but the following additional terms also apply to the Application:
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Both you and GZI acknowledge that the Terms are concluded between you and GZI only, and not with Apple, and that Apple is not responsible for the Application or the Content,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>The Application is licensed to you on a limited, non-exclusive, non-transferrable, non- sublicensable basis, solely to be used in connection with the Services for your private, personal,
                        non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>You will only use the Application in connection with an Apple device that you own or control,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure, upon notification, Apple’s
                        sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>You acknowledge and agree that GZI, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application infringes that third party’s intellectual property
                        rights, GZI, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terroristsupporting” country,
                        and that you are not listed on any U.S. Government list of prohibited or restricted parties,
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Both you and GZI acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party terms of agreement which may affect or be affected by such
                        use, and
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Both you and GZI acknowledge and agree that Apple and Apple’s subsidiaries are third- party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have
                        the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.
                    </p>
                    <h5>
                        <strong className="text-white">Can I Refer Other Users?
                        </strong>
                    </h5>
                    <p className="terms-content">From time-to-time GZI may offer special offers for referring other users to the Services. In order to participate in such special offers, a referring user (“Referrer”) must log in to their account and receive a custom link for the
                        special offer. Referrer may forward the custom link to any number of individuals who are not presently registered users of the Services (“Referee”). A registered user is someone who already has an existing account with GZI. There
                        is no limit to the number of referrals that Referrer can make, nor the cumulative credits that the Referrer may receive through such special offer, unless otherwise indicated. For each Referee that follows the custom link sent
                        by the Referrer and registers for the Services, and then makes a qualifying purchase using that newly created account, GZI will automatically credit the Referrer’s account with the specific amount listed in the particular special
                        offer. All Referees must be first-time recipients of the offer to join the Services, and multiple referrals to the same individual will be disregarded. GZI reserves the right to revoke from Referrer and Referee the special offer
                        at GZI’s discretion for any reason or for no reason whatsoever. Unless otherwise specified on the Services, all credits issued pursuant to such special offers, for both Referrer and Referee, shall automatically expire after 12
                        months if not used. In order to be eligible to receive special offers, GZI may require Referrer or Referee to have a valid credit card on file in that Referrer or Referee account. If GZI determines that Referrer or Referee is attempting
                        to obtain unfair advantage or otherwise violate the terms or spirit of such special offer, GZI reserves the right to (a) revoke any credits issued to either Referrer or Referee and/or (b) charge the Referrer’s or Referee’s credit
                        card for any credits used by Referrer or Referee prior to such revocation and for any credits issued by GZI to any ineligible Referrer or Referee. GZI reserves the right to modify or terminate any special offers at any time. All
                        special offers are subject to any other terms, conditions and restrictions set forth on the Services or presented in connection with the special offer.
                    </p>

                    <h5>
                        <strong className="text-white">What Else Do I Need To Know?
                        </strong>
                    </h5>
                    <p className="terms-content">Investment Risks. You acknowledge that an investment in any security or financial instrument is subject to a number of risks, and that discussions or information about any security or financial instrument published on the Services
                        will not contain a list or description of relevant risk factors. Trading in securities (including, without limitation, stocks, options, ETFs and bonds) involves risk and volatility and can result in immediate and substantial losses
                        of the money invested. Past results are not necessarily indicative of future performance. It is recommended that you only invest funds that are not allocated for other purposes, such as retirement savings, student loans, mortgages,
                        education, or debt reduction. You acknowledge that your investment decisions or recommendations are made entirely at your election.
                    </p>
                    <p className="terms-content">Risks of Investing in Stock and ETFs. Investments always entail some degree of risk. Be aware that:</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Some investments in stock cannot easily be sold or converted to cash. Check to see if there is any penalty or charge if you must sell an investment quickly.
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Investments in stock issued by a company with little or no operating history or published information involves greater risk than investing in a public company with an operating history
                        and extensive public information.


                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Stock investments are not federally insured against a loss in market value.

                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Stock you own may be subject to tender offers, mergers, reorganizations, or third-party actions that can affect the value of your ownership interest. Pay careful attention to public
                        announcements and information sent to you about such transactions. They involve complex investment decisions. Be sure you fully understand the terms of any offer to exchange or sell your shares before you act. In some cases, such
                        as partial or two-tier tender offers, failure to act can have detrimental effects on your investment.

                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>The greatest risk in buying shares of stock is having the value of the stock fall to zero. On the other hand, the risk of selling stock short can be substantial. “Short selling” means
                        selling stock that the seller does not own, or any sale that is completed by the delivery of a security borrowed by the seller. Short selling is a legitimate trading strategy, but assumes that the seller will be able to buy the
                        stock at a more favorable price than the price at which they sold short. If this is not the case, then the seller will be liable for the increase in price of the shorted stock, which could be substantial.

                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Investors should consider the investment objectives and unique risk profile of an ETF carefully before investing. ETFs are subject to risks similar to those of other diversified portfolios.
                        Leveraged and Inverse ETFs may not be suitable for all investors and may increase exposure to volatility through the use of leverage, short sales of securities, derivatives and other complex investment strategies. Although ETFs
                        are designed to provide investment results that generally correspond to the performance of their respective underlying indices, they may not be able to exactly replicate the performance of the indices because of expenses and other
                        factors. A prospectus contains this and other information about the ETF and should be read carefully before investing. Customers should obtain prospectuses from issuers and/or their third party agents who distribute and make prospectuses
                        available for review. ETFs are required to distribute portfolio gains to shareholders at year end. These gains may be generated by portfolio rebalancing or the need to meet diversification requirements. ETF trading will also generate
                        tax consequences.
                    </p>
                    <p className="terms-content "><strong>Service Availability.</strong>We will try to make the Services available at all times, however, the Services may be subject to unavailability for a variety reasons, including without limitation emergencies, interference, service
                        failures, equipment, network problems or other factors beyond our control. Delays or omissions may occur, and we are not responsible for data, messages or Content that is lost, not delivered, delayed or misdirected. The accuracy
                        and timeliness of data received is not guaranteed.
                    </p>
                    <p className="terms-content "><strong>Warranty Disclaimer. </strong>GZI and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives
                        and agents, and each of their respective successors and assigns (GZI and all such parties together, the “GZI Parties”) make no representations or warranties concerning the Services, including without limitation regarding any Content
                        contained in or accessed through the Services, and the GZI Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any
                        claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of or in any way related to your participation in or use of the Services. The GZI Parties make no representations or warranties regarding suggestions
                        or recommendations of services or products offered or purchased through or in connection with the Services. THE SERVICES AND CONTENT ARE PROVIDED BY GZI (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES
                        OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
                        SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL ANY OF THE GZI PARTIES BE LIABLE FOR ANY INVESTMENT GAIN/LOSS YOU INCUR DURING YOUR INVESTMENT
                        IN THE STOCK MARKET.
                    </p>
                    <p className="terms-content "><strong>Limitation of Liability. </strong>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF
                        THE GZI PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF
                        GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR
                        (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO GZI IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION
                        OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
                    </p>
                    <p className="terms-content "><strong>Indemnity. </strong>To the fullest extent allowed by applicable law, you agree to indemnify and hold the GZI Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and
                        expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms.
                        In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce
                        your indemnification obligations hereunder).
                    </p>
                    <p className="terms-content "><strong>Assignment. </strong>You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without GZI’s prior written consent.
                        We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
                    </p>
                    <p className="terms-content "><strong>Choice of Law. </strong>These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of Colorado, without regard to the conflicts of laws provisions
                        thereof.
                    </p>
                    <p className="terms-content "><strong>Arbitration Agreement. </strong>Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with GZI and limits the manner in which you can seek relief from GZI.
                        Both you and GZI acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, GZI’s officers, directors, employees and independent contractors (“Personnel”) are third-party
                        beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.
                    </p>
                    <p className="terms-content "><strong>(a) . </strong>Arbitration Rules, Applicability of Arbitration Agreement. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of
                        these Terms directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in
                        Denver, Colorado. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience
                        in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator
                        may be entered in any court of competent jurisdiction.
                    </p>
                    <p className="terms-content "><strong>(b) . </strong>Costs of Arbitration. The Rules will govern payment of all arbitration fees. GZI will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. GZI will not seek its attorneys’ fees
                        and costs in arbitration unless the arbitrator determines that your claim is frivolous.
                    </p>
                    <p className="terms-content "><strong>(c) . </strong>Small Claims Court, Infringement. Either you or GZI may assert claims, if they qualify, in small claims court in Denver, Colorado or any United States county where you live or work. Furthermore, notwithstanding
                        the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement,
                        misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights.
                    </p>
                    <p className="terms-content "><strong>(d) . </strong>Waiver of Jury Trial. YOU AND GZI WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and GZI are instead choosing to have claims and disputes resolved
                        by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and GZI over whether
                        to vacate or enforce an arbitration award, YOU AND GZI WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
                    </p>
                    <p className="terms-content "><strong>(e) . </strong>Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE
                        THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither
                        you nor GZI is entitled to arbitration, instead all claims and disputes will be resolved in a court as set forth in (g) below.
                    </p>
                    <p className="terms-content "><strong>(f) . </strong>Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: [______________] postmarked within 30 days of first accepting
                        these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.
                    </p>
                    <p className="terms-content "><strong>(g) . </strong>Exclusive Venue. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or GZI to litigate any dispute arising out of or relating to the
                        subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and GZI agree that any judicial proceeding (other than small claims actions) will be brought in the state
                        or federal courts located in, respectively, Denver, Colorado, or the federal district in which that county falls.
                    </p>
                    <p className="terms-content "><strong>(h) . </strong>Severability. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration
                        Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with GZI.
                    </p>
                    <p className="terms-content "><strong>(i) . </strong>Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided
                        that the GZI may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further
                        rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force
                        and effect and enforceable. You and GZI agree that these Terms are the complete and exclusive statement of the mutual understanding between you and GZI, and that these Terms supersede and cancel all previous written and oral agreements,
                        communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of GZI, and you do not have any authority of
                        any kind to bind GZI in any respect whatsoever.
                    </p>
                    <p className="terms-content ">Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and GZI agree there are no third-party beneficiaries intended under these Terms.
                    </p>
                </div>
        </div>
    );
}

export default Page1;