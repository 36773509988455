import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import dataFormat from "../data/json/crypto_format.json";
import Slider from "react-slick";
import { connect } from "react-redux";  
import { useAuth } from "./../util/auth.js";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Chart from "../components/Chart";
import trading_currency_banner from "../assets/images/trading_currency_banner.jpg";
import TabulatorTable from "./TabulatorTable";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    color: "gray",
  },
}));

function ContentCardJsonData(props) {
  const classes = useStyles();
  const [data, setData] = useState([])
 
  useEffect(() => {
    props.removeData({})
    let dataJson = [{}];
    let datakey = [];
    dataFormat.map((key, index_2) => {
      datakey.push(key);
    });
    props.data.map((item, index) => {
      dataJson[index] = {};
        Object.entries(item).map((key, index_1) => {
          //if (index_1 < 11) {
          dataJson[index][datakey[index_1]] = { label: key[0], value: key[1] };
          //}
        })
    })
    setData(dataJson);
    props.saveData(dataJson[0])
  }, [props]);

  const setItem = async (item) => {
    props.saveData(item);
  };
  
  return (
        <> 
          <div class="col-left col-md-6">
              <div class="left-c">
          {data.length > 0 &&
              data.map((item,index)=>
              <Accordion className="accordion" style={{borderRadius:"6px"}} 
             >
              
              <AccordionDetails  onClick={()=>{
                setItem(item);
              }} >
              <div class="table IBSBVL">
                  <table style={{tableLayout:"fixed", width:'100%'}}>
                          <tbody><tr>
                            <th>STOCK EXCHANGE</th>
                            <th>SECTOR</th>
                            <th>CURRENCY</th>
                            <th>INDUSTRY</th>
                          </tr>
                          <tr style={{wordBreak:"break-word"}}>
                            <td>{item.exchange.value}</td>
                              <td>{item.trading_currency.value}</td>
                              <td>{item.industry.value}</td>
                              <td> {item.category.value}</td>
                          </tr>


                        </tbody></table>
                      </div>
              </AccordionDetails>
            </Accordion>)}
            </div>
            </div>
          <div class="col-right col-md-6">
            {data.length>0 &&
              <TabulatorTable data={data} show={true} interactiveBroker={props.interactiveBroker} 
              render={props.render}/> 
            }
          </div>
        </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveData: (data) => dispatch({ type: "SAVE_DATA", payload: data }),
    removeData: (data) => dispatch({ type: "REMOVE_DATA", payload: data })
  };
};

export default connect(null, mapDispatchToProps)(ContentCardJsonData);
