import section1 from "../../assets/images/logo.png";
import section2 from "../../assets/images/glg.svg";
import section3 from "../../assets/images/binance_logo.svg";
import section4 from "../../assets/images/wefunder_logo1.svg";
import section5 from "../../assets/images/digital ocean.png";
import section6 from "../../assets/images/glg.svg";
import section7 from "../../assets/images/binance_logo.svg";
import section8 from "../../assets/images/digital ocean.svg";
import section9 from "../../assets/images/guide.svg";
import section10 from "../../assets/images/nasdaq_logo1.svg";
import section11 from "../../assets/images/netcapital_logo.svg";
import section12 from "../../assets/images/wefunder_logo1.svg";
import section13 from "../../assets/images/founder-select-portfolio.svg";
import section14 from "../../assets/images/asset_img1.png";
import section15 from "../../assets/images/asset_img3.png";
import section16 from "../../assets/images/asset_img2.png";
import section17 from "../../assets/images/asset_img3.png";
import section18 from "../../assets/images/woman.png";
import section19 from "../../assets/images/block_img1.png";
import section20 from "../../assets/images/block_img2.png";
import section21 from "../../assets/images/block_img3.png";
import section22 from "../../assets/images/block_img4.png";
import section23 from "../../assets/images/broker_img.png";
import section24 from "../../assets/images/news1.png";
import section25 from "../../assets/images/news2.png";
import section26 from "../../assets/images/blog1.png";
import section27 from "../../assets/images/blog2.png";
import section28 from "../../assets/images/news3.png";
import section29 from "../../assets/images/news4.png";
import section30 from "../../assets/images/Logo_footer.png";
import section31 from "../../assets/images/caret1.png";
import section32 from "../../assets/images/blog4.png";
import section33 from "../../assets/images/blog3.png";
import section34 from "../../assets/images/news5.png";

import person1 from "../../assets/images/person1.jpeg";
import person2 from "../../assets/images/person2.jpeg";
import person3 from "../../assets/images/person3.jpeg";
import person4 from "../../assets/images/person4.jpeg";
import person5 from "../../assets/images/person5.jpeg";
import person6 from "../../assets/images/person6.jpeg";
import person7 from "../../assets/images/person7.jpeg";
import person8 from "../../assets/images/person8.jpeg";
import person9 from "../../assets/images/person9.jpeg";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/145x114_inches.svg";
import logo from "../../assets/images/156x114.png";

import Slider from "react-slick";

const Section = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const testimonials = [
    {
      content: `GZI's proprietary strategies enabled me to achieve a
        return of 56.05% on my portfolio from October 2019 to
        March 2021. I'll definitely be conserving his methods
        for future decisions.`,
      name: `Shawn C.`,
      designation: `Real Estate Entrapreneur`,
      personImageSrc: person1,
    },
    {
      content: `I met Sean at dinner with some friends back in November
        2019 where he introduced us to his company and its
        trading methodology. When I asked for a name to invest
        in, he mentioned Moderna (MRNA) as a solid pharma
        company. At the time, it was trading at $18. My husband
        and I talk about Sean every time we see the stock
        achieve new highs.`,
      name: `Wonkyung Lee`,
      designation: `Head of Macro Research, FJ Capital Management`,
      personImageSrc: person2,
    },
    {
      content: `Looking forward to checking out your next interviews.`,
      name: `Marion Jumeau`,
      designation: `Digital Marketing Entrapreneur`,
      personImageSrc: person3,
    },
    {
      content: `This is such an incredibly great interview!`,
      name: `Shona McEnroe`,
      designation: `Managing Director at Summit Coaching`,
      personImageSrc: person4,
    },
    {
      content: `This is fresh and bold mate!`,
      name: `Mark Vinch`,
      designation: `Account Executive, Everything Benefits`,
      personImageSrc: person5,
    },
    {
      content: `Thank you for featuring GZI, they’re awesome!`,
      name: `Chris Bolger, PhDC`,
      designation: `Professional Speaker, Entrapreneur`,
      personImageSrc: person6,
    },
    {
      content: `Not surprised! Well deserved feature GZI!`,
      name: `Cherf Eddine Zareb`,
      designation: `Call Center Supervisor, SDA Sonelgaz`,
      personImageSrc: person7,
    },
    {
      content: `Thanks for sharing this amazing interview.`,
      name: `Jo Joshi`,
      designation: `Founder of DMC Finder, Entrapreneur`,
      personImageSrc: person8,
    },
    {
      content: `Your story is awesome!!`,
      name: `Stefan Repin`,
      designation: `Fractional CMO, Luck Boosters`,
      personImageSrc: person9,
    },
  ];
  return (
    <>
      <head>
        <meta httpEquiv='Content-Type' content='text/html; charset=UTF-8' />
        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0'
        />

        {/* stylesheet*/}
        <link rel='stylesheet' type='text/css' href='css/style.css' />
        {/* //stylesheet*/}

        <meta data-react-helmet='true' name='docsearch:version' content='2.0' />
        <meta
          data-react-helmet='true'
          name='viewport'
          content='width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover,user-scalable=0'
        />

        {/* Google fonts*/}
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'
          rel='stylesheet'
        />
        {/* //Google fonts*/}

        {/* Font Awesome*/}
        {/* <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css'
          integrity='sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=='
          crossOrigin='anonymous'
          referrerpolicy='no-referrer'
        />
        <link
          rel='stylesheet'
          href='https://pro.fontawesome.com/releases/v5.10.0/css/all.css'
          integrity='sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p'
          crossOrigin='anonymous'
        /> */}

        {/* //Font Awesome*/}

        {/* Bootstrap*/}
        <link
          rel='stylesheet'
          href='https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css'
        />
        {/* //Bootstrap*/}

        {/* owl carousel*/}
        {/* <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.css'>*/}
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css'
        />
        {/* //owl carousel*/}

        {/* owl carousel in mobile*/}
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css'
        />
        {/* //owl carousel in mobile*/}

        <>
          <script src='https://code.jquery.com/jquery-2.2.4.min.js'></script>

          <script src='https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js'></script>
          <script src='https://bfintal.github.io/Counter-Up/jquery.counterup.min.js'></script>
          <script type='text/javascript' src='js/counter.js'></script>

          <script src='https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.slim.min.js'></script>
          <script src='https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js'></script>
          <script src='https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js'></script>

          {/* custom js*/}
          <script type='text/javascript' src='js/tabs_js.js'></script>
          <script type='text/javascript' src='js/sticky_header.js'></script>
          {/* <script src='https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js'></script>*/}
          <script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'></script>
          <script
            type='text/javascript'
            src='js/testimonial_carousel.js'
          ></script>
        </>
      </head>

      <section className='main_page_section home_bg all_main_sec_padding'>
        <section className='banner_sec home_banner'>
          <div className='container box-container'>
            <div className='main_banner_sec'>
              <h1>Conquer The Market with Alternative Data</h1>
              <p>
                GZI is a pioneer in alternative data that produces gains
                consistently on par with the top hedge funds around the world.
              </p>
              <a href='/content' className='explore_btn'>
                Explore More
              </a>
            </div>
          </div>
          <div className='partners_logo_box container-fluid box-container'>
            {/* mobile view*/}
            <div className='row partners_logo_box_mob'>
              <div className='col-6 col-sm-3 partners_logo_col_mob'>
                <a href='#'>
                  <img src={section2} className='img-fluid' />
                </a>
              </div>
              <div className='col-6 col-sm-3 partners_logo_col_mob'>
                <a href='#'>
                  <img src={section3} className='img-fluid' />
                </a>
              </div>
              <div className='col-6 col-sm-3 partners_logo_col_mob'>
                <a href='#'>
                  <img src={section4} className='img-fluid' />
                </a>
              </div>
              <div className='col-6 col-sm-3 partners_logo_col_mob'>
                <a href='#'>
                  <img src='{section5}' className='img-fluid' />
                </a>
              </div>
            </div>
            <ul className='logo_ul'>
              <li>
                <a href='#'>
                  <img src={section6} className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='#'>
                  <img src={section7} className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='#'>
                  <img src={section12} className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='#'>
                  <img src={section8} className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='#'>
                  <img src={section9} className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='#'>
                  <img src={section10} className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='#'>
                  <img src={section11} className='img-fluid' />
                </a>
              </li>

              <li>
                <a href='#'>
                  <img src={section13} className='img-fluid' />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className='news_articles_sec'>
          <div className='container box-container'>
            <div className='news_row'>
              <div className='news_art_hding'>
                <h2>Latest News and Articles</h2>
              </div>

              <div className='news_art_block'>
                {/* mobile view*/}
                <div className='view_in_mob'>
                  <div
                    id='newsArt_carousel_mob'
                    className='carousel slide'
                    data-ride='carousel'
                  >
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <div className='news_art_div'>
                          <a
                            href='https://www.forbes.com/sites/theyec/2022/09/26/4-things-to-know-before-partnering-with-a-large-corporation/?sh=29c8b1ee2795'
                            className='news_a'
                          >
                            <img src={section25} />
                            <div className='news_description'>
                              <div className='badge'>
                                <span>News</span>
                              </div>
                              <p>
                                Forbes: 4 Things to Know Before Partnering with
                                a Large Corporation
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className='carousel-item'>
                      <div className='news_art_div'>
                        <a
                          href='https://uk-podcasts.co.uk/podcast/nalu-fm-finance/episode-39-sean-adler-exploring-quantitative-inves'
                          className='news_a'
                        >
                          <img src={section27} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>
                            <p>
                            Nalu Finance: Exploring Quantitative Investment Strategies - From Smart Beta to AI Integration
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='carousel-item'>
                      <div className='news_art_div'>
                        <a href='https://www.marketwatch.com/press-release/rise-of-alternative-data-fintech-innovation-with-sean-adler-of-gzi-finance-897b094e' className='news_a'>
                          <img src={section32} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>
                            <p>MarketWatch: Rise of Alternative Data</p>
                          </div>
                        </a>
                      </div>
                    </div>
                      <div className='carousel-item'>
                      <div className='news_art_div'>
                        <a
                          href='https://finance.yahoo.com/news/rise-alternative-data-fintech-innovation-121000142.html'
                          className='news_a'
                        >
                          <img src={section26} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>
                            <p>Yahoo Finance: Disruption Interruption</p>
                          </div>
                        </a>
                      </div>
                    </div>
                      <div className='carousel-item'>
                        <div className='news_art_div'>
                          <a
                            href='https://bit.ly/valiantceo'
                            className='news_a'
                          >
                            <img src={section28} />
                            <div className='news_description'>
                              <div className='badge'>
                                <span>News</span>
                              </div>
                              <p>
                                Valiant CEO: Translating Scientific Innovation
                                into Financial Technology
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className='carousel-item'>
                      <div className='news_art_div'>
                        <a href='https://www.forbes.com/sites/theyec/2023/01/05/how-to-utilize-simultaneous-competing-buyand-sell-side-contracts' className='news_a'>
                          <img src={section26} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>
                            <p>Forbes: How to Use Competing Buy and Sell Side Contracts</p>
                          </div>
                        </a>
                      </div>
                    </div>
                      <div className='carousel-item'>
                        <div className='news_art_div'>
                          <a
                            href='https://bit.ly/gzi_omnicron'
                            className='news_a'
                          >
                            <img src={section33} />
                            <div className='news_description'>
                              <div className='badge'>
                                <span>News</span>
                              </div>
                              <p>
                                How Alternative Data Predicted the Cure for
                                Omnicron
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='carousel-item'>
                      <div className='news_art_div'>
                        <a href='https://www.forbes.com/sites/theyec/2023/08/09/4-things-late-seed-companies-should-know-about-the-secondary-market/' className='news_a'>
                          <img src={section26} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>
                            <p>Forbes: 4 Things Late Seed Companies Should Know About The Secondary Market</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='news_art_div'>
                          <a
                            href='https://bit.ly/gzi_gemstones_from_space'
                            className='news_a'
                          >
                            <img src={section27} />
                            <div className='news_description'>
                              <div className='badge'>
                                <span>Blog</span>
                              </div>
                              <p>
                                Pharmaceuticals and Gemstones from Space Galore
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    <div className='carousel-item'>
                        <div className='news_art_div'>
                          <a
                            href='https://bit.ly/beyondexclamation'
                            className='news_a'
                          >
                            <img src={section34} />
                            <div className='news_description'>
                              <div className='badge'>
                                <span>News</span>
                              </div>
                              <p>
                                Beyond Exclamation: Top 10 Most Influential
                                Leaders in Biotechnology 2021
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>

                    <a
                      className='carousel-control-prev'
                      href='#newsArt_carousel_mob'
                      role='button'
                      data-slide='prev'
                    >
                      <span
                        className='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a
                      className='carousel-control-next'
                      href='#newsArt_carousel_mob'
                      role='button'
                      data-slide='next'
                    >
                      <span
                        className='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
                {/* //desktop view*/}

                <div className='hide_in_mob'>
                  <Slider {...settings}>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://www.forbes.com/sites/theyec/2022/09/26/4-things-to-know-before-partnering-with-a-large-corporation/?sh=29c8b1ee2795'
                          className='news_a'
                        >
                          <img src={section25} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>
                              Forbes: 4 Things to Know Before Partnering with a
                              Large Corporation
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://uk-podcasts.co.uk/podcast/nalu-fm-finance/episode-39-sean-adler-exploring-quantitative-inves'
                          className='news_a'
                        >
                          <img src={section27} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>
                            Nalu Finance: Exploring Quantitative Investment Strategies - From Smart Beta to AI Integration
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://www.marketwatch.com/press-release/rise-of-alternative-data-fintech-innovation-with-sean-adler-of-gzi-finance-897b094e'
                          className='news_a'
                        >
                          <img src={section32} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>MarketWatch: Rise of Alternative Data</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://finance.yahoo.com/news/rise-alternative-data-fintech-innovation-121000142.html'
                          className='news_a'
                        >
                          <img src={section24} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>Yahoo Finance: Disruption Interruption</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://bit.ly/valiantceo'
                          className='news_a'
                        >
                          <img src={section28} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>
                              Valiant CEO: Translating Scientific Innovation
                              into Financial Technology
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://www.forbes.com/sites/theyec/2023/08/09/4-things-late-seed-companies-should-know-about-the-secondary-market/'
                          className='news_a'
                        >
                          <img src={section26} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>Forbes: 4 Things Late Seed Companies Should Know About The Secondary Market</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://bit.ly/gzi_omnicron'
                          className='news_a'
                        >
                          <img src={section29} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>
                              How Alternative Data Predicted the Cure for
                              Omnicron
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://www.forbes.com/sites/theyec/2023/01/05/how-to-utilize-simultaneous-competing-buyand-sell-side-contracts'
                          className='news_a'
                        >
                          <img src={section26} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>Forbes: How to Use Competing Buy and Sell Side Contracts</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://bit.ly/gzi_gemstones_from_space'
                          className='news_a'
                        >
                          <img src={section33} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>Blog</span>
                            </div>

                            <p>
                              Pharmaceuticals and Gemstones from Space Galore{" "}
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='news_art_div'>
                        <a
                          target='_blank'
                          href='https://bit.ly/beyondexclamation'
                          className='news_a'
                        >
                          <img src={section34} />
                          <div className='news_description'>
                            <div className='badge'>
                              <span>News</span>
                            </div>

                            <p>
                              Beyond Exclamation: Top 10 Most Influential
                              Leaders in Biotechnology 2021{" "}
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </Slider>

                  {/* <div className="btn_row">
                      <a href="#" className="view_all_btn">
                        View All
                      </a>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='testimonial_slider_sec mt-5'>
          <div className='container box-container'>
            <div className=''>
              <div id='testimonial-slider' className='owl-carousel'>
                {testimonials.map((testimonials, index) => (
                  <div className='testimonial'>
                    <div className='testimonial-content'>
                      <div className='txt_side'>
                        <p className='description'>
                          {"“ " + testimonials.content + " ”"}
                        </p>

                        {/* mobile view*/}
                        {/* <div className='img_side view_in_mob'>
                          <div className='pic'>
                            <img src={testimonials.personImageSrc} alt='' />
                          </div>
                        </div> */}
                        {/* //mobile view*/}

                        <h3 className='name'>{testimonials.name}</h3>
                        <span className='title'>
                          {testimonials.designation}
                        </span>
                      </div>
                      {/* <div className='img_side hide_in_mob'>
                        <div className='pic'>
                          <img src={testimonials.personImageSrc} alt='' />
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
                {/* <div className="testimonial">
                  <div className="testimonial-content">
                    <div className="txt_side">
                      <p className="description">
                        “ I2 met Sean at dinner with some friends back in
                        November 2019 where he introduced us to his company and
                        its trading methodology. When I asked for a name to
                        invest in, he mentioned Moderna (MRNA) as a solid pharma
                        company. At the time, it was trading at $18. My husband
                        and I talk about Sean every time we see the stock
                        achieve new highs.”
                      </p>

                     
                      <div className="img_side view_in_mob">
                        <div className="pic">
                          <img src={section18} alt="" />
                        </div>
                      </div>
                      
                      <h3 className="name">Wonkyung Lee</h3>
                      <span className="title">
                        Head of Macro Research, FJ Capital Management
                      </span>
                    </div>
                    <div className="img_side hide_in_mob">
                      <div className="pic">
                        <img src={section18} alt="" />
                      </div>
                    </div>
                  </div>
              </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className='counter_sec'>
          <div className='container box-container'>
            <div className='counter_row'>
              <ul className='counter_ul'>
                <li>
                  <h2>
                    <span className='counter'>4</span>
                  </h2>
                  <p>Continents</p>
                </li>
                <li>
                  <h2>
                    <span className='counter'>16</span>
                  </h2>
                  <p>Countries</p>
                </li>
                <li>
                  <h2>
                    <span className='counter'>10</span>
                  </h2>
                  <p>Enterprise Clients</p>
                </li>
                <li>
                  <h2>
                    <span className='counter'>3</span>
                  </h2>
                  <p>Moonshots Over 100%</p>
                </li>
                <li>
                  <h2>
                    <span className='counter'>36</span>%
                  </h2>
                  <p>More Efficient Than Robo Advisors</p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className='broker_section'>
          <div className='container box-container'>
            <div className='broker_row'>
              <div className='row'>
                <div className='col-md-7 col-sm-12 col-xs-12'>
                  <div className='broker_left_text_home'>
                    <h2>
                      The NASDAQ claims, “Alternative data remains the deepest,
                      least utilized source of information intelligence in the
                      world today.” Traditional financial data analytics no
                      longer meet the needs of traders. Our alternative data
                      does all the work for you.{" "}
                    </h2>
                  </div>
                </div>
                <div className='col-md-5 col-sm-12 col-xs-12'>
                  <div className='broker_right_img'>
                    <img src={section23} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='data_assets_sec'>
          <div className='container box-container '>
            <div className='data_assets_div'>
              {/* <h2 className="main_assets_hd">One stop shop for data assets</h2>*/}
              <h2 className='main_assets_hd'>
                We Empower Investors with Alternative Financial Data Engineering
              </h2>
              {/* <p className="main_assets_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>*/}

              <div className='row'>
                <div className='col-md-6 col-sm-12 col-xs-12'>
                  <div className='row'>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <a href='https://bit.ly/gzi_contentpage' target='_blank'>
                        <div className='assets_img_box small_img_box'>
                          <img src={section14} />
                          <div className='assets_text_box'>
                            <h2>Oracle</h2>
                            <p>
                              Query the Oracle to use our alternative data API
                              to stream data into your trading stack. Create
                              your own algorithms and analytics with
                              pre-screened data from an SEC compliant
                              corporation.
                            </p>
                            {/* <p>Proin libero nunc consequat interdum varius sit amet blandit.</p>*/}
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* mobile view*/}
                    <div className='col-md-6 col-sm-12 col-xs-12 view_in_mob'>
                      <a href='https://bit.ly/gzi_community' target='_blank'>
                        <div className='assets_img_box large_img_box'>
                          <img src={section15} />
                          <div className='assets_text_box'>
                            <h2>Oracle API</h2>
                            <p>
                              Query the Oracle to use our alternative data API
                              to stream data into your trading stack. Create
                              your own algorithms and analytics with
                              pre-screened data from an SEC compliant
                              corporation.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* //mobile view*/}

                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <a href='https://bit.ly/gzi_contentpage' target='_blank'>
                        <div className='assets_img_box small_img_box'>
                          <img src={section16} />
                          <div className='assets_text_box'>
                            <h2>Analytics</h2>
                            {/* <p>Turpis massa tincidunt dui ut ornare vitae nunc sed dignissim.</p>*/}
                            <p>
                              Analyze quantamentals, earnings, valuations, and
                              fundamental ratios on desktop and mobile.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 col-xs-12 hide_in_mob'>
                  <a href='https://bit.ly/gzi_community' target='_blank'>
                    <div className='assets_img_box large_img_box'>
                      <img src={section17} />
                      <div className='assets_text_box'>
                        <h2>Datasets</h2>
                        {/* <p>Vel risus commodo viverra maecenas accumsan scelerisque.</p>*/}
                        <p>
                          Customize and download alternative data trusted by
                          business brokers, investment banks, tech accelerators,
                          and crowdfunding platforms.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='investors_sec'>
          <div className='container box-container'>
            <div className='sec_hding'>
              {/* <h2>Empower investors with alternative financial data engineering.</h2>*/}
              <h2>A Novel Investing Experience Everyone Can Identify With</h2>
            </div>
            <div className='investor_row'>
              {/* mobile view*/}
              <div className='view_in_mob'>
                <div
                  id='investor_carousel_mob'
                  className='carousel slide'
                  data-ride='carousel'
                >
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <a href='https://bit.ly/gzi_contentpage' target='_blank'>
                        <div className='data_block'>
                          <img src={section19} />
                          <div className='data_text_block'>
                            <h2>Expert Analysis at Your Fingertips</h2>
                            <p>
                              GZI has passed extensive due dilligence and is
                              featured on all of the 3 largest expert consulting
                              networks in world: GLG, Guidepoint, and
                              AlphaSights. Skip the consult and subscribe or
                              send us a contact request to book a meeting. The
                              choice is yours.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='carousel-item'>
                      <a href='https://bit.ly/gzi_aboutpage' target='_blank'>
                        <div className='data_block'>
                          <img src={section20} />
                          <div className='data_text_block'>
                            <h2>SEC Verified Numbers</h2>
                            <p>
                              Data startups claim they help users conquer the
                              market. Our numbers are filed on a claims sheet
                              with the SEC. GZI is trained on the same data used
                              to outperform leading robo advisors in 2020 and
                              2021. This includes triple-digit jumps on Ascletis
                              Biopharma—the cure to the Omnicron variant. Visit
                              our FINRA and SIPC accredited investment banking
                              partners to view the documents.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='carousel-item'>
                      <a href='https://bit.ly/gzi_aboutpage' target='_blank'>
                        <div className='data_block'>
                          <img src={section21} />
                          <div className='data_text_block'>
                            <h2>Become Inspired Like the Media</h2>
                            <p>
                              Read our interviews in Beyond Exclamation, Valiant
                              CEO, Forbes, and YEC to see why GZI has inspired
                              the media with alternative data.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='carousel-item'>
                      <a href='https://bit.ly/we_gzi' target='_blank'>
                        <div className='data_block'>
                          <img src={section22} />
                          <div className='data_text_block'>
                            <h2>Trusted by Institutions</h2>
                            <p>
                              Visit the Content page to see why GZI has signed
                              competing buy- and sell-side contracts under
                              exclusivity clauses with some of the top business
                              brokers and crowdfunding platforms in the world at
                              a multimillion dollar valuation.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <a
                    className='carousel-control-prev'
                    href='#investor_carousel_mob'
                    role='button'
                    data-slide='prev'
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a
                    className='carousel-control-next'
                    href='#investor_carousel_mob'
                    role='button'
                    data-slide='next'
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
              {/* //mobile view*/}

              <div className='row hide_in_mob'>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <a href='https://bit.ly/gzi_contentpage' target='_blank'>
                    <div className='data_block'>
                      <img src={section19} />
                      <div className='data_text_block'>
                        {/* <h2>Trading platforms</h2>*/}
                        <h2>Expert Analysis at Your Fingertips</h2>
                        {/* <p>Powerful enough for the professional trader but designed for everyone. Available on desktop, mobile and web.</p>*/}
                        <p>
                          GZI has passed extensive due dilligence and is
                          featured on all of the 3 largest expert consulting
                          networks in world: GLG, Guidepoint, and AlphaSights.
                          Skip the consult and subscribe or send us a contact
                          request to book a meeting. The choice is yours.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <a href='https://bit.ly/gzi_aboutpage' target='_blank'>
                    <div className='data_block'>
                      <img src={section20} />
                      <div className='data_text_block'>
                        {/* <h2>Free trading tools</h2>*/}
                        <h2>SEC Verified Numbers</h2>
                        {/* <p>Spot market opportunities, analyze results, manage your account and make better decisions with our free trading tools.</p>*/}
                        <p>
                          Data startups claim they help users conquer the
                          market. Our numbers filed on a claims sheet with the
                          SEC. GZI is trained on the same data used to
                          outperform leading robo advisors in 2020 and 2021.
                          This includes triple-digit jumps on Ascletis
                          Biopharma—the cure to the Omnicron variant. Visit our
                          FINRA and SIPC accredited investment banking partners
                          to view the documents.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <a href='https://bit.ly/gzi_aboutpage' target='_blank'>
                    <div className='data_block'>
                      <img src={section21} />
                      <div className='data_text_block'>
                        {/* <h2>Order types and algos</h2>*/}
                        <h2>Become Inspired Like the Media</h2>
                        {/* <p>100+ order types – from limit orders to complex algorithmic trading – help you execute any trading strategy.</p>*/}
                        <p>
                          Read our interviews in Beyond Exclamation, Valiant
                          CEO, Forbes, and YEC to see why GZI has inspired the
                          media with alternative data.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <a href='https://bit.ly/we_gzi' target='_blank'>
                    <div className='data_block'>
                      <img src={section22} />
                      <div className='data_text_block'>
                        {/* <h2>Comprehensive reporting</h2>*/}
                        <h2>Trusted by Institutions</h2>
                        {/* <p>Real-time trade confirmations, margin details, transaction cost analysis, sophisticated portfolio analysis and more.</p>*/}
                        <p>
                          Visit the Content page to see why GZI has signed
                          competing buy- and sell-side contracts under
                          exclusivity clauses with some of the top business
                          brokers and crowdfunding platforms in the world at a
                          multimillion dollar valuation.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='platform_banner_sections'>
          <div className='container box-container'>
            <div className='platform_bg'>
              <div className='row'>
                <div className='col-md-7 col-sm-12 col-xs-12'>
                  <div className='platform_text'>
                    <h2>Try the Platform for Yourself</h2>
                    <p>
                      Ask a question and a member of the team will be in touch
                      soon.
                    </p>
                    <div className='btn_div'>
                      <a
                        href='https://bit.ly/gzicalendly'
                        className='filled_btn '
                      >
                        Book a Demo
                      </a>
                      <a href='/content' className='outline_btn '>
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='only_text_section'>
          <div className='container box-container'>
            <div className='text_only_box'>
              <p>
                Nothing contained on this site constitutes tax, legal, insurance
                or investment advice. Neither the information, nor any opinion,
                contained on this site constitutes a solicitation or offer by
                GZI or its affiliates to buy or sell any securities, futures,
                options or other financial instruments, nor shall any such
                security be offered or sold to any person in any jurisdiction in
                which such offer, solicitation, purchase, or sale would be
                unlawful under the securities laws of such jurisdiction.
              </p>
              <p>
                The risk of loss in online trading of stocks, options, futures,
                currencies, foreign equities, and fixed income can be
                substantial.
              </p>
              <p>
                IN NO EVENT SHALL ANY OF THE GZI PARTIES BE LIABLE FOR ANY
                INVESTMENT GAIN/LOSS YOU INCUR DURING YOUR INVESTMENT IN THE
                STOCK MARKET.
              </p>
            </div>
          </div>
        </section>
        <footer>
          <section className='footer-sec'>
            <div className='container'>
              <div className='row pt-5'>
                <div className='col-md-3 col-sm-3 col-xs-12'>
                  <div className='footer_col footer-logo'>
                    <img src={logo} />
                    <p>
                      GZI empowers investors with alternative financial data
                      engineering.
                    </p>
                  </div>
                </div>
                <div className='col-md-9 col-sm-9 col-xs-12'>
                  <div id='accordion' className='row'>
                    <div className='footer_col card col-md-4 col-sm-4 col-xs-12'>
                      <div className='card-header' id='headingOne'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link'
                            data-toggle='collapse'
                            data-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            Contact
                          </button>
                        </h2>
                      </div>

                      <div
                        id='collapseOne'
                        className='collapse'
                        aria-labelledby='headingOne'
                        data-parent='#accordion'
                      >
                        <div className='card-body'>
                          <div className='block_box'>
                            <h3>Write to Us</h3>
                            <ul className='footer_links flex_ul_li'>
                              <li>
                                <i className='fa fa-envelope'></i>
                                <a href='mailto:sadler@gzi.app'>
                                  sean.adler@gzi.finance
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className='block_box'>
                            <h3>Give us a Call</h3>
                            <ul className='footer_links flex_ul_li'>
                              <li>
                                <i className='fa fa-phone-alt'></i>
                                <a
                                  className='no_decor'
                                  href='tel:+1(303)5193561'
                                >
                                  +1-(303)-519-3561
                                </a>
                              </li>
                              <li>
                                <i className='fa fa-phone-alt'></i>
                                <a
                                  className='no_decor'
                                  href='https://bit.ly/gzicalendly'
                                >
                                  Schedule A Call
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='footer_col card col-md-4 col-sm-4 col-xs-12'>
                      <div className='card-header' id='headingTwo'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            data-toggle='collapse'
                            data-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            Sitemap
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseTwo'
                        className='collapse'
                        aria-labelledby='headingTwo'
                        data-parent='#accordion'
                      >
                        <div className='card-body'>
                          <div className='block_box'>
                            <ul className='footer_links block_ul_li'>
                              <li>
                                <Link to='/home'>Home</Link>
                              </li>
                              <li>
                                <Link to='/about-us'>About</Link>
                              </li>
                              <li>
                                <Link to='/content'>Content</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='footer_col card col-md-4 col-sm-4 col-xs-12'>
                      <div className='card-header' id='headingThree'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            data-toggle='collapse'
                            data-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            Account
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseThree'
                        className='collapse'
                        aria-labelledby='headingThree'
                        data-parent='#accordion'
                      >
                        <div className='card-body'>
                          <div className='block_box'>
                            <ul className='footer_links block_ul_li'>
                              <li>
                                <Link to='/auth/signin'>Log In</Link>
                              </li>
                              <li>
                                <Link to='/auth/signup'>Open An Account</Link>
                              </li>
                              <li>
                                <Link to='/auth/signup'>Free Trail</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='copyright_box'>
                <div className='row'>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <p>© 2024 GZI Hub Inc. All Rights Reserved.</p>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <ul>
                      <li>
                        <a href='#'>
                          <i className='fa-brands fa-facebook'></i>
                        </a>
                      </li>
                      <li>
                        <a target='_blank' href='https://twitter.com/swan_gzi'>
                          <i className='fa-brands fa-twitter'></i>
                        </a>
                      </li>
                      <li>
                        <a target='_blank' href='https://bit.ly/gzi_community'>
                          <i className='fa-brands fa-linkedin'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <p className='last_p'>
                      <Link to='/terms-and-condition'>Terms & Conditions</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div className="container box-container" >
                        <div className="view_in_mob">
                            <div className="col-xs-12">
                                <div className="footer_col text-center">
                                    <img src={section30} />
                                    <p>
                                        GZI empowers investors with alternative financial data
                                        engineering.
                                    </p>
                                </div>
                            </div>

                            <div id="accordion_footer">
                                <div className="footer_col">
                                    <div
                                        className="footer_accord_hd"
                                        data-toggle="collapse"
                                        data-target="#contact_accor"
                                        aria-expanded="false"
                                        aria-controls="contact_accor"
                                    >
                                        <h2>Contact</h2>
                                        <span className="caret_span">
                                            <img src={section31} />
                                        </span>
                                    </div>
                                    <div
                                        className="collapse"
                                        id="contact_accor"
                                        data-parent="#accordion_footer"
                                    >
                                        <div className="block_box">
                                            <h3>Write to us</h3>
                                            <ul className="footer_links flex_ul_li">
                                                <li>
                                                    <i className="fal fa-envelope"></i>
                                                    <a href="mailto:sadler@gzi.app">sadler@gzi.app</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="block_box">
                                            <h3>Give us a Call</h3>
                                            <ul className="footer_links flex_ul_li">
                                                <li>
                                                    <i className="fal fa-phone-alt"></i>
                                                    <a className="no_decor" href="tel:+1-(303)-519-3561">
                                                        +1-(303)-519-3561
                                                    </a>
                                                </li>
                                                <li>
                                                    <i className="fal fa-phone-alt"></i>
                                                    <a className="no_decor" href="#">
                                                        Schedule A Call
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer_col">
                                    <div
                                        className="footer_accord_hd"
                                        data-toggle="collapse"
                                        data-target="#sitemap_accor"
                                        aria-expanded="false"
                                        aria-controls="sitemap_accor"
                                    >
                                        <h2>Sitemap</h2>
                                        <span className="caret_span">
                                            <img src={section31} />
                                        </span>
                                    </div>
                                    <div
                                        className="collapse"
                                        id="sitemap_accor"
                                        data-parent="#accordion_footer"
                                    >
                                        <div className="block_box">
                                            <ul className="footer_links block_ul_li">
                                                <li>
                                                    <Link to="/home">Home</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about-us">About</Link>
                                                </li>
                                                <li>
                                                    <Link to="/content">Content</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer_col">
                                    <div
                                        className="footer_accord_hd"
                                        data-toggle="collapse"
                                        data-target="#account_accor"
                                        aria-expanded="false"
                                        aria-controls="account_accor"
                                    >
                                        <h2>Account</h2>
                                        <span className="caret_span">
                                            <img src={section31} />
                                        </span>
                                    </div>
                                    <div
                                        className="collapse"
                                        id="account_accor"
                                        data-parent="#accordion_footer"
                                    >
                                        <div className="block_box">
                                            <ul className="footer_links block_ul_li">
                                                <li>
                                                    <Link to="/auth/signin">Log In</Link>
                                                </li>
                                                <li>
                                                    <Link to="/auth/signup">Open An Account</Link>
                                                </li>
                                                <li>
                                                    <Link to="/auth/signup">Free Trail</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row hide_in_mob pt-5">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="footer_col">
                                    <img src={section30} />
                                    <p>
                                        GZI empowers investors with alternative financial data
                                        engineering.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="footer_col">
                                    <h2>Contact</h2>
                                    <div className="block_box">
                                        <h3>Write to us</h3>
                                        <ul className="footer_links flex_ul_li">
                                            <li>
                                                <i className="fa fa-envelope"></i>
                                                <a href="mailto:sadler@gzi.app">sadler@gzi.app</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="block_box">
                                        <h3>Give us a Call</h3>
                                        <ul className="footer_links flex_ul_li">
                                            <li>
                                                <i className="fa fa-phone-alt"></i>
                                                <a className="no_decor" href="tel:+1(303)5193561">
                                                    +1-(303)-519-3561
                                                </a>
                                            </li>
                                            <li>
                                                <i className="fa fa-phone-alt"></i>
                                                <a className="no_decor" href="https://bit.ly/gzicalendly">
                                                    Schedule A Call
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="footer_col noborder_tab">
                                    <h2>Sitemap</h2>
                                    <div className="block_box">
                                        <ul className="footer_links block_ul_li">
                                            <li>
                                                <Link to="/home">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us">About</Link>
                                            </li>
                                            <li>
                                                <Link to="/content">Content</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="footer_col noborder_mob noborder_tab">
                                    <h2>Account</h2>
                                    <div className="block_box">
                                        <ul className="footer_links block_ul_li">
                                            <li>
                                                <Link to="/auth/signin">Log In</Link>
                                            </li>
                                            <li>
                                                <Link to="/auth/signup">Open An Account</Link>
                                            </li>
                                            <li>
                                                <Link to="/auth/signup">Free Trail</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright_box">
                            <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <p>© 2022 GZI Hub Inc. All Rights Reserved.</p>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fa-brands fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://twitter.com/swan_gzi">
                                                <i className="fa-brands fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://bit.ly/gzi_community">
                                                <i className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <p className="last_p">
                                        <Link to="/terms-and-condition">Terms & Conditions</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </footer>
      </section>
    </>
  );
};

export default Section;
