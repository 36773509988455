const Page1 = ({}) => {
    return (
      <div id="page_1">
        <div className="col-md-12 text-center">
                    <h3 className="terms-heading text-white py-3">Privacy Policy</h3>
                </div>
                <div className="col-md-12 content">
                    <h5>
                        <strong className="text-white">Effective date: April 30, 2021</strong>
                    </h5>
                    <p className="terms-content">We at GZI know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy. Your use of the Services is at all times subject
                        to our Terms of Use, which incorporates this Privacy Policy. Any undefined terms we use in this Privacy Policy have the definitions assigned to such terms in our Terms of Use.
                    </p>
                    <p className="terms-content">By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following
                        ways.
                    </p>
                    <h5>
                        <strong className="text-white">What Does This Privacy Policy Cover?</strong>
                    </h5>
                    <p className="terms-content">This Privacy Policy covers our treatment of personally identifiable information (“Personal Data”) that we gather when you are accessing or using our Services. Our Privacy Policy does not cover the practices of companies we do not own
                        or control, or people that we do not manage. We gather various types of Personal Data from our users, as explained in more detail below, and we use this Personal Data in connection with our Services, including to develop, offer,
                        operate, personalize, provide, and improve our Services, to allow you to set up a user account and profile, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to
                        analyze how you use the Services. In certain cases, we may also share some Personal Data with third parties as described below.
                    </p>
                    <h5>
                        <strong className="text-white">What Personal Data Does GZI Collect?</strong>
                    </h5>
                    <p className="terms-content">We collect Personal Data about you when you provide such information directly to us, when third parties such as our business partners or service providers provide us with Personal Data about you, or when Personal Data about you is
                        automatically collected in connection with your use of our Services.
                    </p>
                    <p className="terms-content">Personal Data You Provide to Us </p>
                    <p className="terms-content">We receive and store any information you provide to us. For example, through the registration process and/or through your account settings, we may collect Personal Data which may include the following :</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>First and last name</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Username</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Password</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Email address</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Mailing address</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Telephone number</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>[Occupation and title]</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Employer, company, association or other institutional affiliation</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Information related to stocks and assets you engage with through the Services(“ Stock Tracking Information ”)</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Financial or payment information</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Anything you upload, post or choose to share (for example, comments and posts you submit publicly, etc.), which may include Personal Data if you include Personal Data in such content</p>
                    <p className="terms-content d-flex">Please note that certain information may be required to register with us or to take advantage of some of our Services.</p>
                    <p className="terms-content">Information Collected Automatically</p>
                    <p className="terms-content">Whenever you interact with our Services, we automatically receive and record information on our server logs from your browser or device, which may include the following:</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>IP address</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Device identifiers and information</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Web browser information</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Page view statistics</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Browsing history</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Usage information</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Transaction information (e.g. transaction amount, date and time such transaction occurred)</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Cookies and certain other tracking technologies (e.g. web beacons, pixel tags, SDKs, etc.)</p>
                    <p className="terms-content d-flex">— for more information, see below </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Location information (e.g. IP address, zip code), including geolocation/GPS data – for more information, see below</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Log data (e.g. access times, hardware and software information)</p>
                    <h5>
                        <strong className="text-white">Geolocation Data</strong>
                    </h5>
                    <p className="terms-content">If you are accessing the Services from a mobile device or through a mobile application, you may be asked to share your precise geolocation information with us so we can customize your experience with the Services. We will ask you to
                        opt-in before we use geolocation information to identify your precise location. If you agree to such collection, in most cases, you will be able to turn off such data collection at any time by accessing the privacy settings of
                        your mobile device.</p>

                    <h5>
                        <strong className="text-white">Additional Information about Cookies</strong>
                    </h5>
                    <p className="terms-content">The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser and tell us how and when you visit and use
                        our Services, to analyze trends, learn about our user base and operate and improve our Services. Cookies are small files – usually consisting of letters and numbers – placed on your computer, tablet, phone, or similar device when
                        you use that device to visit our Services. We sometimes combine information collected through Cookies that is not Personal Data with Personal Data that we have about you, for example, to tell us who you are or whether you have
                        an account with us. We may also supplement the information we collect from you with information received from third parties. </p>
                    <p className="terms-content">Cookies can either be “session Cookies” or “persistent Cookies”. Session Cookies are temporary Cookies that are stored on your device while you are visiting or using our Services, whereas “persistent Cookies” are stored on your device
                        for a period of time after you leave our Services. The length of time a persistent Cookie stays on your device varies from Cookie to Cookie. We use persistent Cookies to store your preferences so that they are available for your
                        next visit, and to keep a more accurate account of how often you visit our Services, how often you return, and how your use of the Services may vary over time. We also use persistent Cookies to measure the effectiveness of advertising
                        efforts. Through these Cookies, we may collect information about your online activity after you leave our Services. Your browser may offer you a “Do Not Track” or “DNT” option, which allows you to signal to operators of websites,
                        and web applications, and services that you do not wish such operators to track certain of your online activities over time and across different websites. Because we collect browsing and persistent identifier data, the Services
                        do not support Do Not Track requests at this time, which means that we may collect information about your online activity both while you are using the Services and after you leave our properties.
                    </p>
                    <p className="terms-content">Some Cookies are placed by a third party on your device and may provide information to us and third parties about your browsing habits (such as your visits to our Services, the pages you have visited and the links and advertisements
                        you have clicked). These Cookies can be used to determine whether certain third party services are being used, to identify your interests, to retarget advertisements to you and to serve advertisements to you that we or others believe
                        are relevant to you. We do not control third party Cookies. </p>

                    <h5>
                        <strong className="text-white">We use the following types of Cookies:</strong>
                    </h5>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Essential Cookies. Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas
                        of our Services. Disabling these Cookies may make certain features and services unavailable.</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Functional Cookies. Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to
                        our Services. These Cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).
                    </p>
                    <p className="terms-content">Performance/Analytical Cookies. Performance/Analytical Cookies allow us to understand how visitors use our Services such as by collecting information about the number of visitors to the Services, what pages visitors view on our Services
                        and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those
                        who engage with our advertising. For example, Google, Inc. (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits
                        to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt out of Google’s use of cookies by visiting the
                    </p>
                    <p className="terms-content">Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at<a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/.</a>
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Retargeting/Advertising Cookies. Retargeting/Advertising Cookies collect data about your online activity and identify your interests so that we can provide advertising that we believe
                        is relevant to you. For more information about this, please see the section below titled “Additional information about Interest-Based Advertisements.”</p>

                    <p className="terms-content">You can decide whether or not to accept Cookies. One way you can do this is through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new
                        Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your computer. If you
                        do this, however, you may have to manually adjust some preferences every time you visit a site and some Services and functionalities may not function properly. </p>

                    <p className="terms-content">To explore what Cookie setting are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please
                        visit <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>.</p>

                    <h5>
                        <strong className="text-white">Additional Information about Interest-Based Advertisements</strong>
                    </h5>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>We may serve advertisements, and also allow third party ad networks, including third party ad servers, ad agencies, ad technology vendors and research firms, to serve advertisements
                        through the Services. These advertisements may be targeted to users who fit certain general profile categories or display certain preferences or behaviors (“Interest-Based Ads”). Information for Internet-Based Ads (including Personal
                        Data) may be provided to us by you or derived from the usage patterns of particular users on the Services and/or services of Third Parties. Such information may be gathered through tracking users’ activities across time and unaffiliated
                        properties. To accomplish this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you through the Services. Web beacons allow ad networks to provide anonymized, aggregated
                        auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit or set their own
                        Cookies on your browser, just as if you had requested a web page from their site.
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Through the Digital Advertising Alliance (“DAA”) and Network Advertising Initiative (“NAI”), several media and marketing associations have developed an industry self- regulatory program
                        to give consumers a better understanding of and greater control over ads that are customized based on their online behavior across different websites. To make choices about Interest-Based Ads from participating third parties, including
                        to opt out of
                    </p>
                    <p className="terms-content">receiving behaviorally targeted advertisements from participating organizations, please visit the DAA’s or NAI’s consumer opt out pages, which are located at <a href="http://www.networkadvertising.org/choices/"><a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a></a>or
                        <a href="www.aboutads.info/choices"> www.aboutads.info/choices</a>.
                    </p>
                    <h5>
                        <strong className="text-white">Information We Receive From Third Party Sources</strong>
                    </h5>
                    <p className="terms-content">Some third parties such as our business partners and service providers provide us with Personal Data about you, such as the following:</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Information from our service providers: We receive information collected by third parties about your use of the Services. For example, we may use analytics service providers to analyze
                        how you interact and engage with the Services and our advertisements, so we can learn and make enhancements to offer you a better experience. Some of these entities may use Cookies, web beacons and other technologies to collect
                        information about your use of the Services and other websites, which may include tracking activity across time and unaffiliated properties, including your IP address, web browser, pages viewed, time spent on pages, links clicked
                        and conversion information. Third parties may also help us provide you with customer support and provide us with information so that we may help you use our Services.</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Account information for third party services: If you provide your third-party account credentials to us or otherwise sign in to the Services through a third party site or service, some
                        content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your account with us if you authorize such transmissions. Such Third Party Account Information transmitted to our Services
                        is covered by this Privacy Policy, for example, information from your public profile, if the third party service and your account settings allow such sharing. The information we receive will depend on the policies and your account
                        settings with the third party service.
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Information from our advertising partners: We receive information about you from some of our service providers who assist us with marketing or promotional services related to how you
                        interact with our websites, applications, products, services, advertisements or communications.
                    </p>

                    <h5>
                        <strong className="text-white">How Do We Use Your Personal Data?</strong>
                    </h5>
                    <p className="terms-content ">We process Personal Data to develop, operate, improve, understand and personalize our Services. For example, we use Personal Data to: </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Communicate with you about the Services, including Service announcements, updates or offers</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Provide support and assistance for the Services </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Personalize website content and communications based on your preferences</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Recommend products and services that we think may be of interest to you</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Create and manage user profiles</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Respond to user inquiries</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Fulfill user requests</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Comply with our legal or contractual obligations</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Resolve disputes</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Protect against or deter fraudulent, illegal or harmful actions</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Enforce our Terms of Use</p>
                    <p className="terms-content">We may communicate with you if you have provided us the means to do so. For example, if you have provided your email address, we may send you promotional email offers on behalf of other businesses, or email you about your use of the
                        Services. Also, we may receive a confirmation when you open an email from us. This confirmation helps us make our communications with you more interesting and improve our services. If you do not want to receive communications from
                        us, please indicate your preference by emailing us at [sean.adler@gzi.finance]. </p>
                    <h5>
                        <strong className="text-white">What About Non-personally Identifiable Data?</strong>
                    </h5>
                    <p className="terms-content ">In addition to processing your Personal Data as set forth herein, we may also de-identify or aggregate your Personal Data to create data that does not specifically identify you as an individual (“Non-PID”). We use Non-PID for various
                        business purposes, which may include incorporating Non-PID in our current or future Services, selling Non-PID to our customers or business partners or providing Non-PID to our business partners for their use in connection with
                        their own products or services. For example, we may provide aggregate usage information to our partners (or allow partners to collect that information from you), who may use such information to understand how often and in what
                        ways people use our Services, so that they, too, can provide you with an optimal online experience. However, we never disclose aggregate usage or Non-PID to a partner (or allow a partner to collect such information) in a manner
                        that would identify you as an individual person.</p>
                    <h5>
                        <strong className="text-white">Will GZI share any of the Personal Data GZI receives?</strong>
                    </h5>
                    <p className="terms-content ">We may share your Personal Data with third parties as described below:</p>
                    <p className="terms-content ">Service Providers</p>
                    <p className="terms-content ">We share Personal Data with vendors and third party service providers who work on our behalf and provide us with services related to the purposes described in this Privacy Policy or our Terms of Use. These parties may include: </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Ad networks</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Fraud prevention service providers</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Analytics service providers</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Staff augmentation and contract personnel</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Hosting service providers</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Co-location service providers</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Telecommunications service providers</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Data providers and vendors</p>
                    <p className="terms-content ">Unless we tell you differently, our vendors and service providers do not have any right to use the Personal Data we share with them beyond what is necessary to assist us.</p>
                    <p className="terms-content ">Data Shared at Your Request</p>
                    <p className="terms-content ">We also share Personal Data when necessary to complete a transaction initiated or authorized by you or provide you with a product or service you have requested. In addition to those set forth above, these parties also include: </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Services)</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Social media services (if you interact with such services through your use of the Services)</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Third party business partners who you access through the Services</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Data providers and vendors</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Other parties authorized by you</p>
                    <h5>
                        <strong className="text-white">User Profiles and Submissions</strong>
                    </h5>
                    <p className="terms-content ">Certain user profile information that such user has uploaded to the Services, including your name, location, video or image content and Stock Tracking Information, may be displayed to other users to facilitate user interaction within
                        the Services or address your request for our Services. Your account privacy settings may allow you to limit the other users who can see the Personal Data in your user profile and/or what information in your user profile is visible
                        to others. Please remember that any content you upload to your public user profile, along with any Personal Data or content that you voluntarily disclose online in a manner other users can view (on discussion boards, in messages
                        and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your full name and GZI user name may also be displayed to other users if and when you send or receive messages or comments or upload images
                        or videos through the Services.. Additionally, if you sign into the Services through a third party social networking site or service, your list of “friends” from that site or service may be automatically imported to the Services,
                        and such “friends,” if they are also registered users of the Services, may be able to access certain non- public information you have entered in your Services user profile. Again, we do not control the policies and practices of
                        any other third party site or service.</p>
                    <h5>
                        <strong className="text-white">Business Transfers</strong>
                    </h5>
                    <p className="terms-content ">We may choose to buy or sell assets and may share and/or transfer customer information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter
                        bankruptcy, or go through some other change of control, Personal Data could be one of the assets transferred to or acquired by a third party.</p>

                    <h5>
                        <strong className="text-white">Protection of Company and Others</strong>
                    </h5>
                    <p className="terms-content ">We reserve the right to access, read, preserve, and disclose any information that we believe is necessary to comply with law or court order, enforce or apply our Terms of Use and other agreements, or protect the rights, property, or
                        safety of GZI, our employees, our users, or others.
                    </p>
                    <h5>
                        <strong className="text-white">Is Personal Data About Me Secure?</strong>
                    </h5>
                    <p className="terms-content ">Your account is protected by a password for your privacy and security. If you access your account via a third party site or service, you may have additional or different sign-on protections via that third party site or service. You
                        must prevent unauthorized access to your account and Personal Data by selecting and protecting your password and/or other sign-on mechanism appropriately and limiting access to your computer or device and browser by signing off
                        after you have finished accessing your account.</p>
                    <p className="terms-content ">We endeavor to protect the privacy of your account and other Personal Data that we hold in our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors,
                        may compromise the security of user information at any time.

                    </p>
                    <h5>
                        <strong className="text-white">How Long Do We Retain Your Personal Data?
                        </strong>
                    </h5>
                    <p className="terms-content ">We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you Services. In some cases we retain Personal Data for a longer period of time, if doing so is necessary to comply
                        with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule, or regulation or our policies (including without limitation this Privacy Policy and our Terms of
                        Use). We also retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.
                    </p>
                    <h5>
                        <strong className="text-white">What Personal Data Can I Access?
                        </strong>
                    </h5>
                    <p className="terms-content ">Through your account settings, you may access, and, in some cases, edit or delete the following information you have provided to us (provided that we may retain a backup copy for continued use in accordance with this Privacy Policy):
                    </p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>First and last name</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Username</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Password</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Email address</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Telephone number</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Occupation and title</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Employer, company, association or other institutional affiliation</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Financial or payment information</p>
                    <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Anything you upload, post or choose to share (for example, comments and posts you submit publicly, etc.), which may include Personal Data if you include Personal Data in such content</p>
                    <p className="terms-content ">The information you can view, update, and delete may change as the Services change. If you have any questions about viewing or updating information we have on file about you, please contact us at [sean.adler@gzi.finance].</p>

                    <h5>
                        <strong className="text-white">What Choices Do I Have?
                        </strong>
                    </h5>
                    <p className="terms-content ">You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features.</p>
                    <p className="terms-content ">You may be able to add, update, or delete information as explained above. When you update information, however, we may maintain a copy of the unrevised information in our records. You may request deletion of your account by emailing
                        us at [sean.adler@gzi.finance]. Some information may remain in our records after your deletion of such information from your account. We may use any aggregated data derived from or incorporating your Personal Data after you update or
                        delete it, but not in a manner that would identify you personally.</p>
                    <h5>
                        <strong className="text-white">What About The Personal Data Of Children?
                            </strong>
                    </h5>
                    <p className="terms-content ">As noted in our Terms of Use, we do not knowingly collect or solicit personally identifiable information from children under 13 years of age. If you are a child under 13 years of age, please do not attempt to register for or otherwise
                        use the Services or send us any personal information. If we learn we have collected personal information from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under
                        13 years of age may have provided us personal information, please contact us at [sean.adler@gzi.finance].</p>

                    <h5>
                        <strong className="text-white">Will GZI Ever Change This Privacy Policy?
                                </strong>
                    </h5>
                    <p className="terms-content ">We are constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well. We will alert you to changes by placing a notice on our website, by sending you an email, and/or by some other
                        means. Please note that if you have opted not to receive legal notice emails from us (or you have not provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible
                        for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the most up-to-date Privacy
                        Policy in effect.</p>

                    <h5>
                        <strong className="text-white">What If I live In California?
                                    </strong>
                    </h5>
                    <p className="terms-content ">California residents are entitled to ask us for a notice (i) identifying the categories of Personal Information which we share with our affiliates and/or third parties for marketing purposes, and (ii) providing contact information
                        for such affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please submit a written request to [sean.adler@gzi.finance].</p>
                    <h5>
                        <strong className="text-white">What If I Have Questions About This Policy?
                                        </strong>
                    </h5>
                    <p className="terms-content ">If you have any questions or concerns regarding our Privacy Policy, please send us a detailed message to [sean.adler@gzi.finance] and we will try to resolve your concerns.</p>
                </div>
      </div>
    );
  }
  
  export default Page1;