const initialState = { jsonData: null, userData: null, authenticate: null };

const Sign = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_DATA":
      return { ...state, jsonData: action.payload };
    case "REMOVE_DATA":
      return {...state, jsonData:{} };
    case "GET_USER_DATA":
      return { ...state, userData: action.payload };
    case "AUTHENTICATE_USER":
      return { ...state, authenticate: action.payload };
    default:
      return state;
  }
};
export default Sign;
