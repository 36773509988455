import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import contact from "../../util/contact.js";
import { useForm } from "react-hook-form";

const ContactForm = ({}) => {
    const [loader, setLoader] = useState(false);
    const [formAlert, setFormAlert] = useState(null);
    const [name,setName] = useState(null);
    const [email,setEmail] = useState(null);
    const [message,setMessage] = useState(null);
    const [subject,setSubject] = useState(null);
    const { handleSubmit, register, errors, reset:resetForm } = useForm();

    const onSubmit = async(data) => {
        resetForm();
        data.name = name;
        data.email = email;
        data.subject = subject;
        data.message = message;
        setLoader(true);
        contact.submit(data)
        .then(() => {
            resetForm();
            setFormAlert({
                type: "success",
                message: "Your message has been sent!",
            });
        })
        .catch((error) => {
            setFormAlert({
                type: "error",
                message: error.message,
            });
        })
        .finally(() => {
            setLoader(false);
        });
    };

    // const validateInput = () => {
    //     function validateEmail(email) {
    //       const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //       return re.test(email);
    //     };
    //     if(!validateEmail(email)){
    //       setFormAlert({
    //         type: "error",
    //         message: "Please provide a valid email"
    //       });
    //       return false;
    //     }
    //     else{
    //         return true;
    //     }
    // }

    // const onValueChange = (event) => {
    //     const targetElement = event.target;
    //     const { name: eleName, value } = targetElement;

    //     targetElement.setCustomValidity("");

    //     switch (eleName) {
    //         case 'Name':
    //             setName(value)
    //             break;
    //         case 'Email':
    //             setEmail(value)
    //             break;
    //         case 'Subject':
    //             setSubject(value)
    //             break;
    //         case 'Message':
    //             setMessage(value)
    //             break;
    //     }
        
    //     setFormAlert(null);
    // }

    return (
        <div className="contact-form form-model">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-inner">
                    <div className="form-title section-title text-left align-items-start">
                        <h2>How can we help you?</h2>
                    </div>
                    <div className="form-main">
                        <div className="single-input">
                            <input
                                type="text"
                                className="input"
                                name='Name'
                                placeholder="Your name"
                                onChange={(event)=>setName(event.target.value)}
                                required={true}
                            />
                        </div>
                        <div className="single-input">
                            <input
                                type="email"
                                className="input"
                                name='Email'
                                placeholder="Email address"
                                onChange={(event)=>setEmail(event.target.value)}
                                required={true}
                            />
                        </div>
                        <div className="single-input">
                            <input
                                type="text"
                                className="input"
                                name='Subject'
                                placeholder="Subject"
                                onChange={(event)=>setSubject(event.target.value)}
                                required={true}
                            />
                        </div>
                        <div className="single-input">
                            <textarea
                                className="input big"
                                name='Message'
                                placeholder="Write your message"
                                defaultValue={""}
                                onChange={(event)=>setMessage(event.target.value)}
                                required={true}
                            />
                        </div>
                        <button type="submit" className="slim-submit-button" value="Send Message">
                            {
                                loader ? <CircularProgress size={28} /> : <span>Send Message</span>
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ContactForm;
