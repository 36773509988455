import { loadStripe } from "@stripe/stripe-js";
import { apiRequest } from "./util";
import firebase from "./firebase";
import { getUser, updateUser } from "./db";
import { getStripePriceId } from './prices'

let stripe;
// Load the Stripe script
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    // Pin to specific version of the Stripe API
    apiVersion: "2020-08-27",
}).then((stripeInstance) => {
    // Set stripe so all functions below have it in scope
    stripe = stripeInstance;
});

export async function redirectToCheckout(planName, tableRef = null) {
    const planId = getStripePriceId(planName)
    const uId = firebase.auth().currentUser
        ? firebase.auth().currentUser.uid
        : undefined;

    if (typeof uId !== 'undefined' && uId != '') {
        let { email, stripeCustomerId } = await getUser(uId);
        if (!stripeCustomerId) {
            await createCustomer(email, planName, uId, planId);
        } else {
            await checkoutSession(planName, uId, planId, stripeCustomerId, tableRef);
        }
    }

}

async function createCustomer(email, planName, uId, planId) {
    const customerRequestOptions = {
        body: `email=${email}`,
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "Set-Cookie": `token=;${process.env.REACT_APP_STRIPE_SECRET_KEY} Path=/;HttpOnly; maxAge=86400000; SameSite=false;`
        },
        method: "POST"
    }
    await fetch("https://api.stripe.com/v1/customers", customerRequestOptions)
        .then(response => response.json())
        .then(async customer => {
            await updateUser(uId, {
                stripeCustomerId: customer.id,
            });
            await checkoutSession(planName, uId, planId, customer.id);
        });
}

async function redirectAndDownloadCsv(session, tableRef) {
    //    tableRef.current.table.download("csv", "data.csv")
    await stripe.redirectToCheckout({ sessionId: session.id });
}

async function checkoutSession(planName, uId, planId, customerId, tableRef) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            "Set-Cookie": `token=;${process.env.REACT_APP_STRIPE_SECRET_KEY} Path=/;HttpOnly; maxAge=86400000; SameSite=false;`
        },
        body: `success_url=${window.location.origin}/content?session_id={CHECKOUT_SESSION_ID}&cancel_url=${window.location.origin}/content&payment_method_types[0]=card&line_items[0][price]=${planId}&line_items[0][quantity]=1&mode=subscription&customer=${customerId}`,
    };
    await fetch('https://api.stripe.com/v1/checkout/sessions', requestOptions)
        .then(response => response.json())
        .then(async session => {
            await updateUser(uId, {
                planId: planName,
            })
            if (session.id) {
                await redirectAndDownloadCsv(session, tableRef)
            }
        }).then(function (result) {
            if (result.error) {
                alert(result.error.message);
            }
        });
}

export async function checkoutSessionStatus(sessionId, uId) {
    const requestOptions = {
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
    };
    return await fetch(`https://api.stripe.com/v1/checkout/sessions/${sessionId}`, requestOptions)
        .then(response => response.json())
        .then(async response => {
            if (response.payment_status === 'paid') {
                await updateUser(uId, {
                    planIsActive: true
                });
            }
            return await getUser(uId)
        })
}

export async function redirectToBilling() {
    // Create a billing session
    const session = await apiRequest("stripe-create-billing-session", "POST", {
        returnUrl: `${window.location.origin}/settings/general`,
    });

    // Ensure if user clicks browser back button from billing they go to /settings/general
    // instead of this page or they'll redirect right back to billing.
    window.history.replaceState({}, "", "/settings/general");

    // Redirect to billing session url
    window.location.href = session.url;
}
