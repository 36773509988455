const Page6 = ({}) => {
  return (
    <div id="page_6">
      <div className="col-md-12 content">
        <p>
          We may communicate with you if you have provided us the means to do
          so. For example, if you have provided your email address, we may send
          you promotional email offers on behalf of other businesses, or email
          you about your use of the Services. Also, we may receive a
          confirmation when you open an email from us. This confirmation helps
          us make our communications with you more interesting and improve our
          services. If you do not want to receive communications from us, please
          indicate your preference by emailing us at [sean.adler@gzi.finance].
        </p>
        <h5>
          <strong className="text-white">
          What About <nobr>Non-personally</nobr> Identifiable Data?
          </strong>
        </h5>
        <p>
          In addition to processing your Personal Data as set forth herein, we
          may also <nobr>de-identify</nobr> or aggregate your Personal Data to
          create data that does not specifically identify you as an individual{" "}
          <nobr>(“Non-PID”).</nobr> We use <nobr>Non-PID</nobr> for various
          business purposes, which may include incorporating{" "}
          <nobr>Non-PID</nobr> in our current or future Services, selling{" "}
          <nobr>Non-PID</nobr> to our customers or business partners or
          providing <nobr>Non-PID</nobr> to our business partners for their use
          in connection with their own products or services. For example, we may
          provide aggregate usage information to our partners (or allow partners
          to collect that information from you), who may use such information to
          understand how often and in what ways people use our Services, so that
          they, too, can provide you with an optimal online experience. However,
          we never disclose aggregate usage or <nobr>Non-PID</nobr> to a partner
          (or allow a partner to collect such information) in a manner that
          would identify you as an individual person.
        </p>
        <h5><strong className='text-white'>Will GZI share any of the Personal Data GZI receives? </strong></h5>
        <p>
          We may share your Personal Data with third parties as described below:
        </p>
        <p>Service Providers</p>
        <p>
          We share Personal Data with vendors and third party service providers
          who work on our behalf and provide us with services related to the
          purposes described in this Privacy Policy or our Terms of Use. These
          parties may include:
        </p>

        <p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Ad networks</span>
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Fraud prevention service providers</span>
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Analytics service providers</span>
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Staff augmentation and contract personnel</span>
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Hosting service providers</span>
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <nobr>
                <span>Co-location</span>
              </nobr>{" "}
              service providers
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Telecommunications service providers</span>
            </p>
              <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              <span>Data providers and vendors</span>
            </p>
  
        </p>
        <p>
          Unless we tell you differently, our vendors and service providers do
          not have any right to use the Personal Data we share with them beyond
          what is necessary to assist us.
        </p>
      </div>
      <div className="col-md-12 content">
        <p>Data Shared at Your Request</p>
      </div>
    </div>
  );
}

export default Page6;