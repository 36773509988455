import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import Section from "./Section";
// import Container from "@material-ui/core/Container";
// import { Link } from "./../util/router.js";
// import Typography from "@material-ui/core/Typography";
// import TwitterIcon from "@material-ui/icons/Twitter";
import useDarkMode from "use-dark-mode";
// import { makeStyles } from "@material-ui/core/styles";
// import banimg from "../assets/images/ban.png";
// import logo from "../assets/images/76x56-inches.png";
import logo from "../assets/images/logo156114.png";

import { useSelector } from "react-redux";
import { useAuth } from "../util/auth.js";
import { useRouter } from "../util/router.js";
import "../assets/css/gzi.css";
import $ from "jquery";
function Header(props) {
  const router = useRouter();
  const [toggleBar, settoggleBar] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const authenticate = useSelector((state) => state.ReducerValue.authenticate);
  const auth = useAuth();
  // const classes = useStyles();
  const darkMode = useDarkMode();

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 10
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };

  useEffect(() => {
    const targetElement = document.querySelector(
      `a[href*="${window.location.pathname}"]`
    );
    if (window.location.pathname !== "/auth/signin" && targetElement) {
      targetElement.classList.add("activeClassHeader");
    }

    var url = window.location.href;
    // now grab every link from the navigation
    $("div#navbarSupportedContent ul>li a").each(function () {
      if (this.href == url) {
        $(this).parent().addClass("active");
      }
    });
  }, []);

  //console.log(auth);
  return (
    <>
      <header className='header-section'>
        <div className='container box-container'>
          <div className='topnav'>
            <p></p>
          </div>

          <nav className='navbar navbar-expand-lg navbar-light custom_navbar'>
            <div className='main-nav'>
              <a className='navbar-brand' href='/'>
                <img src={logo} />
              </a>

              {/* mobile view */}

              <a
                className="right_a btn btn-outline-success my-2 my-sm-0 filled_btn view_in_mob"
                type="submit"
                href="/auth/signup"
                onClick={() => router.history.push("/auth/signup")}
              >
                Try for Free
              </a>

              {/* //mobile view */}

              <button
                className='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
              <div
                className='collapse navbar-collapse'
                id='navbarSupportedContent'
              >
                <ul className='navbar-nav mr-auto'>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='/home'
                      onClick={() => router.history.push("/home")}
                    >
                      Home <span className='sr-only'>(current)</span>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='/about-us'
                      onClick={() => router.history.push("/about-us")}
                    >
                      About
                    </a>
                  </li>
                  {/* <li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Dropdown
								</a>
								<div class="dropdown-menu" aria-labelledby="navbarDropdown">
									<a class="dropdown-item" href="#">Action</a>
									<a class="dropdown-item" href="#">Another action</a>
									<div class="dropdown-divider"></div>
									<a class="dropdown-item" href="#">Something else here</a>
								</div>
  </li> */}
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='/content'
                      onClick={() => router.history.push("/content")}
                    >
                      Content
                    </a>
                  </li>
                  {/*<li className="nav-item">
                  <a
                    className="nav-link"
                    href="/pricing"
                    onClick={() => router.history.push("/pricing")}
                  >
                    Pricing
                  </a>
</li>*/}
                </ul>
                <div className='form-inline my-2 my-lg-0 right_menu'>
                  {auth.user ? (
                    <div class='dropdown-new account_dd'>
                      <button
                        onClick={() => setDropdown(!dropdown)}
                        class='dropbtn-new right_a btn btn-outline-success my-2 my-sm-0 filled_btn'
                        style={toggleBar ? { display: "block" } : null}
                      >
                        Account
                        <i class='fa fa-caret-down '></i>
                      </button>
                      <div
                        class='dropdown-content-new'
                        style={dropdown ? { display: "block" } : null}
                      >
                        <a
                          href='#'
                          onClick={() => {
                            auth.signout();
                          }}
                        >
                          Sign Out
                        </a>
                      </div>
                    </div>
                  ) : (
                    <a
                      className='right_a nav-link a_link'
                      href='/auth/signin'
                      onClick={() => {
                        router.history.push("/auth/signin");
                      }}
                    >
                      Login
                    </a>
                  )}
                  <a
                    className='right_a btn btn-outline-success my-2 my-sm-0 outline_btn'
                    type='submit'
                    href='https://bit.ly/gzicalendly'
                    onClick={() =>
                      router.history.push("https://bit.ly/gzicalendly")
                    }
                  >
                    Talk to Sales
                  </a>
                  <a
                    class='hide_in_mob right_a btn btn-outline-success my-2 my-sm-0 filled_btn'
                    type='submit'
                    href='/auth/signup'
                    onClick={() => router.history.push("/auth/signup")}
                  >
                    Try for Free
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
// import Slider from "react-slick";
// import { Dropdown, DropdownButton } from "react-bootstrap";
// import Section from "./Section";
// import Container from "@material-ui/core/Container";
// import { Link } from "./../util/router.js";
// import Typography from "@material-ui/core/Typography";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import useDarkMode from "use-dark-mode";
// import { makeStyles } from "@material-ui/core/styles";
// import banimg from "../assets/images/ban.png";
// import logo from "../assets/images/logo.png";
// import { useSelector } from "react-redux";
// import { useAuth } from "../util/auth.js";
// import { useRouter } from "../util/router.js";
// import "../assets/css/gzi.css";

// function Header(props) {
//   const router = useRouter();
//   const [toggleBar, settoggleBar] = useState(false);
//   const [dropdown, setDropdown] = useState(false);
//   const authenticate = useSelector((state) => state.ReducerValue.authenticate);
//   const auth = useAuth();
//   // const classes = useStyles();
//   const darkMode = useDarkMode();
//   // Use inverted logo if specified
//   // and we are in dark mode
//   //   const logo =
//   //     props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

//   useEffect(()=>{
//     const targetElement = document.querySelector(`a[href*="${window.location.pathname}"]`);
//     if(window.location.pathname !== '/auth/signin' && targetElement) {
//       targetElement.classList.add("activeClassHeader")
//     }
//   },[])

//   return (
//     <>
//     <head>
//       <title>Bootstrap Example</title>
//       <meta charset="utf-8"/>
//       <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
//       <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
//     </head>
//     <header id="nav-newbar" style={{backgroundColor: 'black'}}>
//       <nav class="nav-newbar-container container" id="myTopnav-new">
//         <a className="home-link" onClick={() => router.history.push("/home")}>
//           <div class="nav-newbar-logo">
//             <img className="img-logo" src={logo} alt="" />
//           </div>
//         </a>
//         <button onClick={()=> settoggleBar(!toggleBar)}
//          type="button" id="nav-newbar-toggle" aria-controls="nav-newbar-menu" aria-label="Toggle menu" aria-expanded="false">
//           <span class="icon-bar"></span>
//           <span class="icon-bar"></span>
//           <span class="icon-bar"></span>
//         </button>
//         <div id="nav-newbar-menu" aria-labelledby="nav-newbar-toggle"
//           style={toggleBar?{display:'block', visibility:"visible", opacity:1}:null}
//         >
//           <ul class="nav-newbar-links">
//             <li className="nav-newbar-item">
//               <a href="/home" className="nav-newbar-link" onClick={() => router.history.push("/home")}>Home</a>
//             </li>
//             <li class="nav-newbar-item">
//               <a class="nav-newbar-link" href="/about-us" onClick={() => router.history.push("/about-us")}>About</a>
//             </li>
//             <li class="nav-newbar-item">
//               <a class="nav-newbar-link" href="/content" onClick={() => router.history.push("/content")}>Content</a>
//             </li>
//             <li class="nav-newbar-item">
//               <a class="nav-newbar-link" href="/faq" onClick={() => router.history.push("/faq")}>FAQ</a>
//             </li>
//             <li class="nav-newbar-item">
//               <a class="nav-newbar-link" href="/contact-us" onClick={() => router.history.push("/contact-us")}>Contact</a>
//             </li>
//             <li class="nav-newbar-item">
//               <a class="nav-newbar-link" href="/pricing" onClick={() => router.history.push("/pricing")}>Pricing</a>
//             </li>
//             <li class="nav-newbar-item">
//               <a class="nav-newbar-link" href="/terms-and-condition" onClick={() => router.history.push("/terms-and-condition")}>Terms & Conditions</a>
//             </li>

//            <li class="nav-newbar-item socials">
//              <a target="_blank" href="#"><i class="fa fa-facebook" aria-hidden="true"></i>
//               {/* <img alt="facebook" src={facebook} height="20px" width="20px"/> */}
//              </a>
//              <a target="_blank" href="https://twitter.com/swan_gzi"><i class="fa fa-twitter" aria-hidden="true"></i>
//               {/* <img alt="twitter" src={twitter} height="20px" width="20px"/> */}
//              </a>
//              <a target="_blank" href="#"><i class="fa fa-instagram" aria-hidden="true"></i>
//               {/* <img alt="instagram" src={instagram} height="20px" width="20px"/> */}
//              </a>
//            </li>
//            <li class="nav-newbar-item s-in">
//              {auth.user? (
//                       <div class="dropdown-new">
//                       <button onClick={()=> setDropdown(!dropdown)}
//                       class="dropbtn-new" style={toggleBar?{display:'block'}:null}>Account
//                         <i class="fa fa-caret-down "></i>
//                       </button>
//                       <div class="dropdown-content-new" style={dropdown?{display:'block'}:null}>
//                         <a onClick={()=>{auth.signout()}}>Sign Out</a>
//                       </div>
//                         </div>
//                     ):
//                      <a style={{
//                       border: '1px solid #585858',
//                       padding: '4px 20px',
//                       borderRadius: '15px',
//                      }} onClick={() => { router.history.push("/auth/signin")}}>Sign in</a>
//                     }
//             </li>
//             {/* <a href="javascript:void(0);" style={{fontSize:'15px'}} class="icon" onClick={myFunction()}>&#9776;</a>  */}
//           </ul>
//           </div>
//       </nav>
//     </header>
//     </>
//   )

// }

// export default Header;
