import React from "react";
import { useAuth } from "../../util/auth.js";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import pricing_ban from "../../assets/images/pricing_ban.jpg";

// Sub Sections
import PricingSection from "../../components/pricing/PricingSection";
import PaymentLinksSection from "../../components/pricing/PaymentLinksSection";
import PricingHeaderSection from "../../components/pricing/PricingHeaderSection";

const PricingPage = (props) => {
	const auth = useAuth();

	const divStyle ={
    height:'45%',
    display:"flex",
    justifyContent:"center",
    alignItems:'center'
  }

  return (
    <div>
      <Header {...props} pageName={"price"} bgImage={pricing_ban} />
      <div className="main-wrap">
        <main style={{paddingTop: '5rem'}}>
        <section className="page_bg grid_section_component all_main_sec_padding">
          <section className="pricing-section about_banner_sec">
            <div className="container">
              <div className="pricing-section-inner">
                <div className="section-title">
                  <h4>Choose the plan that’s right for you</h4>
							    <h2>GZI Premium gives you the ultimate package
                    <br/> for stock mastery.
                  </h2>
						    </div>
                <div class="pricing-table-outer">
                  <div class="pricing-table">
                    <PricingHeaderSection />
                    <PricingSection />
                    <PaymentLinksSection {...props}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </section>
        </main>
       
        <Footer {...props} pageName={""} />
      </div>
    </div>
  );
};

export default PricingPage;
