import { Fragment } from "react";

const PricingSection = () => {

    const pricings = [
      {
        mainFeature: 'Data streaming',
        infoSrc: '#',
        basic: true,
        business: true,
        pro: true
      },
      {
        mainFeature: 'Customizable portfolios for download',
        infoSrc: '#',
        basic: true,
        business: true,
        pro: true
      },
      {
        mainFeature: 'Imports to brokerage software of your choosing',
        infoSrc: '#',
        basic: false,
        business: true,
        pro: true
      },
      {
        mainFeature: 'Access to financial data',
        infoSrc: '#',
        basic: true,
        business: true,
        pro: true
      },
      {
        mainFeature: 'Access to alternative data',
        infoSrc: '#',
        basic: true,
        business: true,
        pro: true
      },
      {
        mainFeature: 'Vectorized backtesting',
        infoSrc: '#',
        basic: false,
        business: true,
        pro: true
      },
      {
        mainFeature: 'AI data analytics',
        infoSrc: '#',
        basic: false,
        business: true,
        pro: true
      },
    ];
  
    return (
      <Fragment>
        {
          pricings.map((prices, index)=>
            <div key={index} class="table-row table-body-row">
              <div class="column clumn1 detail-column">
                <div class="price-detail-title">
                  <h4>{prices.mainFeature}</h4>
                  <a href={prices.infoSrc} class="info">
                    <i class="fal fa-info-circle"/>
                  </a>
                </div>
              </div>
              <div class="column clumn2 card-column">
                { 
                  prices.basic ? <i class="far fa-check"></i> : '-'
                }
              </div>
              <div class="column clumn3 card-column heightlight">
                { 
                  prices.business ? <i class="far fa-check"/> : '-'
                }
              </div>
              <div class="column clumn4 card-column">
                { 
                  prices.pro ? <i class="far fa-check"/> : '-'
                }
              </div>
            </div>
          )
        }
      </Fragment>
    );
}

export default PricingSection;