const ContactDetails = ({}) => {

    const contactDetails = {
        phones: ['+1-303-519-3561', '+1-303-815-7016'],
        emails: ['info@gzi.app', 'sadler@gzi.app'],
        address: {
            street: '655 New York Ave NW',
            state: 'Washington, DC',
            country: 'USA',
            pin: "20001",
        }
    }

    return (
        <div className="contact-details">
            <div className="contact-details-single">
                <h3>Call us</h3>
                <p>
                    <a href={`Tel:${contactDetails["phones"][0]}`}>{contactDetails["phones"][0]}</a>
                </p>
                <p>
                    <a href={`Tel:${contactDetails["phones"][1]}`}>{contactDetails["phones"][1]}</a>
                </p>
            </div>
            <div className="contact-details-single">
                <h3>Email us</h3>
                <p>
                    <a href={`mailto:${contactDetails['emails'][0]}`}>{contactDetails['emails'][0]}</a>
                </p>
                <p>
                    <a href={`mailto:${contactDetails['emails'][1]}`}>{contactDetails['emails'][1]}</a>
                </p>
            </div>
            <div className="contact-details-single mb-0">
                <h3>Visit us</h3>
                <p>{contactDetails['address']['street']}</p>
                <p>
                    {`${contactDetails['address']['state']}, ${contactDetails['address']['country']} ${contactDetails['address']['pin']}`}
                </p>
            </div>
        </div>
    );
}

export default ContactDetails;