import {create} from 'zustand';


export const useStore = create((set) => ({
  filename: "csvjson_z1.json",
  underlying: "BABA",

  setFilename: (filename) => set({ filename }),
  setUnderlying: (underlying) => set({ underlying }),

  getFilename: () => set((state) => state.filename),
  getUnderlying: () => set((state) => state.underlying),
}));
