const Page3 = ({}) => {
  return (
    <div id="page_3">
      <div className="col-md-12 content">
        <p>
          Cookies can either be “session Cookies” or “persistent Cookies”.
          Session Cookies are temporary Cookies that are stored on your device
          while you are visiting or using our Services, whereas “persistent
          Cookies” are stored on your device for a period of time after you
          leave our Services. The length of time a persistent Cookie stays on
          your device varies from Cookie to Cookie. We use persistent Cookies to
          store your preferences so that they are available for your next visit,
          and to keep a more accurate account of how often you visit our
          Services, how often you return, and how your use of the Services may
          vary over time. We also use persistent Cookies to measure the
          effectiveness of advertising efforts. Through these Cookies, we may
          collect information about your online activity after you leave our
          Services. Your browser may offer you a “Do Not Track” or “DNT” option,
          which allows you to signal to operators of websites, and web
          applications, and services that you do not wish such operators to
          track certain of your online activities over time and across different
          websites. Because we collect browsing and persistent identifier data,
          the Services do not support Do Not Track requests at this time, which
          means that we may collect information about your online activity both
          while you are using the Services and after you leave our properties.
        </p>
        <p>
          Some Cookies are placed by a third party on your device and may
          provide information to us and third parties about your browsing habits
          (such as your visits to our Services, the pages you have visited and
          the links and advertisements you have clicked). These Cookies can be
          used to determine whether certain third party services are being used,
          to identify your interests, to retarget advertisements to you and to
          serve advertisements to you that we or others believe are relevant to
          you. We do not control third party Cookies.
        </p>
        <h5>
          <strong className="text-white">We use the following types of Cookies</strong>
        </h5>
        <p>
   
            <p>
              <span>Essential Cookies. </span>
              Essential Cookies are required for providing you with features or
              services that you have requested. For example, certain Cookies
              enable you to log into secure areas of our Services. Disabling
              these Cookies may make certain features and services unavailable.
            </p>
            <p>
              <span>Functional Cookies. </span>
              Functional Cookies are used to record your choices and settings
              regarding our Services, maintain your preferences over time and
              recognize you when you return to our Services. These Cookies help
              us to personalize our content for you, greet you by name, and
              remember your preferences (for example, your choice of language or
              region).
            </p>
            <p>
              <span>Performance/Analytical Cookies. </span>
              Performance/Analytical Cookies allow us to understand how visitors
              use our Services such as by collecting information about the
              number of visitors to the Services, what pages visitors view on
              our Services and how long visitors are viewing pages on the
              Services. Performance/Analytical Cookies also help us measure the
              performance of our advertising campaigns in order to help us
              improve our campaigns and the Services’ content for those who
              engage with our advertising. For example, Google, Inc. (“
              <span>Google</span>”) uses cookies in connection with its Google
              Analytics services. Google’s ability to use and share information
              collected by Google Analytics about your visits to the Services is
              subject to the Google Analytics Terms of Use and the Google
              Privacy Policy. You have the option to opt out of Google’s use of
              cookies by visiting the
            </p>

        </p>
      </div>
    </div>
  );
}

export default Page3;