import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import dataFormat from "../data/json/crypto_format.json";
import Slider from "react-slick";
import { connect } from "react-redux";
import { useAuth } from "../util/auth.js";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Chart from "./Chart";
import trading_currency_banner from "../assets/images/trading_currency_banner.jpg";
import TabulatorTable1 from "./TabulatorTable1";
import ContentPopup from "./ContentPopup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import App from "./sunbursts/App";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    color: "gray",
  },
}));

function ContentCardJsonData1(props) {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [num_stocks, setValue] = useState(1);
  const [formatted_data, SetFormattedData] = useState([]);
  // graph variables and initialization
  var canvas = "",
    ctx = "",
    inputPoints = "",
    inputSpeed = "",
    resetMultip = "",
    plusTen = "",
    plusOne = "",
    rangeLabels = "",
    root = "",
    mValue = "",
    dist = "",
    map = "",
    rect = "",
    line = "",
    circle = "",
    patterns = "",
    sSize = { s: 8, w: 60, h: 60, o: 2 },
    size = {
      ...sSize,
      sw: sSize.w * sSize.s,
      sh: sSize.h * sSize.s,
      mw: Math.floor(sSize.w * sSize.s * 0.5),
      mh: Math.floor(sSize.h * sSize.s * 0.5),
      lw: sSize.w * sSize.s - sSize.s,
      lh: sSize.h * sSize.s - sSize.s,
    },
    pi2 = Math.PI * 2;
  let m = 1;

  const setNumStockAdd = () => {
    setValue(num_stocks + 1);
  };

  const setNumStockSub = () => {
    setValue(num_stocks - 1);
  };

  // const drawGraph = () => {
  //   let speedVal = inputSpeed.value;
  //   const n = inputPoints.value,
  //     r = Math.floor((size.w - size.o) * size.s * 0.5);

  //   m >= 999 ? (m = 0) : (m += +speedVal);
  //   rect({ x: 0, y: 0, w: size.sw, h: size.sh }, "#222");
  //   circle({ x: size.mw, y: size.mh, r: r }, "#333");

  //   for (let i = 0; i < n; i++) {
  //     const x = Math.sin((pi2 / n) * i - pi2 * 0.25) * r,
  //       y = Math.sin((pi2 / n) * i) * r,
  //       ex = Math.sin((pi2 / n) * m * i - pi2 * 0.25) * r,
  //       ey = Math.sin((pi2 / n) * m * i) * r,
  //       Xw = size.mw + x,
  //       Yh = size.mh + y,
  //       eXw = size.mw + ex,
  //       eYh = size.mh + ey,
  //       lineL = dist(Xw, Yh, eXw, eYh),
  //       a =
  //         map(lineL, 0, r * 1.73, 1, 0.25) > 1
  //           ? 1
  //           : map(lineL, 0, r * 1.73, 1, 0.25).toFixed(2);
  //    circle({ x: Xw, y: Yh, r: size.o }, "#043c8f");
  //    line({ x: Xw, y: Yh }, { x: eXw, y: eYh }, `rgb(4,60,143)`);
  //   }
  //   let checkValue = parseFloat(Math.round(m * 100) / 100).toFixed(2);
  //   if (checkValue <= 6) {
  //     mValue.textContent = parseFloat(Math.round(m * 100) / 100).toFixed(2);
  //   } else {
  //     speedVal = 0;
  //   }
  //   // mValue.textContent = parseFloat(Math.round(m * 100) / 100).toFixed(2);
  //   root.style.setProperty("--Hsl", (+m * 100) % 360);
  //   requestAnimationFrame(drawGraph);
  // };
  // end graph variable and initiazation

  useEffect(() => {
    if (props.formatData && !props.formatDataBool) {
      let { UNDERLYING, UNDERLYING_CHECK, EXCHANGE } = "";
      let data_object = {};
      props.data.map((item, index) => {
        data_object = {};
        UNDERLYING_CHECK = "";
        UNDERLYING = item["Financial Instrument"];
        EXCHANGE =
          typeof item["Exchange"] !== "undefined" ? item["Exchange"] : "NA";
        if (EXCHANGE == "NA") {
          let words = item["Financial Instrument"].split(" ");
          if (words.length > 1) {
            EXCHANGE = words[1];
            UNDERLYING_CHECK = words[0];
          } else {
            EXCHANGE = "SMART";
          }
        }
        data_object["DES"] = "DES";
        data_object["UNDERLYING"] = UNDERLYING;
        data_object["UNDERLYING_CHECK"] = UNDERLYING_CHECK;
        data_object["SECTYPE"] = "STK";
        data_object["EXCHANGE"] = EXCHANGE;
        data_object["LASTTRADINGDAY"] = "";
        data_object["STRIKE"] = "";
        data_object["PUT"] = {
          CALL: "",
        };
        data_object["MULTIPLIER"] = "";
        formatted_data.push(data_object);
      });
      SetFormattedData(formatted_data);
    }

    props.removeData({});
    let dataJson = [{}];
    let datakey = [];
    dataFormat.map((key, index_2) => {
      datakey.push(key);
    });
    props.data.map((item, index) => {
      dataJson[index] = {};
      Object.entries(item).map((key, index_1) => {
        //if (index_1 < 11) {
        dataJson[index][datakey[index_1]] = { label: key[0], value: key[1] };
        //}
      });
    });
    setData(dataJson);
    // console.log(dataJson);
    props.saveData(dataJson[0]);

    // graph working
    canvas = document.querySelector(".graph");
    // ctx = canvas.getContext("2d");
    // inputPoints = document.getElementById("ProjectedGains");
    // inputSpeed = document.getElementById("Risk");
    // resetMultip = document.getElementById("reset");
    // plusTen = document.getElementById("plusTen");
    // plusOne = document.getElementById("plusOne");
    // rangeLabels = document.querySelectorAll(".slideContainer h4");
    // root = document.documentElement;
    // mValue = document.getElementById("mValue");
    canvas.width = size.sw;
    canvas.height = size.sh;

    // dist = (x1, y1, x2, y2) => {
    //   return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    // };

    // map = (value, minA, maxA, minB, maxB) => {
    //   return (
    //     (1 - (value - minA) / (maxA - minA)) * minB +
    //     ((value - minA) / (maxA - minA)) * maxB
    //   );
    // };

    // rect = (r, color) => {
    //   ctx.fillStyle = color;
    //   ctx.fillRect(r.x, r.y, r.w, r.h);
    // };

    // line = (s, e, color) => {
    //   ctx.strokeStyle = color;
    //   ctx.beginPath();
    //   ctx.moveTo(s.x, s.y);
    //   ctx.lineTo(e.x, e.y);
    //   ctx.stroke();
    // };

    // circle = (c, color) => {
    //   ctx.strokeStyle = color;
    //   ctx.beginPath();
    //   ctx.arc(c.x, c.y, c.r, 0, pi2);
    //   ctx.stroke();
    // };

    // patterns = (value) => {
    //   m = value;
    //   inputSpeed.value = 0;
    //   inputPoints.value = 200;
    // };

    // rangeLabels.forEach((label) => {
    //   const word = [...label.textContent];
    //   label.innerHTML = "";
    //   word.forEach((letter) => {
    //     const spannedLetter = document.createElement("span");
    //     spannedLetter.textContent = letter;
    //     label.appendChild(spannedLetter);
    //   });
    // });

    // document.querySelectorAll(".controlBtn").forEach((btn) => {
    //   btn.addEventListener("click", () => {
    //     const btnValue = btn.getAttribute("data-value");
    //     btnValue == 0 ? (m = 0) : (m += +btnValue);
    //   });
    // });
    // drawGraph();
    // end graph working
  }, [props]);

  const setItem = async (item) => {
    // setNumStockAdd();
    props.setRender(false);
    props.saveData(item);
  };

  return (
    <>
      <div className="col-left on-desktop">
        <div className="left-c">
          <div className="table IBSBVL">
            <table style={{ tableLayout: "fixed", width: "100%" }}>
              <tbody>
                <tr>
                  <th>
                    <div className="select-field">
                      <select className="form-control" id="stock_exchange">
                        <option>Volatility</option>
                        <option>High</option>
                        <option>Medium</option>
                        <option>Low</option>
                      </select>
                    </div>
                  </th>
                  <th>
                    <div className="select-field">
                      <select className="form-control" id="sector">
                        <option>Time Zone</option>
                        <option>Z1: EST+/- 1</option>
                        <option>Z2: EST+ 5-9</option>
                        <option>Z3: EST+ 10-12</option>
                      </select>
                    </div>
                  </th>
                  <th>
                    <div className="select-field">
                      <select className="form-control" id="currency">
                        <option>Alternative Data</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                  </th>
                  <th>
                    <div className="select-field">
                      <select className="form-control" id="industry">
                        <option>Dividend Potential</option>
                        <option>High</option>
                        <option>Medium</option>
                        <option>Low</option>
                      </select>
                    </div>
                  </th>
                  <th></th>
                </tr>
                {data.length > 0 &&
                  data.map((item, index) => (
                    <tr style={{ wordBreak: "break-word" }}>
                      {item.exchange.value.split(" ").length > 1 ? (
                        <td>
                          {item.exchange.value.split(" ")[0]}
                          <br />
                          {item.exchange.value.split(" ")[1]}
                        </td>
                      ) : (
                        <td>
                          {item.financial_instrument.value}
                          <br />
                          {item.exchange.value}
                        </td>
                      )}
                      {/* <td>{item.exchange.value}</td> */}
                      <td>{item.industry.value}</td>
                      <td>{item.trading_currency.value}</td>
                      <td>{item.category.value}</td>
                      <td>
                        <a
                          className="td_plus_icon_a"
                          onClick={() => {
                            setItem(item);
                          }}
                        >
                          <i className="fa-solid fa-circle-plus"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-left on-mobile">
        <div className="col-price-details">
          {data.length > 0 &&
            data.map((item, index) => (
              <ul>
                <li>
                  <p>
                    NAME :{" "}
                    <span>
                      <strong>{item.financial_instrument.value}</strong>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    STOCK EXCHANGE : <span>{item.exchange.value}</span>
                  </p>
                </li>
                <li>
                  <p>
                    SECTOR : <span>{item.industry.value}</span>
                  </p>
                </li>
                <li>
                  <p>
                    CURRENCY : <span>{item.trading_currency.value}</span>
                  </p>
                </li>
                <li>
                  <p>
                    INDUSTRY : <span>{item.category.value}</span>
                  </p>
                  <p className="plus-icon">
                    <a
                      className="td_plus_icon_a"
                      onClick={() => {
                        setItem(item);
                      }}
                    >
                      <i class="fa-solid fa-plus"></i>
                    </a>
                  </p>
                </li>
              </ul>
            ))}
        </div>
      </div>

      <div className="col-right on-desktop">
        {/* <div className="col-price-details for-mobile">
          {data.length > 0 &&
            data.map((item, index) => (
              <ul>
                <li>
                  <p>
                    DES :{" "}
                    <span>
                      <strong>DES</strong>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    UNDERLYING : <span>{item.financial_instrument.value}</span>
                  </p>
                </li>
                <li>
                  <p>
                    SECTYPE : <span>STK</span>
                  </p>
                </li>
                <li>
                  <p>
                    EXCHANGE : <span>{item.exchange.value}</span>
                  </p>
                </li>
              </ul>
            ))}
        </div> */}
        {console.log("data", data.length)}
        {data.length > 0 && props.formatData ? (
          <TabulatorTable1
            data={data}
            show={true}
            interactiveBroker={formatted_data}
            render={props.render}
            setNumStock={(a) => setValue(a)}
          />
        ) : (
          <TabulatorTable1
            data={data}
            show={true}
            interactiveBroker={props.interactiveBroker}
            render={props.render}
            setNumStock={(a) => setValue(a)}
          />
        )}

        {/* {data.length > 0 &&
                    <TabulatorTable1 
                        data={data} 
                        show={true}
                        interactiveBroker={ props.interactiveBroker}
                        render={props.render} setNumStock={(a) => setValue(a)} />
                } */}

        <div className="multiplier-wrap">
          {/* <div className="multiplier-plusTen">
            <h4 className="py-2">Number of Stocks</h4>
            {/* <input className="controlBtn" type="text" value="10" id="plusTen" /> */}
            {/* <button className="controlBtn" data-value={num_stocks} id="plusTen">
              {num_stocks}
          </div> 
            </button>
          */}

          <div className="multiplier"
          style={{display:"none"}}
          >
            <h4 className="py-2">Leverage Ratio </h4>
           
              <span id="mValue"></span>
            
          </div>
        </div>
      <div className="sunburstgraphcontainer">
        <div className="innersunburstcontainer">
        
              <canvas className="graph running-circle">

              </canvas>
    
                      
              <div className="sunburstgraph">
                  <App/>
              </div>
          </div>
       
    

        {/* <div className="main-wrap11">
          
          <div className="slideContainer slideContainer--speed">
            <input type="range" id="Risk" className="slider"
              name="speed" min="0" max="0.02"defaultValue="0.008"step="0.0001"/>
            <h4 draggable="false">  Risk</h4>
          </div>


          <div className="slideContainer slideContainer--points">
            <input type="range" id="ProjectedGains" className="slider"
              name="points" min="2" max="200" defaultValue="160" step="1"/>
            <h4 draggable="false">  Projected Gains</h4>
          </div>

        </div> */}
        
      </div> 



       



        {/* <h3 className="financial-modeling text-white text-uppercase h5 text-center mt-5 mb-3">
          Financial Modeling Tools
        </h3> */}
        {/* <div className="d-flex  my-2 justify-content-center align-items-center">
          <button
            className="m-auto px-5 py-2 content-chart-info-btn"
            onClick={() => {
              history.push("/sunburst");
            }}
          >
            Info
          </button>
        </div> */}
        {/* <div className="coolPatterns-wrap row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-4 text-center col-6">
                <button
                  onclick="patterns(21)"
                  style={{
                    backgroundImage:
                      'url("https://raw.githubusercontent.com/EntropyReversed/times-tables/master/21m.gif")',
                  }}
                ></button>
                <p className="text-white text-uppercase h6">
                  Vectorized Backtesting
                </p>
              </div>
              <div className="col-md-4 text-center col-6">
                <button
                  onclick="patterns(51)"
                  style={{
                    backgroundImage:
                      'url("https://raw.githubusercontent.com/EntropyReversed/times-tables/master/51.gif")',
                  }}
                ></button>
                <p className="text-white text-uppercase h6">Option Valuation</p>
              </div>
              <div className="col-md-4 text-center col-6">
                <button
                  onclick="patterns(99)"
                  style={{
                    backgroundImage:
                      'url("https://raw.githubusercontent.com/EntropyReversed/times-tables/master/99m.gif")',
                  }}
                >
                  {" "}
                </button>
                <p className="text-white text-uppercase h6">
                  Pairs <br />
                  Trading{" "}
                </p>
              </div>
              <div className="col-md-4 text-center col-6">
                {" "}
                <button
                  onclick="patterns(86)"
                  style={{
                    backgroundImage:
                      'url("https://raw.githubusercontent.com/EntropyReversed/times-tables/master/86m.gif")',
                  }}
                ></button>
                <p className=" text-white text-uppercase h6">Risk Clustering</p>
              </div>
              <div className="col-md-4 text-center col-6">
                {" "}
                <button
                  onclick="patterns(91)"
                  style={{
                    backgroundImage:
                      'url("https://raw.githubusercontent.com/EntropyReversed/times-tables/master/91m.gif")',
                  }}
                ></button>
                <p className=" text-white text-uppercase h6">Jump Diffusion</p>
              </div>
              <div className="col-md-4 text-center col-6">
                <button
                  onclick="patterns(68)"
                  style={{
                    backgroundImage:
                      'url("https://raw.githubusercontent.com/EntropyReversed/times-tables/master/68m.gif")',
                  }}
                ></button>
                <p className="text-white text-uppercase h6">
                  Square Root Diffusion
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveData: (data) => dispatch({ type: "SAVE_DATA", payload: data }),
    removeData: (data) => dispatch({ type: "REMOVE_DATA", payload: data }),
  };
};

export default connect(null, mapDispatchToProps)(ContentCardJsonData1);
