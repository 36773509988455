
import {createStore, combineReducers,applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk';
import ReducerValue from '../Reducers/index'
import storage from 'redux-persist/lib/storage'
 import {persistReducer} from 'redux-persist';
const persistConfig = {
    key: 'root',
    storage: storage,
};
const Reducer = combineReducers({ReducerValue});
const persistedReducer = persistReducer(persistConfig, Reducer);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const Store =createStore( persistedReducer,composeEnhancer(applyMiddleware(thunk)));
export default Store;
