import React from "react";
import Section from "./Section";
import Header from "./Header";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import Footer from "./Footer";
import Chart from "./Chart";

function AuthSection(props) {
	// Values for each auth type
	const allTypeValues = {
		signin: {
			// Top title
			title: "Welcome back",
			// Submit button text
			buttonText: "Sign in",
			// Link text to other auth types
			linkTextSignup: "Create an account",
			linkTextForgotpass: "Forgot Password?",
		},
		signup: {
			title: "Get yourself an account",
			buttonText: "Sign up",
			linkTextSignin: "Sign in",
		},
		forgotpass: {
			title: "Get a new password",
			buttonText: "Reset password",
		},
		changepass: {
			title: "Choose a new password",
			buttonText: "Change password",
		},
	};

	// Ensure we have a valid auth type
	const currentType = allTypeValues[props.type] ? props.type : "signup";

	// Get values for current auth type
	const typeValues = allTypeValues[currentType];

	const getHeading = () => {
		let heading = null;
		if(props.type=="signin"){
			heading = "Sign In";
		}else if(props.type=="signup"){
			heading = "Sign Up";
		}else if(props.type=="forgotpass"){
			heading = "Forgot Password";
		}else {
			heading = "Change Password";
		}
	return heading ;
	}

	// useEffect(() => {
	// 	jQuery(window).scroll(function () {
	// 		if (jQuery(this).scrollTop() > 1) {
	// 			jQuery('header').addClass("sticky");
	// 		}
	// 		else {
	// 			jQuery('header').removeClass("sticky");
	// 		}
	// 	});	
	// });

	return (
		<>
			<Header {...props} pageName={props.type} bgImage={props.bgImage} heading={getHeading()} />
				<Auth
					type={currentType}
					typeValues={typeValues}
					providers={props.providers}
					afterAuthPath={props.afterAuthPath}
					key={currentType}
				/>
			<Footer {...props}/>
		</>
	);
}

export default AuthSection;
