const FAQNavSection = ({setSelectedHeaderTopic, selectedHeaderTopic}) => {
    const faqHeaderTopics = [
        { title: 'General' },
        { title: 'Crypto' },
        { title: 'Subscription' },
        { title: 'Pricing' },
    ];

    return (
        <div className="faq-nav">
            <ul className="nav">
                {
                    faqHeaderTopics.map((topic, index)=>
                        <li key={`${topic}-${index}`}>
                            <label
                                className={`${topic.title == selectedHeaderTopic ? 'active' : ''}`}
                                href='#'
                                style={{cursor: 'pointer'}}
                                data-bs-toggle="tab"
                                data-bs-target="demo"
                                onClick={()=>setSelectedHeaderTopic(topic.title)}
                            >
                                <input type="radio" name="faq-nav" />{" "}
                                <span>{topic.title}</span>
                            </label>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}

export default FAQNavSection;