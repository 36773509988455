const FAQs = ({selectedHeaderTopic}) => {
    const faqs = {
        'General': [
            {
                faqHeading: `Does GZI handle securities or cryptocurrenices?`,
                faqSubHeadings: [
                    `No, GZI offers algorithmically pre-screened financial data and analytics for equities and cryptocurrencies. We do not make investment reccomendations.`,
                    `We provide data and analytics services.`
                ],
                contentVideoSrc:``,
                contentHeading: 'Which platforms is GZI compatible with?',
                contentSubHeadings: [
                    `GZI is currently formatted for TD Ameritrade's Think or Swim platform and Interactive Brokers' Trader Workstation. International equities data is only compatible with Trader Workstation. Please contact your brokerage about access to international securities if you are facing compatibility issues.`,
                    `Submit a request to include your brokerage and customer service will respond shortly.`
                ],
                LearnMoreSrc:'#',
    
            },
            {
                faqHeading: `What is alternative data and why do I want to use it?`,
                faqSubHeadings: [
                    `Simply pick whatever is of interest to you and enjoy. This is elegance in simplicity for your investments.`,
                    `Alternative data is powered by synthetic AI that combines alternative factors with quantamentals. We process everything on the backend and keep the front-end simple.`
                ],
                contentVideoSrc:``,
                contentHeading: '',
                contentSubHeadings: [`Simply pick whatever is of interest to you and enjoy. This is elegance in simplicity for your investments.`,
                    `Alternative data is powered by synthetic AI that combines alternative factors with quantamentals. We process everything on the backend and keep the front-end simple.`],
                LearnMoreSrc:'#',
    
            }
        ],
        'Crypto': [
            {
                faqHeading: `Does GZI's data pertain to cryptocurrencies or cryptocurrency companies?'`,
                faqSubHeadings: [
                    `Both. We intend to offer cryptocurrency data for integrated analytics in the near future. Existing cryptocurrency data is for public markets only`,
                    `We provide data and analytics services.`
                ],
                contentHeading: '',
                contentSubHeadings: [
                    `Both. We intend to offer cryptocurrency data for integrated analytics in the near future. Existing cryptocurrency data is for public markets only`,
                    `We provide data and analytics services.`
                ],
                LearnMoreSrc:'#',
    
            }
        ],
        'Subscription': [
            {
                faqHeading: `Can GZI charge a percentage instead of a regular fee?`,
                faqSubHeadings: [
                    `Not yet. We are discussing changes and will provide updates once GZI can accomodate the compliance requirements.`
                ],
                contentHeading: '',
                contentSubHeadings: [
                    `Not yet. We are discussing changes and will provide updates once GZI can accomodate the compliance requirements.`
                ],
                LearnMoreSrc:'#',
    
            }
        ],
    };

    return (
        <div className="faq-wrap" id="faq-parent">
        {
            faqs[selectedHeaderTopic] && faqs[selectedHeaderTopic].map((faq, index)=>
                <div key={`${selectedHeaderTopic}_${index}`} className="single-faq">
                    <a
                        href="#"
                        className="toggler-header collapsed"
                        data-toggle="collapse"
                        data-target={`#${selectedHeaderTopic}Faq${index+1}`}
                    >
                        <h3> { faq.faqHeading } </h3>
                        {/* {
                            faq.faqSubHeadings.map((faqSubHeading, index) => <p key={index}> { faqSubHeading } </p> )
                        } */}
                        <span className="chevron">
                            <i className="far fa-chevron-down" />
                        </span>
                    </a>
                    <div
                        className={`collapsible-content collapse`}
                        data-bs-parent="#faq-parent"
                        id={`${selectedHeaderTopic}Faq${index+1}`}
                    >
                        <div className="collapsible-content-inner">
                            <div className="media-side">
                                <iframe
                                    className="video-element"
                                    src={faq.contentVideoSrc}
                                    title="YouTube video player"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen />
                            </div>
                            {   
                                faq.contentHeading && <div className="single-faq-content">
                                    <h3> { faq.contentHeading } </h3>
                                    {
                                        faq.contentSubHeadings.map(contentSubHeading => <p> { contentSubHeading } </p> )
                                    }
                                    <a
                                        href={faq.LearnMoreSrc}
                                        className="button default-button default-button--border default-button--border-black"
                                    >
                                        Learn more
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }    
        </div>
    );
}

export default FAQs;
