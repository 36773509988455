import "./App.css";
import React, { useRef, useEffect } from "react";
import Sunbursts from "./sunbursts";
import {useStore} from '../../zustand/zustandstore';


function App({filerbase}) {
  const sunburstsContainer = useRef(null);
  const fileload=useStore((state) => state.filename)
  const filterbase=useStore((state) => state.underlying)
  const getData = () => {
    const el = sunburstsContainer.current;
    el.addEventListener("sunburst-click", (event) => {
      const { cellData, sunburstData } = event.detail;
      
    });
    fetch(fileload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        new Sunbursts({
          el,
          data,
          filterbase,
        });
      });
  };
  useEffect(() => {
    getData();
  }, [
    useStore((state) => state.underlying),
    useStore((state) => state.filename)
  ]);
  return (
    <div className='sunburst-container'>
      <div className='sunburst-App'>
        <div ref={sunburstsContainer} className='sunbursts'></div>
      </div>
    </div>
  );
}

export default App;
