import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

//Sub Sections
import FAQNavSection from "../../components/faq/FAQNavSection";
import FAQs from "../../components/faq/FAQs";

const FAQ = () => {
  const [ selectedHeaderTopic, setSelectedHeaderTopic] = useState('General');

  return (
    <div>
      <Header />
      <div className="main-wrap">
        <main style={{paddingTop: '5rem'}}>
          <section className="faq-section">
            <div className="container">
              <div className="faq-section-inner">
                <div className="section-title">
                  <h4>Learn How To Get Started</h4>
                  <h2>Frequently Asked Questions</h2>
                  <p>
                    Join GZI community now to get free updates and exclusive
                    resources curated <br />
                    by our experts. Questions?{" "}
                    <span className="color-theme1 fw-600">Contact Support</span>
                  </p>
                </div>
                <FAQNavSection selectedHeaderTopic={selectedHeaderTopic} setSelectedHeaderTopic={setSelectedHeaderTopic}/>
                <FAQs selectedHeaderTopic={selectedHeaderTopic}/>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default FAQ;
