import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header"
import ContactForm from '../../components/contactUs/ContactForm';
import ContactDetails from '../../components/contactUs/ContactDetails';

const ContactUs = () => {
  return (
    <div>
      <Header/>
      <div className="main-wrap">
        <main style={{paddingTop: '7rem'}}>
          <section className="contact-us-section">
            <div className="container">
              <div className="contact-us-section-inner">
                <div className="section-title dark-section-title">
                  <h2>Get in touch with us.</h2>
                  <p>
                    We would love to hear from you. GZI can help set you apart from others.{" "}
                  </p>
                </div>
                <div className="contact-us-section-row">
                  <ContactForm />
                  <ContactDetails />
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
