const Page4 = ({}) => {
  return (
    <div id="page_4">
      <div className="col-md-12 content">
        <p>
          Google advertising <nobr>opt-out</nobr> page at
          www.google.com/privacy_ads.html or the Google Analytics{" "}
          <nobr>Opt-out</nobr> Browser <nobr>Add-on</nobr> at
          https://tools.google.com/dlpage/gaoptout/.
        </p>

        <p>
          <span>Retargeting/Advertising Cookies. </span>
          Retargeting/Advertising Cookies collect data about your online
          activity and identify your interests so that we can provide
          advertising that we believe is relevant to you. For more information
          about this, please see the section below titled “Additional
          information about <nobr>Interest-Based</nobr> Advertisements.”
        </p>
        <p>
          You can decide whether or not to accept Cookies. One way you can do
          this is through your internet browser’s settings. Most browsers have
          an option for turning off the Cookie feature, which will prevent your
          browser from accepting new Cookies, as well as (depending on the
          sophistication of your browser software) allow you to decide on
          acceptance of each new Cookie in a variety of ways. You can also
          delete all Cookies that are already on your computer. If you do this,
          however, you may have to manually adjust some preferences every time
          you visit a site and some Services and functionalities may not
          function properly.
        </p>
        <p>
          To explore what Cookie setting are available to you, look in the
          “preferences” or “options” section of your browser’s menu. To find out
          more information about Cookies, including information about how to
          manage and delete Cookies, please visit{" "}
          <a href="http://www.allaboutcookies.org/">
            http://www.allaboutcookies.org/.
          </a>
        </p>
        <h5>
          <strong className="text-white">
            Additional Information about <nobr>Interest-Based</nobr>{" "}
            Advertisements
          </strong>
        </h5>
        <p>
          <span>
            We may serve advertisements, and also allow third party ad networks,
            including third party ad servers, ad agencies, ad technology vendors
            and research firms, to serve advertisements through the Services.
            These advertisements may be targeted to users who fit certain
            general profile categories or display certain preferences or
            behaviors{" "}
          </span>
          <nobr>
            (“
            <span>Interest-Based</span>
          </nobr>
          <span> Ads</span>”). Information for <nobr>Internet-Based</nobr> Ads
          (including Personal Data) may be provided to us by you or derived from
          the usage patterns of particular users on the Services and/or services
          of Third Parties. Such information may be gathered through tracking
          users’ activities across time and unaffiliated properties. To
          accomplish this, we or our service providers may deliver Cookies,
          including a file (known as a “web beacon”) from an ad network to you
          through the Services. Web beacons allow ad networks to provide
          anonymized, aggregated auditing, research and reporting for us and for
          advertisers. Web beacons also enable ad networks to serve targeted
          advertisements to you when you visit other websites. Web beacons allow
          ad networks to view, edit or set their own Cookies on your browser,
          just as if you had requested a web page from their site.
          <span>Through the Digital Advertising Alliance (“</span>
          <span>DAA</span>”) and Network Advertising Initiative (“
          <span>NAI</span>”), several media and marketing associations have
          developed an industry self- regulatory program to give consumers a
          better understanding of and greater control over ads that are
          customized based on their online behavior across different websites.
          To make choices about <nobr>Interest-Based</nobr> Ads from
          participating third parties, including to opt out of receiving
          behaviorally targeted advertisements from participating organizations,
          please visit the DAA’s or NAI’s consumer opt out pages, which are
          located at{" "}
          <a href="http://www.networkadvertising.org/choices/">
            http://www.networkadvertising.org/choices/or{" "}
          </a>
          <a href="http://www.aboutads.info/choices">
            www.aboutads.info/choices.
          </a>
        </p>
      </div>
      <div className="col-md-12 content">
        <h5>
          <strong className="text-white">
            Information We Receive From Third Party Sources
          </strong>
        </h5>
      </div>
    </div>
  );
}

export default Page4;