import TermsPage1 from "./Terms/Page1";
import TermsPage2 from "./Terms/Page2";
import TermsPage3 from "./Terms/Page3";
import TermsPage4 from "./Terms/Page4";
import TermsPage5 from "./Terms/Page5";
import TermsPage6 from "./Terms/Page6";
import TermsPage7 from "./Terms/Page7";
import TermsPage8 from "./Terms/Page8";
import TermsPage9 from "./Terms/Page9";
import TermsPage10 from "./Terms/Page10";
import TermsPage11 from "./Terms/Page11";
import TermsPage12 from "./Terms/Page12";
import TermsPage13 from "./Terms/Page13";
import TermsPage14 from "./Terms/Page14";
import TermsPage15 from "./Terms/Page15";

const TermsAndConditionSection = ({handleTermsToggle}) => {
    return (
        <div id="home" class="tab-pane in active">
            <TermsPage1 />
            {/* <TermsPage2 /> */}
            {/* <TermsPage3 /> */}
            {/* <TermsPage4 /> */}
            {/* <TermsPage5 /> */}
            {/* <TermsPage6 handleTermsToggle={handleTermsToggle} /> */}
            {/* <TermsPage7 /> */}
            {/* <TermsPage8 /> */}
            {/* <TermsPage9 handleTermsToggle={handleTermsToggle} /> */}
            {/* <TermsPage10 /> */}
            {/* <TermsPage11 />
            <TermsPage12 />
            <TermsPage13 />
            <TermsPage14 />
            <TermsPage15 /> */}
        </div>
    );
}

export default TermsAndConditionSection;