import React from "react";
import HeroSection from "../../components/HeroSection";
import FeaturesSection from "../../components/FeaturesSection";
import TestimonialsSection from "../../components/TestimonialsSection";
import ClientsSection from "../../components/ClientsSection";
import NewsletterSection from "../../components/NewsletterSection";
import aboutPng from "../../assets/images/abt_ban1.jpg";
import m1 from "../../assets/images/m1.png";
import m2 from "../../assets/images/m2.png";
import m3 from "../../assets/images/m3.png";
import Chart from "../../components/Chart";
import {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselValueModal from "../CarouselValueModal";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import dataFormat from '../../data/json/crypto_format.json';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {redirectToCheckout} from "../../util/stripe";
import TabulatorTable from '../../components/TabulatorTable.js'
import { useAuth } from "../../util/auth.js";
import { useRouter } from "../../util/router.js";
import chart1 from "../../assets/images/chart1.png";

import Header from "../../components/Header"
import Footer from "../../components/Footer";

// Sub Sections
// import TeamSection from '../../components/aboutUs/TeamSection';
import GridSection from '../../components/aboutUs/GridSection';

function AboutPage(props) {
  const auth = useAuth();
  const router = useRouter()
  const { key } = router.query

  return (
    <div>
      <Header {...props} pageName={"about"} bgImage={aboutPng} />
      <div className="main-wrap">
        <main style={{paddingTop: '5rem'}}>
          <GridSection />
          {/*<TeamSection />*/}
        </main>
      </div>
    </div>
  );
};

export default AboutPage;
