import React from "react";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router.js";

import signin_ban from "../assets/images/signin_ban.jpg";

function AuthPage(props) {
  const router = useRouter();
  return (
    <AuthSection
      {...props}
      bgColor="default"
      size="medium"
      bgImage={signin_ban}
      bgImageOpacity={1}
      heading="" 
      type={router.query.type}
      providers={["google", "facebook", "twitter", "linkedin", "reddit", "github"]}
      afterAuthPath={router.query.next || "/"}
    />
  );
}

export default AuthPage;
