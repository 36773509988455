import React from 'react'
import { useRouter } from "../util/router.js";
// import logo from "../assets/images/145x114_inches.svg";
import logo from "../assets/images/156x114.png";



import "../assets/css/gzi.css";

 function Footer(props) {
    const router = useRouter();
    const page_name = props.pageName;
  return (
      <>
    <footer>
    <section className="about_footer">
        <section className="platform_banner_sections ">
            <div className="container">
                <div className="platform_bg ">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 col-xs-12">
                            <div className="platform_text">
                                <h2>Try the Platform for Yourself</h2>
                                <p>
                                    Ask a question and a member of the team will be in touch soon.
                                </p>
                                <div className="btn_div">
                                    <a href="https://bit.ly/gzicalendly" className="filled_btn ">Book a Demo</a>
                                    <a href="/content" className="outline_btn">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="only_text_section ">
            <div className="container">
                <div className="text_only_box">
                    <p>
                        Nothing contained on this site constitutes tax, legal, insurance or investment advice. Neither the information, nor any opinion, contained on this site constitutes a solicitation or offer by GZI or its affiliates to buy or sell any securities, futures, options or other financial instruments, nor shall any such security be offered or sold to any person in any jurisdiction in which such offer, solicitation, purchase, or sale would be unlawful under the securities laws of such jurisdiction.
                    </p>
                    <p>
                    The risk of loss in online trading of stocks, options, futures, currencies, foreign equities, and fixed income can be substantial.
                </p>
                <p>
                    IN NO EVENT SHALL ANY OF THE GZI PARTIES BE LIABLE FOR ANY INVESTMENT GAIN/LOSS YOU INCUR DURING YOUR INVESTMENT IN THE STOCK MARKET.
                    </p>
                </div>
            </div>
        </section>
        <section className="footer-sec">
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                        <div className="footer_col footer-logo">
                            <img src={logo}/>
                            <p>GZI empowers investors with alternative financial data engineering.</p>
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-9 col-xs-12">
                        <div id="accordion" className="row">
                            <div className="footer_col card col-md-4 col-sm-4 col-xs-12">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Contact
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body">
                                        <div className="block_box">
                                            <h3>Write to Us</h3>
                                            <ul className="footer_links flex_ul_li">
                                                <li>
                                                    <i class={page_name == 'terms'? 'fa fa-envelope' : 'fa fa-envelope'}></i>
                                                    <a href="mailto:sean.adler@gzi.finance">sean.adler@gzi.finance</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="block_box">
                                            <h3>Give us a Call</h3>
                                            <ul className="footer_links flex_ul_li">
                                                <li>
                                                    <i class={page_name == 'terms'? 'fa fa-phone-alt' : 'fa fa-phone-alt'}></i>
                                                    <a className="no_decor" href="tel:+1(303)519-3561">+1-(303)-519-3561</a>
                                                </li>
                                                <li>
                                                    <i class={page_name == 'terms'? 'fa fa-phone-alt' : 'fa fa-phone-alt'}></i>
                                                    <a className="no_decor" href="#">Schedule A Call</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer_col card col-md-4 col-sm-4 col-xs-12">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Sitemap
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div className="card-body">
                                        <div className="block_box">
                                            <ul className="footer_links block_ul_li">
                                                <li>
                                                    <a onClick={() => router.history.push("/home")}>Home</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => router.history.push("/about-us")}>About</a>
                                                </li>
                                                <li>
                                                    <a a onClick={() => router.history.push("/content")}>Content</a>
                                                </li>
                                                {/*<li>
                                                    <a onClick={() => router.history.push("/pricing")}>Pricing</a>
                                                </li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer_col card col-md-4 col-sm-4 col-xs-12">
                                <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Account
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="card-body">
                                        <div className="block_box">
                                            <ul className="footer_links block_ul_li">
                                                <li>
                                                    <a href="/auth/signin" onClick={() => { router.history.push("/auth/signin")}}>Log In</a>
                                                </li>
                                                <li>
                                                    <a href="/auth/signup"onClick={() => { router.history.push("/auth/signup")}}>Open An Account</a>
                                                </li>
                                                <li>
                                                    <a href="/auth/signup">Free Trail</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="footer_col">
                            <h2>Contact</h2>
                            <div className="block_box">
                                <h3>Write to us</h3>
                                <ul className="footer_links flex_ul_li">
                                    <li>
                                        <i className="fal fa-envelope"></i>
                                        <a href="mailto:sadler@gzi.app">sadler@gzi.app</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="block_box">
                                <h3>Give us a Call</h3>
                                <ul className="footer_links flex_ul_li">
                                    <li>
                                        <i className="fal fa-phone-alt"></i>
                                        <a className="no_decor" href="tel:+1(303)519-3561">+1-(303)-519-3561</a>
                                    </li>
                                    <li>
                                        <i className="fal fa-phone-alt"></i>
                                        <a className="no_decor" href="#">Schedule A Call</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="footer_col noborder_tab">
                            <h2>Sitemap</h2>
                            <div className="block_box">
                                <ul className="footer_links block_ul_li">
                                    <li>
                                        <a onClick={() => router.history.push("/home")}>Home</a>
                                    </li>
                                    <li>
                                        <a onClick={() => router.history.push("/about-us")}>About</a>
                                    </li>
                                    <li>
                                        <a a onClick={() => router.history.push("/content")}>Content</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="footer_col noborder_mob noborder_tab">
                            <h2>Account</h2>
                            <div className="block_box">
                                <ul className="footer_links block_ul_li">
                                    <li>
                                        <a href="/auth/signin" onClick={() => { router.history.push("/auth/signin")}}>Log In</a>
                                    </li>
                                    <li>
                                        <a href="/auth/signup"onClick={() => { router.history.push("/auth/signup")}}>Open An Account</a>
                                    </li>
                                    <li>
                                        <a href="/auth/signup">Free Trail</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="copyright_box">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <p>© 2023 GZI Hub Inc. All Rights Reserved.</p>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <ul>
                                <li>
                                    <a href="#">
                                    <i className="fa-brands fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                <a target="_blank" href ="https://twitter.com/swan_gzi">
                                    <i className="fa-brands fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                <a target="_blank" href ="https://bit.ly/gzi_community">
                                    <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <p className="last_p">
                                <a  onClick={() => router.history.push("/terms-and-condition")}>Terms & Conditions</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    </section>
</footer>

</>
  )
}
export default Footer;

























// import React from "react";
// import Newsletter from "./Newsletter";
// import logo from "../assets/images/logo.png";
// import ln from "../assets/images/in.png";
// import rd from "../assets/images/rd.png";
// import tw from "../assets/images/tw.png";
// import md from "../assets/images/md.png";
// import "../assets/css/gzi.css";
// import { useRouter } from "../util/router.js";

// function Footer(props) {
//     const router = useRouter();

//    return (
//     <>
//      <head>
//       <title>Bootstrap Example</title>
//       <meta charset="utf-8"/>
//       <meta name="viewport" content="width=device-width, initial-scale=1"/>
//       <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
//    </head>
   
//    <footer>
//     <div className="footer-div">
//         <div className="twen">
//             <img alt="logo" src={logo} height="auto" width="50%"/>
//         </div>
//         <div className="thrt">
//             <ul>
//                 <div className="menu-a">
//                 <li><a onClick={() => router.history.push("/home")}>Home</a></li>
//                 <li><a onClick={() => router.history.push("/about-us")}>About</a></li>
//                 <li><a onClick={() => router.history.push("/content")}>Content</a></li>
//                 <li><a onClick={() => router.history.push("/faq")}>FAQ</a></li>
//                 <li><a onClick={() => router.history.push("/contact-us")}>Contact</a></li>
//                 </div>
//                 <div className="menu-b">
//                 <li><a onClick={() => router.history.push("/pricing")}>Pricing</a></li>
//                 <li><a onClick={() => router.history.push("/terms-and-condition")}>Terms & Conditions</a></li>
//                 </div>
//             </ul>
//         </div>
//         <div className="thrt contact">
//             <h4>CONTACT</h4>
//             <p>655 New York Ave NW</p>
//             <p>Washington, DC</p>
//             <p>USA</p>
//             <p>303-519-3561</p>
//         </div>
//         <div className="thrt contact last">
//             <h4>NEWSLETTER</h4>
//             <p>Stay under our wing with<br/>
//                 enclusive resources and tips.<br/>
//                 </p>
//                 <form>
//                     <div className="newslt">
//                       <Newsletter/>
//                     </div>
  
//                 </form>
//         </div>
//     </div>
//     <div className="copyright">
//         <div className="copyr">
//             Copyright © 2022 GZI All right reserved
//         </div>
//         <div className="social-icons">
//             <a target="_blank" href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
//              <a target="_blank" href="https://twitter.com/swan_gzi"><i className="fa fa-twitter" aria-hidden="true"></i></a>
//               <a target="_blank" href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
//                <a target="_blank" href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
//                 <a target="_blank" href="#"><i className="fa fa-medium" aria-hidden="true"></i></a>
  
//         </div>
//     </div>
//   </footer>
//   </>
//   )
// }

// export default Footer;
