import React, { useState, useRef, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
//mui icon

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import logo from "../assets/images/145x114.png";
import bg from "../assets/images/content-popup.png";
import cancelIcon from "../assets/images/content-modal-cancel.png";
import { useHistory } from "react-router-dom";
function ContentPopup() {
  const [email, setEmail] = useState("");
  const containerRef = useRef(null);
  const history = useHistory();

  const handleClick = () => {
    history.push("/auth/signup");
  };
  return (
    <>
      <div ref={containerRef} className='image_bg'>
      <div className="image_overlay"></div>
        <img
          className=''
          src={bg}
          alt='background'
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: 999,
          }}
          className=' d-flex align-items-center justify-content-center'
        >
          <div className='content-desktop-popup-contentContainer'>
            <div className='content-desktop-popup-headingContainer'>
              <img
                className='content-desktop-popup-logo'
                src={logo}
                alt='logo'
              />
              <span className='content-desktop-popup-headingName'>
                Explore Every{" "}
                <span className='content-desktop-popup-blueHeadingName'>
                  Nuance
                </span>{" "}
              </span>
            </div>
            <div className='content-desktop-popup-inputContainer'>
              <input
                className='content-desktop-popup-input'
                type='text'
                placeholder='Type Your Email...'
                placeholderTextColor='white'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={handleClick}
                className='content-desktop-popup-button'
              >
                Sign Up
              </button>
            </div>
            <div className='content-desktop-popup-linkTextContainer'>
              <span className='content-desktop-popup-linkText'>
                Sign up to use GZI
              </span>
              <FaChevronRight
                className='content-desktop-popup-arrowIcon'
                style={{
                  color: "white",
                  fontSize: "1rem",
                  marginLeft: "0.5rem",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentPopup;