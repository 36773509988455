







import React from "react";
import SignInForm from '../../components/signin/SignInForm'


const SignIn = ({onAuth}) => {
  return (
    
      <SignInForm onAuth={onAuth}/>
  
  );
};

export default SignIn;







// import React from "react";
// import SignInForm from '../../components/signin/SignInForm'
// import FormLayout from '../../components/FormLayout'

// const SignIn = ({onAuth}) => {
//   return (
//     <FormLayout>
//       <SignInForm onAuth={onAuth}/>
//     </ FormLayout>
//   );
// };

// export default SignIn;
