import React, {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ReactTabulator} from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import CarouselValueModal from "./CarouselValueModal";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import dataFormat from '../data/json/crypto_format.json';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {redirectToCheckout} from "../util/stripe";
import { useAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";
import { makeStyles } from "@material-ui/core";
import "../assets/css/gzi.css";

const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        background: 'inherit',
        border: '1px solid gray',
      }
    }
}));

function TabulatorTable(props) {
    const auth = useAuth();
    const router = useRouter();
    const [data, setData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [open, setOpen] = useState(false);
    const [render,setRender] = useState()
    const tableRef = useRef(null);
    const classes = useStyles();
    
    const columns = [
        {title: "DES", field: "des"},
        {title: "UNDERLYING", field: "underlying"},
        {title: "SECTYPE", field: "sectype"},
        {title: "EXCHANGE", field: "exchange"},
        {rowHandle: true, formatter: "handle", headerSort: false, frozen: true, width: 30, minWidth: 30},
    ];

    const options = {
        height: "auto",
        movableRows: true,
        downloadDataFormatter: (data) => data,
        downloadReady: (fileContents, blob) => blob,
    };

    const renderTabularData = async (propsData, interactiveBroker) => {

        let dataArray = [];
        if (propsData.dataValue !== null) {
            await dataFormat.map((item) => {
                if (propsData.dataValue[item].label === "Financial Instrument") {
                    console.log(propsData.dataValue,"ddf")
                    interactiveBroker.map(hash=>{
                        if(hash["UNDERLYING"] === propsData.dataValue[item].value){
                            dataArray.push({
                                des: hash['DES'],
                                underlying:hash['UNDERLYING'],
                                sectype: hash['SECTYPE'],
                                exchange: hash['EXCHANGE']
                            })
                        }
                    })
                }
            })
        }
        return dataArray;
    }

    const rowClick = (e,row) => {
      setModalData(props.data.filter(x=>x.financial_instrument.value === row._row.data.underlying))
      setOpen(true)
    };

    const runPythonScript = () => {
        // Here the button 'Backtest' will hit the python api and revert back the result for an single stock in the form of graph or chart.

        // for eg.
        // fetch('/hello').then(res => res.json()).then(data => {
        //     console.log(data.result, 'result')
        // });
    }

    useEffect(() => {
        if(props){
            setRender(props.render)
        }
        renderTabularData(props, props.interactiveBroker).then(dataArray => {
            setData(prev=>{
               return render?
               [...dataArray].filter((v,i,a)=>a.findIndex(t=>(t.underlying=== v.underlying))===i):
               [...prev,...dataArray].filter((v,i,a)=>a.findIndex(t=>(t.underlying=== v.underlying))===i)
            })
            setRender(false)
        })
    }, [props])

    return (
        !props.show ? null :
            <Container className={classes.root}>
                <Grid justify="center" spacing={4}>
                    <Card className="tabular-table p-3  h-100">
                        <ReactTabulator
                            ref={tableRef}
                            columns={columns}
                            data={props.page? []:data}
                            options={options}
                            rowClick={!props.page?rowClick:null}
                            data-custom-attr="test-custom-attribute"
                            className="custom-css-class tabular-table-content"
                        />
                    </Card>
                    <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={2}
                        >
                            <Typography variant="h5">
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            > 
                            <Box style={{padding: "6px 20px 6px 21px",
                                 borderRadius: "24px", background:"orangered"}} margin={1}>
                                    <div
                                       style={{color:"white"}}
                                        onClick={async() => {
                                            !auth.user? router.push(`/auth/signup?next=/purchase/${"pro"}`)
                                            :await redirectToCheckout("pro")
                                        }}
                                    >
                                        Backtest
                                    </div>
                                </Box>
                                
                                <Box style={{border: "1px solid black",padding: "6px 20px 6px 21px",
                                 borderRadius: "24px", color:'black'}} margin={1}>
                                    <div
                                        onClick={async() => {
                                           !auth.user?
                                           router.push(`/auth/signup?next=/purchase/"business"}`):
                                           !!auth.user && !(auth.user.planId!="business"&& auth.user.planIsActive)?
                                            await redirectToCheckout("business",tableRef):
                                            tableRef.current.table.download("csv", "data.csv")
                                        }}
                                    >
                                        Download
                                    </div>
                                </Box>
                            </Box>
                            </Box>
                    {open?
                        <CarouselValueModal show={open} data={modalData[0]} onHide={() => { setOpen(false) }} />
                        :null
                    }
                </Grid>
            </Container>
    );
}

const mapStateToProps = (state) => {
    return ({
        dataValue: state.ReducerValue.jsonData
    })
}
export default connect(mapStateToProps)(TabulatorTable)