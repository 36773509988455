import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './common.css';
import "../src/assets/css/bootstrap_v4.css";
// import "../src/assets/css/slick.css";
// import "../src/assets/css/slick-theme.css";
import "../src/assets/css/stsheet.css";
import { Provider } from 'react-redux'
import Store from './Redux/Store'
import { persistStore, autoRehydrate } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react';
// import './assets/css/loader.css'
import './assets/css/bootstrap.min.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/nice-select.css'
import './assets/css/default-edit.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

const persistor = persistStore(Store);

ReactDom.render(
<Provider store={Store}>
    <PersistGate persistor={persistor}>
        <App />
    </PersistGate>
  </Provider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
