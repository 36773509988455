const PricingHeaderSection = () => {
    return (
       
        <div class="table-row table-heading-row">
            <div class="column clumn1 detail-column">
                <div class="price-detail-head">
                    <h4>MAIN FEATURE</h4>
                </div>
            </div>
            <div class="column clumn2 card-column">
                <div class="price-card-head">
                    <h3>Basic</h3>
                    <h4>Play the Market</h4>
                    <h2><span>$</span> 99</h2>
                    <p>per month</p>
                </div>
            </div>
            <div class="column clumn3 card-column heightlight">
                <div class="price-card-head">
                    <h3>Business</h3>
                    <h4>Conquer Fintech</h4>
                    <h2><span>$</span> 999</h2>
                    <p>per month</p>
                </div>
            </div>
            <div class="column clumn4 card-column">
                <div class="price-card-head">
                    <h3>Pro</h3>
                    <h4>God Mode</h4>
                    <h2><span>$</span> 9,999</h2>
                    <p>per month</p>
                </div>
            </div>
        </div>
        
    );
}

export default PricingHeaderSection;