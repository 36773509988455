import Footer from "../components/Footer";
import Header from "../components/Header";
import "../assets/css/gzi.css";
import "../components/sunbursts/App.css"
import { Dropdown, Modal, Button } from "react-bootstrap";
import ContentPopup from "../components/ContentPopup";
import content1 from "../assets/images/connection1.png";
import content2 from "../assets/images/connection2.png";
import content3 from "../assets/images/connection3.png";
import content4 from "../assets/images/Info2.svg";
import content5 from "../assets/images/minus-signn.png";
import content6 from "../assets/images/pricing_check.png";
import content7 from "../assets/images/plus.svg";
import content8 from "../assets/images/info.svg";
import content9 from "../assets/images/remove.svg";
import content10 from "../assets/images/circle2.svg";
import content11 from "../assets/images/circle3.svg";
import content12 from "../assets/images/circle1.png";
import content13 from "../assets/images/circle4.svg";
import content14 from "../assets/images/circle5.svg";
import content15 from "../assets/images/circle6.svg";
import content16 from "../assets/images/circle7.svg";
import contentchart from "../assets/images/content-char.svg";
import popupvid from "../assets/popups/popup_vid.mp4";
import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import dataFormat from "../data/json/crypto_format.json";
import Slider from "react-slick";
import { connect } from "react-redux";
import { useAuth } from "./../util/auth.js";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";

import Chart from "../components/Chart";
import trading_currency_banner from "../assets/images/trading_currency_banner.jpg";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import HowWorkSection from "../components/home/HowWorkSection";
import content_ban from "../assets/images/content_ban.jpg";
import cnt_dtl_pic1 from "../assets/images/cnt_dtl_pic1.png";
import cnt_dtl_pic2 from "../assets/images/cnt_dtl_pic2.png";
import cnt_dtl_pic3 from "../assets/images/cnt_dtl_pic3.png";
import cnt_dtl_pic4 from "../assets/images/cnt_dtl_pic4.png";
import cnt_dtl_pic5 from "../assets/images/cnt_dtl_pic5.png";
import cnt_dtl_pic6 from "../assets/images/cnt_dtl_pic6.png";
import cnt_dtl_pic7 from "../assets/images/cnt_dtl_pic7.png";
import csvjson_z1 from "../data/json/csvjson_z1.json";
import csvjson_z2 from "../data/json/csvjson_z2.json";
import csvjson_z3 from "../data/json/csvjson_z3.json";
import csvjson_z22 from "../data/json/csvjson_z2.2.json";
import biotech from "../data/json/biotech.json";
import biotech_highrisk from "../data/json/biotech_highrisk.json";
import crypto from "../data/json/crypto.json";
import real_estate from "../data/json/real_estate.json";
import Z1_InteractiveBrokers from "../data/json/Z1_InteractiveBrokers.json";
import Z2_2_InteractiveBrokers from "../data/json/Z2.2_2_InteractiveBrokers.json";
import Z2_InteractiveBrokers from "../data/json/Z2_InteractiveBrokers.json";
import Z3_InteractiveBrokers from "../data/json/Z3_InteractiveBrokers.json";
import ContentCardJsonData1 from "../components/ContentCardJsonData1";
import Biotech from "../data/json/biotech.json";
import Biotech_Highrisk from "../data/json/biotech_highrisk.json";
import Real_Estate from "../data/json/real_estate.json";
import Crypto from "../data/json/crypto.json";
import {useStore} from '../zustand/zustandstore'

// import "../assets/css/gzi.css";

const items = [
  {
    image: cnt_dtl_pic1,
    title: "North America",
    body: "Stock Exchanges Include: New York Stock Exchange(NYSE), Nasdaq Technology (NASDAQ), Mexico (MEXI),Canada (TSE), Canadian Venture (TSXV)",
    url: "/post/north-america",
    data: csvjson_z1,
    interactiveBroker: Z1_InteractiveBrokers,
    formatData: true,
    filename: "csvjson_z1.json",
  },
  {
    image: cnt_dtl_pic2,
    title: "Western Europe",
    body: "Stock Exchanges Include: London (LSE), France (SBF),Portugal (BVL), Italy (BMV), Vienna (VSE), SwissElectronic Bourse (EBS), SIX Swiss (VIRTX)",
    url: "/post/western_europe",
    data: csvjson_z2,
    interactiveBroker: Z2_InteractiveBrokers,
    formatData: true,
    filename: "csvjson_z2.json",
  },
  {
    image: cnt_dtl_pic3,
    title: "Scandanvaia and Eastern Europe",
    body: "Stock Exchanges Include: Sweeden (SFB), Norway (OSE),Russia (MOEX), Ukraine (WSE)",
    url: "/post/eastern_europe",
    data: csvjson_z22,
    interactiveBroker: Z2_2_InteractiveBrokers,
    formatData: true,
    filename: "csvjson_z2.2.json",
  },
  {
    image: cnt_dtl_pic4,
    title: "Asia, Oceania, and the Middle East",
    body: "Stock Exchanges Include: Isreal (TASE), Shanghai(SSE), Shenzhen (SZE), Hong Kong (SEHK), Singapore(SGX), Japan (TSEJ), Australia (ASX)",
    url: "/post/asia",
    data: csvjson_z3,
    interactiveBroker: Z3_InteractiveBrokers,
    formatData: true,
    filename: "csvjson_z3.json",
  },
  {
    image: cnt_dtl_pic5,
    title: "Biotechnology",
    body: "Our specialty. Applies worldwide.",
    url: "/post/biotech",
    data: biotech,
    interactiveBroker: Biotech,
    formatData: true,
    filename: "Biotech.json",
  },
  {
    image: cnt_dtl_pic6,
    title: "Biotech High Risk",
    body: "For those who want to avoid direct exposure. Applies worldwide.",
    url: "/post/biotech_highrisk",
    data: biotech_highrisk,
    interactiveBroker: Biotech_Highrisk,
    formatData: true,
    filename: "Biotech_Highrisk.json",
  },
  {
    image: cnt_dtl_pic6,
    title: "Cryptocurrency",
    body: "For those who want to avoid direct exposure. Applies worldwide.",
    url: "/post/crypto",
    data: crypto,
    interactiveBroker: Crypto,
    formatData: true,
    filename: "Crypto.json",
  },
  {
    image: cnt_dtl_pic6,
    title: "Real Estate",
    body: "Feed your head and your wallet. Applies worldwide.",
    url: "/post/real_estate",
    data: real_estate,
    interactiveBroker: Real_Estate,
    formatData: true,
    filename: "Real_Estate.json",
  },
];

const MobileFlows = () => {
  return (
    <svg
      // width="380.00000000000006"
      // height="1500"
      viewBox="0 0 400 1400"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 1952 1080"
      className="graph-svg"
    >
      <g>
        <title>Layer 1</title>
        <g id="Layer_3">
          */
          <path className="hack" opacity="0.5" fill="#086D9A" id="svg_13" />
          <g id="svg_14">
            <text
              className="marketPath exact cty eby graph-txt-color"
              transform="rotate(90 304.237 91.7756) matrix(1 0 0 1 25.5007 500.902)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_1"
              y="-401.86643"
              x="236.91526"
            >
              Scientific Data
            </text>
            <text
              className="marketPath exact cty eby graph-txt-color"
              transform="rotate(90 215.857 91.09) matrix(1 0 0 1 25.031 599.392)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_2"
              y="-499.85096"
              x="151.91978"
            >
              Financial Data
            </text>
            <text
              className="marketPath exact cty eby graph-txt-color"
              transform="rotate(90 140.518 85.2613) matrix(1 0 0 1 -1.8181 690.952)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_3"
              y="-594.61081"
              x="89.55971"
            >
              Technologic Data
            </text>
            <rect
              transform="rotate(90 294.738 239.065)"
              className="marketPath exact cty eby"
              x="280.9881"
              y="205.86474"
              opacity="0.75"
              fill="#3c96d2"
              width="27.5"
              height="66.4"
              id="svg_4"
            />
            <rect
              transform="rotate(90 223.038 239.165)"
              className="hack"
              x="209.38811"
              y="205.9647"
              opacity="0.75"
              fill="#086d9a"
              width="27.3"
              height="66.4"
              id="svg_5"
            />
            <rect
              transform="rotate(90 151.238 239.115)"
              className="marketPath exact cty eby"
              x="137.63811"
              y="205.91472"
              opacity="0.75"
              fill="#21366B"
              width="27.2"
              height="66.4"
              id="svg_6"
            />
            <path
              transform="rotate(90 266.103 357.665)"
              className="cow hack"
              opacity="0.5"
              fill="#3C96D2"
              d="m161.15269,312.42928c77.2,0.1 169,58.1 209.9,56.5l0,32.39964c-56.80021,13.20038 -135.4,-60.8996 -209.9,-68.2996l0,-20.60004z"
              id="svg_7"
            />
            <path
              transform="rotate(90 133.564 358.165)"
              className="cow hack"
              opacity="0.5"
              fill="#21366B"
              d="m28.11362,342.49021c77.3,6.6 134.49977,10.4523 210.89998,-1.04768l-0.29575,31.42514c-44.49996,6.10021 -141.30423,-2.47746 -210.60423,-14.27746l0,-8.1l0,-8z"
              id="svg_12"
            />
            <text
              className="fact hack graph-txt-color"
              transform="rotate(90 294.646 581.074) matrix(1 0 0 1 463.54 525.5)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_18"
              y="62.93767"
              x="-227.08479"
            >
              Artificial Intelligence
            </text>
            <text
              className="cty hack graph-txt-color"
              transform="rotate(90 220.031 585.181) matrix(1 0 0 1 510.735 665.478)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_20"
              y="-72.71967"
              x="-331.38282"
            >
              Data Analytics
            </text>
            <text
              className="hack graph-txt-color"
              transform="rotate(90 139.676 590.517) matrix(1 0 0 1 505.659 735.967)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_21"
              y="-137.87423"
              x="-413.08858"
            >
              Data Streamings
            </text>
            <rect
              transform="rotate(90 294.841 782.165)"
              className="marketPath ifttt bbye hack"
              x="281.19073"
              y="748.96476"
              opacity="0.75"
              fill="#3C96D2"
              width="27.3"
              height="66.4"
              id="svg_22"
              stroke="null"
            />
            <rect
              transform="rotate(90 222.841 782.165)"
              className="ifttt hack"
              x="209.19073"
              y="748.96476"
              opacity="0.75"
              fill="#21366b"
              width="27.3"
              height="66.4"
              id="svg_24"
            />
            <rect
              transform="rotate(90 147.341 782.165)"
              className="cty hack"
              x="133.69073"
              y="745.46476"
              opacity="0.75"
              fill="#3c96d2"
              width="27.3"
              height="73.4"
              id="svg_25"
            />
            <path
              transform="rotate(90 192.719 901.37)"
              className="fact"
              opacity="0.35"
              fill="#3C96D2"
              d="m85.8692,796.14593c108.2,-6.4 158.90004,179.09994 213.70004,181.19994l0,29.39962c-108.50064,-0.39981 -172.20004,-175.19958 -213.70004,-174.39958l0,-36.19998z"
              id="svg_43"
            />
            <polygon
              transform="rotate(90 156.288 1022.72)"
              className="fact"
              opacity="0.75"
              fill="#21366b"
              points="167.18814086914062,989.5646896362305 165.88821411132812,989.5646896362305 139.7880859375,989.5646896362305 139.7880859375,1055.8647384643555 165.88821411132812,1055.8647384643555 167.18814086914062,1055.8647384643555 172.7880859375,1022.6647872924805 "
              id="svg_47"
            />
            <polygon
              transform="rotate(90 84.5381 1022.72)"
              className="fact"
              opacity="0.75"
              fill="#3c96d2"
              points="95.4381332397461,989.514762878418 94.1382064819336,989.514762878418 94.1382064819336,989.514762878418 68.03810119628906,989.514762878418 68.03810119628906,1055.9147872924805 94.1382064819336,1055.9147872924805 95.4381332397461,1055.9147872924805 101.0381088256836,1022.7148361206055 "
              id="svg_48"
            />
            <text
              x="-1380.19215"
              y="606.16262"
              className="eby pg find cnet cow hack graph-txt-color"
              transform="rotate(90 306.439 1123.53) matrix(1 0 0 1 1629.85 525.5)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_49"
            >
              Portfolio Structuring
            </text>
            <text
              x="-1443.57881"
              y="529.37533"
              className="cty graph-txt-color"
              transform="rotate(90 238.061 1117.4) matrix(1 0 0 1 1629.85 595.587)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_50"
            >
              Portfolio Analytics
            </text>
            <text
              x="-1521.46724"
              y="468.28769"
              className="fact graph-txt-color"
              transform="rotate(90 166.868 1125.23) matrix(1 0 0 1 1629.85 665.075)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_51"
            >
              Financial Backtesting
            </text>
            <text
              x="-1612.50679"
              y="408.3453"
              className="ifttt graph-txt-color"
              transform="rotate(90 90.1329 1140.37) matrix(1 0 0 1 1629.85 740.566)"
              fontFamily="'Telegraf'"
              fontSize="17.5701"
              id="svg_52"
            >
              Importable to Your Device
            </text>
            <path
              transform="rotate(90 282.908 357.313)"
              className="cow hack"
              opacity="0.5"
              fill="#086d9a"
              d="m177.31034,383.77003c77.2,0.1 170,-68.49155 210.9,-70.09155l0.29577,34.51234c-44.80008,-6.79983 -136.69577,59.57921 -211.19577,52.17921l0,-16.6z"
              id="svg_53"
              fill-opacity="0.75"
            />
            <path
              transform="rotate(90 214.578 357.517)"
              className="cow hack"
              opacity="0.5"
              fill="#086d9a"
              d="m108.77616,331.84147c77.2,0.1 170.70423,19.84683 211.60423,18.24683l-0.40846,33.10388c-36.8,-22.8 -136.69577,-27.35071 -211.19577,-34.75071l0,-16.6z"
              id="svg_54"
              fill-opacity="0.75"
            />
            <path
              transform="rotate(90 187.587 357.665)"
              className="cow hack"
              opacity="0.5"
              fill="#086d9a"
              d="m81.63743,321.89993c77.2,0.1 170,40.07182 210.9,38.47182l1,32.69543c-120.80089,5.2003 -137.4,-47.16725 -211.9,-54.56725l0,-16.6z"
              id="svg_55"
              fill-opacity="0.75"
            />
            <path
              transform="rotate(90 140.727 357.665)"
              className="cow hack"
              opacity="0.5"
              fill="#086d9a"
              d="m35.48088,291.94056c77.2,0.1 169.29577,97.00142 210.19577,95.40142l0.29577,34.39967c-68.80034,17.20042 -135.99154,-105.80109 -210.49154,-113.20109l0,-16.6z"
              id="svg_56"
              fill-opacity="0.75"
            />
            <path
              transform="rotate(90 294.254 358.017)"
              className="cow hack"
              opacity="0.5"
              fill="#3C96D2"
              d="m188.95219,324.73582c77.2,0.1 169,35.17076 209.9,33.57076l0.70423,32.99121c-36.8,-22.8 -136.10423,-41.15352 -210.60423,-48.55352l0,-18.00845z"
              id="svg_57"
            />
            <path
              transform="rotate(90 88.3429 357.665)"
              className="cow hack"
              opacity="0.5"
              fill="#21366B"
              d="m-17.60708,312.55824c77.3,6.6 156.49998,60.3114 211.89998,55.8114l-1.11321,33.43906c-94.50049,10.10025 -141.48677,-62.35046 -210.78677,-74.15046l1,-8.1l-1,-7z"
              id="svg_58"
            />
            <path
              transform="rotate(90 155.539 901.36)"
              className="pg"
              opacity="0.5"
              fill="#3c96d2"
              d="m48.5886,873.97731c55.3,0.3 103.50002,110.38774 213.90002,92.38774l-1,38.00008c-85.8,-1.6 -162.30002,-65.00072 -211.90002,-59.60072l-1,-146.4l0,75.6129z"
              id="svg_62"
            />
            <path
              transform="rotate(90 279.208 900.87)"
              className="fact"
              opacity="0.35"
              fill="#3C96D2"
              d="m172.85821,852.03492c61,-7.4 159.90002,66.29994 212.70002,65.59994l0,32.39989c-91.3003,4.30019 -152.70002,-68.99981 -212.70002,-64.79981c0,-10.4 0,-22.80002 0,-33.20002z"
              id="svg_63"
            />
            <path
              transform="rotate(90 170.039 900.36)"
              stroke="null"
              className="pg"
              opacity="0.5"
              fill="#21366B"
              d="m86.0886,862.47738c-0.7,1.3 79.50002,37.38759 189.90002,19.38759l0,65.00017c-85.8,-1.6 -162.30002,-71.0007 -211.90002,-65.6007l0,-27.40004l22,8.61298z"
              id="svg_64"
            />
            <path
              transform="rotate(90 215.539 900.36)"
              className="pg"
              opacity="0.5"
              fill="#21366B"
              d="m321.48862,885.36529l0,32.99983c-36.8,-3.6 -121.30002,7.99932 -210.90002,-14.60068l-1,-21.40002l211.90002,3.00087z"
              id="svg_9"
            />
            <path
              transform="rotate(90 281.965 900.86)"
              className="cow hack"
              opacity="0.5"
              fill="#21366B"
              d="m176.51536,927.4912c82.2,-24.90001 170.00002,-73.89928 210.90002,-77.4993l0,65.4003c-106.79985,-15.80087 -136.40002,43.09894 -210.90002,35.69894l0,-23.59994z"
              id="svg_11"
            />
            <polygon
              transform="rotate(90 213.087 1022.72)"
              className="cty"
              opacity="0.75"
              fill="#21366b"
              points="229.58743286132812,1006.164665222168 223.98745727539062,1006.164665222168 223.98745727539062,1006.164665222168 196.58743286132812,1006.164665222168 196.58743286132812,1039.2648849487305 223.98745727539062,1039.2648849487305 "
              id="svg_8"
            />
            <polygon
              transform="rotate(90 246.288 1022.72)"
              className="exact"
              opacity="0.75"
              fill="#3C96D2"
              points="262.7875061035156,1039.364860534668 257.1875305175781,1006.1647872924805 257.1875305175781,1006.064811706543 229.78750610351562,1006.064811706543 229.78750610351562,1039.364860534668 257.1875305175781,1039.364860534668 257.1875305175781,1039.364860534668 "
              id="svg_10"
            />
            <polygon
              transform="rotate(90 300.289 1022.72)"
              className="fact"
              opacity="0.75"
              fill="#21366b"
              points="311.1885070800781,989.5644454956055 309.8885803222656,989.5644454956055 283.7884826660156,989.5644454956055 283.7884826660156,1055.8652267456055 309.8885803222656,1055.8652267456055 311.1885070800781,1055.8652267456055 316.7884826660156,1022.6650314331055 "
              id="svg_19"
            />
            <polygon
              transform="rotate(90 61.0859 478.709)"
              className="cty"
              opacity="0.75"
              fill="#21366b"
              points="77.58589172363281,462.15892791748047 71.98594665527344,462.15892791748047 71.98594665527344,462.15892791748047 44.58589172363281,462.15892791748047 44.58589172363281,495.25902557373047 71.98594665527344,495.25902557373047 "
              id="svg_23"
            />
            <polygon
              transform="rotate(90 94.2859 478.709)"
              className="exact"
              opacity="0.75"
              fill="#086d9a"
              points="110.78589630126953,495.35887908935547 105.1859359741211,462.15892791748047 105.1859359741211,462.05895233154297 77.78590393066406,462.05895233154297 77.78590393066406,495.35887908935547 105.1859359741211,495.35887908935547 105.1859359741211,495.35887908935547 "
              id="svg_26"
            />
            <polygon
              transform="rotate(90 135.086 478.709)"
              className="cty"
              opacity="0.75"
              fill="#21366b"
              points="151.58590698242188,462.15892791748047 145.98593139648438,462.15892791748047 145.98593139648438,462.15892791748047 118.58589935302734,462.15892791748047 118.58589935302734,495.25902557373047 145.98593139648438,495.25902557373047 "
              id="svg_27"
            />
            <polygon
              transform="rotate(90 168.286 478.709)"
              className="exact"
              opacity="0.75"
              fill="#086d9a"
              points="184.785888671875,495.35887908935547 179.18594360351562,462.15892791748047 179.18594360351562,462.05895233154297 151.785888671875,462.05895233154297 151.785888671875,495.35887908935547 179.18594360351562,495.35887908935547 179.18594360351562,495.35887908935547 "
              id="svg_28"
            />
            <polygon
              transform="rotate(90 205.086 478.709)"
              className="cty"
              opacity="0.75"
              fill="#086d9a"
              points="221.58590698242188,462.15892791748047 215.98593139648438,462.15892791748047 215.98593139648438,462.15892791748047 188.58590698242188,462.15892791748047 188.58590698242188,495.25902557373047 215.98593139648438,495.25902557373047 "
              id="svg_29"
            />
            <polygon
              transform="rotate(90 238.286 478.709)"
              stroke="null"
              className="exact"
              opacity="0.75"
              fill="#3c96d2"
              points="254.78590393066406,495.35887908935547 249.18594360351562,462.15892791748047 249.18594360351562,462.05895233154297 221.78590393066406,462.05895233154297 221.78590393066406,495.35887908935547 249.18594360351562,495.35887908935547 249.18594360351562,495.35887908935547 "
              id="svg_30"
            />
            <polygon
              transform="rotate(90 277.086 478.709)"
              className="cty"
              opacity="0.75"
              fill="#3c96d2"
              points="293.5859680175781,462.15892791748047 287.9860534667969,462.15892791748047 287.9860534667969,462.15892791748047 260.5859680175781,462.15892791748047 260.5859680175781,495.25902557373047 287.9860534667969,495.25902557373047 "
              id="svg_31"
            />
            <polygon
              transform="rotate(90 310.286 478.709)"
              className="exact"
              opacity="0.75"
              fill="#086d9a"
              points="326.7862854003906,495.35924530029297 321.1863708496094,462.1587448120117 321.1863708496094,462.0587692260742 293.7862854003906,462.0587692260742 293.7862854003906,495.35924530029297 321.1863708496094,495.35924530029297 321.1863708496094,495.35924530029297 "
              id="svg_32"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const DesktopFlows = () => {
  return (
    <svg
      width="1320"
      height="440"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 1952 1080"
    >
      <g>
        <title>Layer 1</title>
        <g id="Layer_3">
          <text
            className="marketPath exact cty eby graph-txt-color"
            transform="matrix(1 0 0 1 25.5007 500.902)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_1"
            y="-398.02316"
            x="-1.00724"
          >
            Scientific Data
          </text>
          <text
            className="marketPath exact cty eby graph-txt-color"
            transform="matrix(1 0 0 1 25.031 599.392)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_2"
            y="-412.02316"
            x="-1.00724"
          >
            Financial Data
          </text>
          <text
            className="marketPath exact cty eby graph-txt-color"
            transform="matrix(1 0 0 1 -1.8181 690.952)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_3"
            y="-422.02316"
            x="14.99277"
          >
            Technologic Data
          </text>
          <rect
            className="marketPath exact cty eby"
            x="196.09276"
            y="73.07684"
            opacity="0.75"
            fill="#3c96d2"
            width="27.5"
            height="66.4"
            id="svg_4"
          />
          <rect
            className="hack"
            x="196.29276"
            y="144.77684"
            opacity="0.75"
            fill="#086d9a"
            width="27.3"
            height="66.4"
            id="svg_5"
          />
          <rect
            className="marketPath exact cty eby"
            x="196.29276"
            y="216.57684"
            opacity="0.75"
            fill="#21366B"
            width="27.2"
            height="66.4"
            id="svg_6"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#3C96D2"
            d="m223.49276,89.67684c77.2,0.1 169,58.1 209.9,56.5l0,32.39964c-56.80021,13.20038 -135.4,-60.8996 -209.9,-68.2996l0,-20.60004z"
            id="svg_7"
          />
          */
          <path
            className="cow hack"
            opacity="0.5"
            fill="#21366B"
            d="m223.49276,251.77684c77.3,6.6 134.49977,10.4523 210.89998,-1.04768l-0.29575,31.42514c-44.49996,6.10021 -141.30423,-2.47746 -210.60423,-14.27746l0,-8.1l0,-8z"
            id="svg_12"
          />
          <path className="hack" opacity="0.5" fill="#086D9A" id="svg_13" />
          <text
            className="fact hack graph-txt-color"
            transform="matrix(1 0 0 1 463.54 525.5)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_18"
            y="-414.0232"
            x="32.99277"
          >
            Artificial Intelligence
          </text>
          <text
            className="cty hack graph-txt-color"
            transform="matrix(1 0 0 1 510.735 665.478)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_20"
            y="-476.02386"
            x="6.99277"
          >
            Data Analytics
          </text>
          <text
            className="hack graph-txt-color"
            transform="matrix(1 0 0 1 505.659 735.967)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_21"
            y="-466.02382"
            x="10.99277"
          >
            Data Streamings
          </text>
          <rect
            className="marketPath ifttt bbye hack"
            x="739.29277"
            y="72.97417"
            opacity="0.75"
            fill="#3C96D2"
            width="27.3"
            height="66.4"
            id="svg_22"
            stroke="null"
          />
          <rect
            className="ifttt hack"
            x="739.29277"
            y="144.97417"
            opacity="0.75"
            fill="#21366b"
            width="27.3"
            height="66.4"
            id="svg_24"
          />
          <rect
            className="cty hack"
            x="739.29277"
            y="216.97417"
            opacity="0.75"
            fill="#3c96d2"
            width="27.3"
            height="73.4"
            id="svg_25"
          />
          <path
            className="fact"
            opacity="0.35"
            fill="#3C96D2"
            d="m765.29277,103.07684c108.2,-6.4 158.90004,179.09994 213.70004,181.19994l0,29.39962c-108.50064,-0.39981 -172.20004,-175.19958 -213.70004,-174.39958l0,-36.19998z"
            id="svg_43"
          />
          <polygon
            className="fact"
            opacity="0.75"
            fill="#21366b"
            points="1004.392822265625,211.57682037353516 1003.0928955078125,211.57682037353516 976.9927978515625,211.57682037353516 976.9927978515625,277.8768844604492 1003.0928955078125,277.8768844604492 1004.392822265625,277.8768844604492 1009.9927978515625,244.67687225341797 "
            id="svg_47"
          />
          <polygon
            className="fact"
            opacity="0.75"
            fill="#3c96d2"
            points="1004.392822265625,283.27684783935547 1003.0928955078125,283.27684783935547 1003.0928955078125,283.27684783935547 976.9927978515625,283.27684783935547 976.9927978515625,349.67687225341797 1003.0928955078125,349.67687225341797 1004.392822265625,349.67687225341797 1009.9927978515625,316.4768600463867 "
            id="svg_48"
          />
          <text
            x="-591.00723"
            y="-412.02316"
            className="eby pg find cnet cow hack graph-txt-color"
            transform="matrix(1 0 0 1 1629.85 525.5)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_49"
          >
            Portfolio Structuring
          </text>
          <text
            x="-591.00723"
            y="-412.02316"
            className="cty graph-txt-color"
            transform="matrix(1 0 0 1 1629.85 595.587)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_50"
          >
            Portfolio Analytics
          </text>
          <text
            x="-591.00723"
            y="-412.02316"
            className="fact graph-txt-color"
            transform="matrix(1 0 0 1 1629.85 665.075)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_51"
          >
            Financial Backtesting
          </text>
          <text
            x="-591.00723"
            y="-412.02316"
            className="ifttt graph-txt-color"
            transform="matrix(1 0 0 1 1629.85 740.566)"
            fontFamily="'Telegraf'"
            fontSize="17.5701"
            id="svg_52"
          >
            Importable to Your Device
          </text>
          <path
            className="cow hack"
            opacity="0.5"
            fill="#086d9a"
            d="m222.49276,144.56416c77.2,0.1 170,-68.49155 210.9,-70.09155l0.29577,34.51234c-44.80008,-6.79983 -136.69577,59.57921 -211.19577,52.17921l0,-16.6z"
            id="svg_53"
            fill-opacity="0.75"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#086d9a"
            d="m222.49276,160.76134c77.2,0.1 170.70423,19.84683 211.60423,18.24683l-0.40846,33.10388c-36.8,-22.8 -136.69577,-27.35071 -211.19577,-34.75071l0,-16.6z"
            id="svg_54"
            fill-opacity="0.75"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#086d9a"
            d="m222.49276,177.66275c77.2,0.1 170,40.07182 210.9,38.47182l1,32.69543c-120.80089,5.2003 -137.4,-47.16725 -211.9,-54.56725l0,-16.6z"
            id="svg_55"
            fill-opacity="0.75"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#086d9a"
            d="m223.19699,194.56416c77.2,0.1 169.29577,97.00142 210.19577,95.40142l0.29577,34.39967c-68.80034,17.20042 -135.99154,-105.80109 -210.49154,-113.20109l0,-16.6z"
            id="svg_56"
            fill-opacity="0.75"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#3C96D2"
            d="m223.49276,73.47966c77.2,0.1 169,35.17076 209.9,33.57076l0.70423,32.99121c-36.8,-22.8 -136.10423,-41.15352 -210.60423,-48.55352l0,-18.00845z"
            id="svg_57"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#21366B"
            d="m222.49276,267.56557c77.3,6.6 156.49998,60.3114 211.89998,55.8114l-1.11321,33.43906c-94.50049,10.10025 -141.48677,-62.35046 -210.78677,-74.15046l1,-8.1l-1,-7z"
            id="svg_58"
          />
          <path
            className="pg"
            opacity="0.5"
            fill="#3c96d2"
            d="m765.19277,218.08895c55.3,0.3 103.50002,110.38774 213.90002,92.38774l-1,38.00008c-85.8,-1.6 -162.30002,-65.00072 -211.90002,-59.60072l-1,-146.4l0,75.6129z"
            id="svg_62"
          />
          <path
            className="fact"
            opacity="0.35"
            fill="#3C96D2"
            d="m765.29277,72.97682c61,-7.4 159.90002,66.29994 212.70002,65.59994l0,32.39989c-91.3003,4.30019 -152.70002,-68.99981 -212.70002,-64.79981c0,-10.4 0,-22.80002 0,-33.20002z"
            id="svg_63"
          />
          <path
            stroke="null"
            className="pg"
            opacity="0.5"
            fill="#21366B"
            d="m787.19277,193.08902c-0.7,1.3 79.50002,37.38759 189.90002,19.38759l0,65.00017c-85.8,-1.6 -162.30002,-71.0007 -211.90002,-65.6007l0,-27.40004l22,8.61298z"
            id="svg_64"
          />
          <path
            className="pg"
            opacity="0.5"
            fill="#21366B"
            d="m977.09279,170.47693l0,32.99983c-36.8,-3.6 -121.30002,7.99932 -210.90002,-14.60068l-1,-21.40002l211.90002,3.00087z"
            id="svg_9"
          />
          <path
            className="cow hack"
            opacity="0.5"
            fill="#21366B"
            d="m766.19277,145.67608c82.2,-24.90001 170.00002,-73.89928 210.90002,-77.4993l0,65.4003c-106.79985,-15.80087 -136.40002,43.09894 -210.90002,35.69894l0,-23.59994z"
            id="svg_11"
          />
          <polygon
            className="cty"
            opacity="0.75"
            fill="#21366b"
            points="1009.9927978515625,171.37749481201172 1004.392822265625,171.37749481201172 1004.392822265625,171.37749481201172 976.9927978515625,171.37749481201172 976.9927978515625,204.47759246826172 1004.392822265625,204.47759246826172 "
            id="svg_8"
          />
          <polygon
            className="exact"
            opacity="0.75"
            fill="#3C96D2"
            points="1009.9927978515625,171.37749481201172 1004.392822265625,138.17748260498047 1004.392822265625,138.07750701904297 976.9927978515625,138.07750701904297 976.9927978515625,171.37749481201172 1004.392822265625,171.37749481201172 1004.392822265625,171.37749481201172 "
            id="svg_10"
          />
          <polygon
            className="fact"
            opacity="0.75"
            fill="#21366b"
            points="1004.392822265625,67.57618713378906 1003.0928955078125,67.57618713378906 976.9927978515625,67.57618713378906 976.9927978515625,133.87688446044922 1003.0928955078125,133.87688446044922 1004.392822265625,133.87688446044922 1009.9927978515625,100.67675018310547 "
            id="svg_19"
          />
          <polygon
            className="cty"
            opacity="0.75"
            fill="#21366b"
            points="465.9869689941406,323.37902069091797 460.38702392578125,323.37902069091797 460.38702392578125,323.37902069091797 432.9869689941406,323.37902069091797 432.9869689941406,356.47911834716797 460.38702392578125,356.47911834716797 "
            id="svg_23"
          />
          <polygon
            className="exact"
            opacity="0.75"
            fill="#086d9a"
            points="465.9869689941406,323.37902069091797 460.38702392578125,290.1790084838867 460.38702392578125,290.0790328979492 432.9869689941406,290.0790328979492 432.9869689941406,323.37902069091797 460.38702392578125,323.37902069091797 460.38702392578125,323.37902069091797 "
            id="svg_26"
          />
          <polygon
            className="cty"
            opacity="0.75"
            fill="#21366b"
            points="465.9869689941406,249.37902069091797 460.38702392578125,249.37902069091797 460.38702392578125,249.37902069091797 432.9869689941406,249.37902069091797 432.9869689941406,282.47911834716797 460.38702392578125,282.47911834716797 "
            id="svg_27"
          />
          <polygon
            className="exact"
            opacity="0.75"
            fill="#086d9a"
            points="465.9869689941406,249.37902069091797 460.38702392578125,216.17900848388672 460.38702392578125,216.07903289794922 432.9869689941406,216.07903289794922 432.9869689941406,249.37902069091797 460.38702392578125,249.37902069091797 460.38702392578125,249.37902069091797 "
            id="svg_28"
          />
          <polygon
            className="cty"
            opacity="0.75"
            fill="#086d9a"
            points="465.9869689941406,179.37902069091797 460.38702392578125,179.37902069091797 460.38702392578125,179.37902069091797 432.9869689941406,179.37902069091797 432.9869689941406,212.47911834716797 460.38702392578125,212.47911834716797 "
            id="svg_29"
          />
          <polygon
            stroke="null"
            className="exact"
            opacity="0.75"
            fill="#3c96d2"
            points="465.9869689941406,179.37902069091797 460.38702392578125,146.17900848388672 460.38702392578125,146.07903289794922 432.9869689941406,146.07903289794922 432.9869689941406,179.37902069091797 460.38702392578125,179.37902069091797 460.38702392578125,179.37902069091797 "
            id="svg_30"
          />
          <polygon
            className="cty"
            opacity="0.75"
            fill="#3c96d2"
            points="465.9869689941406,107.37899017333984 460.38702392578125,107.37899017333984 460.38702392578125,107.37899017333984 432.9869689941406,107.37899017333984 432.9869689941406,140.47911834716797 460.38702392578125,140.47911834716797 "
            id="svg_31"
          />
          <polygon
            className="exact"
            opacity="0.75"
            fill="#086d9a"
            points="465.9869689941406,107.37899017333984 460.38702392578125,74.17848205566406 460.38702392578125,74.07850646972656 432.9869689941406,74.07850646972656 432.9869689941406,107.37899017333984 460.38702392578125,107.37899017333984 460.38702392578125,107.37899017333984 "
            id="svg_32"
          />
        </g>
      </g>
    </svg>
  );
};

function ContentPage(props) {
  const [data, setData] = useState(csvjson_z1);
  const [interactiveBrokers, setInteractiveBrokers] = useState(
    Z1_InteractiveBrokers
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [render, setRender] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formatData, setFormatData] = useState(false);
  const auth = useAuth();
  const [selectedDropDown, setDropDown] = useState("North America");

  const handleClose = () => {
    setShowModal(false);
    if (!auth.user) {
      props.history.push(`/auth/signup`);
    }
  };

  /*useEffect(() => {
		setTimeout(() => {
			setShowModal(true)
		}, 1000 * 60)
		setShowModal(false);
		console.log(showModal)
	}, [])*/

  return (
    <>
      <head>
        <link rel="stylesheet" type="text/css" href="css/style.css" />

        <meta data-react-helmet="true" name="docsearch:version" content="2.0" />
        <meta
          data-react-helmet="true"
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover,user-scalable=0"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.datatables.net/v/bs4/dt-1.12.0/datatables.min.css"
        />
        <>
          <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js"></script>

          <script type="text/javascript" src="js/sticky_header.js"></script>

          <script
            type="text/javascript"
            src="https://cdn.datatables.net/v/bs4/dt-1.12.0/datatables.min.js"
          ></script>
          <script type="text/javascript" src="js/data_table.js"></script>
          <script type="text/javascript" src="js/range_slide.js"></script>
          <script type="text/javascript" src="js/graph-slider.js"></script>
          <script type="text/javascript" src="js/circle-timer.js"></script>
        </>
      </head>

      <Header {...props} pageName={"content"} bgImage={content_ban} />

      <section className="page_bg grid_section_component all_main_sec_padding">
        <section className="content_banner_sec">
          <div className="container">
            <div className="content_main_hd">
              <p>Ins and Outs of GZI</p>
              <h1 className="pricing_title">How it Works</h1>
            </div>
            <div className="listt_row1">
              <div className="col1_custom col_custom">
                <div className="list_col">
                  <h2>Data Synthesis</h2>
                </div>
              </div>
              <div className="col2_custom col_custom">
                <div className="list_col list_box_api">
                  <h2>Alternative Data</h2>
                </div>
              </div>
              <div className="col3_custom col_custom">
                <div className="list_col">
                  <h2>
                    Simplified for
                    <br /> Your Enjoyment
                  </h2>
                </div>
              </div>
              <div className="col4_custom col_custom">
                <div className="list_col">
                  <h2>The User Experience</h2>
                </div>
              </div>
            </div>
            {/* ..*/}
            <div className="d-none">
              <div className="listt_row1">
                <div className="col1_custom col_custom">
                  <div className="list_col">
                    <h2>Data Synthesis</h2>
                  </div>
                </div>
                <div className="col2_custom col_custom">
                  <div className="list_col list_box_api">
                    <h2>Alternative Data</h2>
                  </div>
                </div>
                <div className="col3_custom col_custom">
                  <div className="list_col">
                    <h2>Simplified for Your Enjoyment</h2>
                  </div>
                </div>
                <div className="col4_custom col_custom">
                  <div className="list_col">
                    <h2>The User Experience</h2>
                  </div>
                </div>
              </div>

              {/* mobile view */}
              <div className="listt_row1_mob">
                <div className="col1_custom col_custom_mob">
                  <div className="list_col">
                    <h2>Data Synthesis</h2>
                  </div>
                  <div className="col11_custom col_custom_mob">
                    <div className="list_col">
                      <div className="list_img_box">
                        <div className="img_box img_box">
                          <img src={content1} />
                        </div>
                        <div className="list_box1 list_box_commmon_mob">
                          <ul>
                            {/* <li>
															<a href="#">STORES</a>
														</li>

														<li>
															<a href="#">DOTCOM</a>
														</li>

														<li>
															<a href="#">LIFE EVENTS</a>
														</li>

														<li>
															<a href="#">DEALS</a>
														</li>

														<li>
															<a href="#">CLEARANCE</a>
														</li>

														<li>
															<a href="#">SDF</a>
														</li> */}
                            <li>
                              <a href="#">Scientific Data</a>
                            </li>
                            <li>
                              <a href="#">Financial Data</a>
                            </li>
                            <li>
                              <a href="#">Technologic Data</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col2_custom col_custom_mob">
                  <div className="list_col list_box_api">
                    <h2>Alternative Data</h2>
                  </div>
                  <div className="col12_custom col_custom_mob">
                    <div className="list_col">
                      <div className="list_img_box">
                        <div className="img_box img_box">
                          <img src={content2} />
                        </div>
                        <div className="list_box2 list_box_commmon">
                          <ul>
                            /*{" "}
                            <li>
                              <a href="#">PRODUCT CATALOG</a>
                            </li>
                            <li>
                              <a href="#">STORE INFORMATION</a>
                            </li>
                            <li>
                              <a href="#">PRODUCT AVAILABILITY</a>
                            </li>
                            <li>
                              <a href="#">PRODUCT REVIEWS</a>
                            </li>
                            <li>
                              <a href="#">PRODUCT CATEGORIES</a>
                            </li>
                            <li>
                              <a href="#">RECOMMENDATIONS</a>
                            </li>
                            <li>
                              <a href="#">BUYING OPTIONS</a>
                            </li>
                            <li>
                              <a href="#">SMART LISTS</a>
                            </li>{" "}
                            */
                            <li>
                              <a href="#">Artificial Intelligence</a>
                            </li>
                            <li></li>
                            <li>
                              <a href="#">Machine Learning</a>
                            </li>
                            <li></li>
                            <li>
                              <a href="#">Data Analytics</a>
                            </li>
                            <li></li>
                            <li>
                              <a href="#">Data Streaming</a>
                            </li>
                            <li></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col13_custom col_custom_mob">
                  <div className="list_col">
                    <h2>Simplified for Your Enjoyment</h2>
                    <div className="col3_custom col_custom_mob">
                      <div className="list_col">
                        <div className="list_img_box">
                          <div className="img_box img_box">
                            <img src={content3} />
                          </div>
                          <div className="list_box3 list_box_commmon">
                            <ul>
                              /*{" "}
                              <li>
                                <a href="#">BBY MARKETING</a>
                              </li>
                              <li>
                                <a href="#">EXACT TARGET</a>
                              </li>
                              <li>
                                <a href="#">CITI THANKYOU</a>
                              </li>
                              <li>
                                <a href="#">EBAY</a>
                              </li>
                              <li>
                                <a href="#">FACTUAL</a>
                              </li>
                              <li>
                                <a href="#">IFTTT</a>
                              </li>
                              <li>
                                <a href="#">PRICE GRABBER</a>
                              </li>
                              <li>
                                <a href="#">THE FIND</a>
                              </li>
                              <li>
                                <a href="#">CNET.COM</a>
                              </li>
                              <li>
                                <a href="#">BBY EVERYWHERE</a>
                              </li>
                              <li>
                                <a href="#">COWBOOM</a>
                              </li>
                              <li>
                                <a href="#">HACKATHONS</a>
                              </li>{" "}
                              */
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li>
                                <a href="#">Data is</a>
                              </li>
                              <li>
                                <a href="#">Processed</a>
                              </li>
                              <li>
                                <a href="#">for You</a>
                              </li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col4_custom col_custom_mob">
                  <div className="list_col">
                    <h2>The User Experience</h2>
                    <div className="col4_custom col_custom_mob">
                      <div className="list_col">
                        <div className="list_img_box4">
                          <div className="list_box4 list_box_commmon">
                            <ul>
                              /*{" "}
                              <li>
                                <a href="#">INTERNAL USE</a>
                              </li>
                              <li>
                                <a href="#">SHOPPING APPS</a>
                              </li>
                              <li>
                                <a href="#">1:1 EMAILS</a>
                              </li>
                              <li>
                                <a href="#">LOYALTY - SHOP WITH POINTS</a>
                              </li>
                              <li>
                                <a href="#">BING</a>
                              </li>
                              <li>
                                <a href="#">APPLE MAPS</a>
                              </li>
                              <li>
                                <a href="#">GOOGLE MAPS</a>
                              </li>
                              <li>
                                <a href="#">PERSONALIZED NOTIFICATIONS</a>
                              </li>{" "}
                              */
                              <li></li>
                              <li>
                                {" "}
                                <a href="#">Portfolio Structuring </a>
                              </li>
                              <li></li>
                              <li>
                                <a href="#">Portfolio Analytics</a>
                              </li>
                              <li></li>
                              <li>
                                <a href="#">Financial Backtesting</a>
                              </li>
                              <li></li>
                              <li>
                                <a href="#">Importable to Your Device</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="listt_row">
                <div className="col1_custom col_custom">
                  <div className="list_col">
                    <div className="list_img_box">
                      <div className="list_box1 list_box_commmon">
                        <ul>
                          /*{" "}
                          <li>
                            <a href="#">STORES</a>
                          </li>
                          <li>
                            <a href="#">DOTCOM</a>
                          </li>
                          <li>
                            <a href="#">LIFE EVENTS</a>
                          </li>
                          <li>
                            <a href="#">DEALS</a>
                          </li>
                          <li>
                            <a href="#">CLEARANCE</a>
                          </li>
                          <li>
                            <a href="#">SDF</a>
                          </li>{" "}
                          */
                          <li>
                            <a href="#">Scientific Data</a>
                          </li>
                          <li>
                            <a href="#">Financial Data</a>
                          </li>
                          <li>
                            <a href="#">Technologic Data</a>
                          </li>
                        </ul>
                      </div>
                      <div className="img_box img_box1">
                        <img src={content1} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col2_custom col_custom">
                  <div className="list_col">
                    <div className="list_img_box">
                      <div className="list_box2 list_box_commmon">
                        <ul>
                          /*{" "}
                          <li>
                            <a href="#">PRODUCT CATALOG</a>
                          </li>
                          <li>
                            <a href="#">STORE INFORMATION</a>
                          </li>
                          <li>
                            <a href="#">PRODUCT AVAILABILITY</a>
                          </li>
                          <li>
                            <a href="#">PRODUCT REVIEWS</a>
                          </li>
                          <li>
                            <a href="#">PRODUCT CATEGORIES</a>
                          </li>
                          <li>
                            <a href="#">RECOMMENDATIONS</a>
                          </li>
                          <li>
                            <a href="#">BUYING OPTIONS</a>
                          </li>
                          <li>
                            <a href="#">SMART LISTS</a>
                          </li>{" "}
                          */
                          <li>
                            <a href="#">Artificial Intelligence</a>
                          </li>
                          <li></li>
                          <li>
                            <a href="#">Machine Learning</a>
                          </li>
                          <li></li>
                          <li>
                            <a href="#">Data Analytics</a>
                          </li>
                          <li></li>
                          <li>
                            <a href="#">Data Streaming</a>
                          </li>
                          <li></li>
                        </ul>
                      </div>
                      <div className="img_box img_box2">
                        <img src={content2} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col3_custom col_custom">
                  <div className="list_col">
                    <div className="list_img_box">
                      <div className="list_box3 list_box_commmon">
                        <ul>
                          /*{" "}
                          <li>
                            <a href="#">BBY MARKETING</a>
                          </li>
                          <li>
                            <a href="#">EXACT TARGET</a>
                          </li>
                          <li>
                            <a href="#">CITI THANKYOU</a>
                          </li>
                          <li>
                            <a href="#">EBAY</a>
                          </li>
                          <li>
                            <a href="#">FACTUAL</a>
                          </li>
                          <li>
                            <a href="#">IFTTT</a>
                          </li>
                          <li>
                            <a href="#">PRICE GRABBER</a>
                          </li>
                          <li>
                            <a href="#">THE FIND</a>
                          </li>
                          <li>
                            <a href="#">CNET.COM</a>
                          </li>
                          <li>
                            <a href="#">BBY EVERYWHERE</a>
                          </li>
                          <li>
                            <a href="#">COWBOOM</a>
                          </li>
                          <li>
                            <a href="#">HACKATHONS</a>
                          </li>{" "}
                          */
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li>Data is Processed for You</li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                      <div className="img_box img_box3">
                        <img src={content3} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col4_custom col_custom">
                  <div className="list_col">
                    <div className="list_img_box">
                      <div className="list_box4 list_box_commmon">
                        <ul>
                          /*{" "}
                          <li>
                            <a href="#">INTERNAL USE</a>
                          </li>
                          <li>
                            <a href="#">SHOPPING APPS</a>
                          </li>
                          <li>
                            <a href="#">1:1 EMAILS</a>
                          </li>
                          <li>
                            <a href="#">LOYALTY - SHOP WITH POINTS</a>
                          </li>
                          <li>
                            <a href="#">BING</a>
                          </li>
                          <li>
                            <a href="#">APPLE MAPS</a>
                          </li>
                          <li>
                            <a href="#">GOOGLE MAPS</a>
                          </li>
                          <li>
                            <a href="#">PERSONALIZED NOTIFICATIONS</a>
                          </li>{" "}
                          */
                          <li></li>
                          <li>
                            {" "}
                            <a href="#">Portfolio Structuring </a>
                          </li>
                          <li></li>
                          <li>
                            <a href="#">Portfolio Analytics</a>
                          </li>
                          <li></li>
                          <li>
                            <a href="#">Financial Backtesting</a>
                          </li>
                          <li></li>
                          <li>
                            <a href="#">Importable to Your Device</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-chart ">
            <div className="container flow-graph">
              {/* new graph starts*/}

             <DesktopFlows />

              {/*  Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) */}
              {/* <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> */}

              {/* <img className='graph-image' src={contentchart} alt='graph' /> */}
            </div>
            <div className="container flow-graph-mobile">
              <MobileFlows />
            </div>
          </div>
        </section>

        <section class="investment_main">
          <div className="investement-tabs">
            <div className="investment_hd">
            {!auth.user && window.innerWidth > 900 && <ContentPopup />}
              <h2 className="Investment-Experience">
                A New Kind of Investment Experience
              </h2>
              <h2 className="Hot-Investments">
                A New Kind of Investment Experience
              </h2>
            </div>
            <div className="investment-container relative">
              {/* {!auth.user && window.innerWidth > 900 && <ContentPopup />} */}

              <div className="inverstment-dropdown-tabs">
                <div
                  className="nav inverstment-dropdown-desktop"
                  style={{ justifyContent: "center" }}
                >
                  {items.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveIndex(index);
                        setData(item.data);
                        setInteractiveBrokers(item.interactiveBroker);
                        setRender(true);
                        setFormatData(item.formatData);
                        localStorage.setItem("filename", item.filename);
                        useStore.setState({ filename: item.filename });
            
                      }}
                      className={`investement-tabs-option ${
                        index === activeIndex ? "activeClass" : "inactiveClass"
                      }`}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
                <Dropdown className="inverstment-dropdown-mobile">
                  <Dropdown.Toggle id="dropdown-basic">
                    {selectedDropDown}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="nav"
                    style={{ justifyContent: "center" }}
                  >
                    {items.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setActiveIndex(index);
                          setData(item.data);
                          setInteractiveBrokers(item.interactiveBroker);
                          setRender(true);
                          setFormatData(item.formatData);
                          setDropDown(item.title);
                        }}
                        className={`investement-tabs-option ${
                          index == activeIndex ? "activeClass" : "inactiveClass"
                        }`}
                      >
                        {item.title}
                      </div>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className='tab-content'>
                <div id='included_service' className='tab-pane in active'>
                  <div className='root-inv'>
                    <div className='main-col'>
                      <ContentCardJsonData1
                        data={data}
                        interactiveBroker={interactiveBrokers}
                        render={render}
                        setRender={setRender}
                        formatData={formatData}
                        setinteractiveBroker={setInteractiveBrokers}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className='faq_sec'>
          <div className='container'>
            <div className='faq_hdng'>
              <h2>Frequently Asked Questions</h2>
              <p>
                Join GZI community now to get free updates and exclusive
                resources curated by our experts.
              </p>
            </div>
          </div>
          <div className="tabs_box">
            <ul
              className="nav nav-pills mb-3 nav-pills-mobile"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="general_id"
                  data-toggle="pill"
                  href="#general_tab"
                  role="tab"
                  aria-controls="general_tab"
                  aria-selected="true"
                >
                  General
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="crypto_id"
                  data-toggle="pill"
                  href="#crypto_tab"
                  role="tab"
                  aria-controls="crypto_tab"
                  aria-selected="false"
                >
                  API
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="subscription_id"
                  data-toggle="pill"
                  href="#subscription_tab"
                  role="tab"
                  aria-controls="subscription_tab"
                  aria-selected="false"
                >
                  Subscription
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pricing_id"
                  data-toggle="pill"
                  href="#pricing_tab"
                  role="tab"
                  aria-controls="pricing_tab"
                  aria-selected="false"
                >
                  Pricing
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="general_tab"
                role="tabpanel"
                aria-labelledby="general_id"
              >
                <div className="owl-slider general_tab_slider common_slider hide_in_mob">
                  <div id="faq_carousel_general" className="owl-carousel">
                    <div className="item">
                      <h3>Which platforms is GZI compatible with?</h3>
                      <p>
                        GZI is currently formatted for Interactive Brokers.
                        International equities data is only compatible with
                        Trader Workstation due to clearing restrictions for
                        international securities.
                      </p>
                      <p>
                        Data can still be downloaded, but not uploaded to other
                        platforms.
                      </p>
                      <a href=" " className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                    <div className="item">
                      <h3>
                        What is alternative data and why do I want to use it?
                      </h3>
                      <p>
                        Alternative data is powered by synthetic AI that
                        combines alternative factors with quantamentals. We
                        process everything on the backend and keep the front-end
                        simple.
                      </p>
                      <p>
                        Simply pick whatever is of interest to you and enjoy.
                        This is elegance in simplicity for your investments.
                      </p>
                      <a href="#" className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                {/* mobile view */}
                <div className="view_in_mob">
                  <div className="faq_collapse_mob">
                    <div id="faq_accordion">
                      <div className="card">
                        <div className="card-header" id="general_faq_hd1">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#general_collapse1"
                              aria-expanded="true"
                              aria-controls="general_collapse1"
                            >
                              <span className="gstn_hd">
                                Which platforms is GZI compatible with?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="general_collapse1"
                          className="collapse show"
                          aria-labelledby="general_faq_hd1"
                          data-parent="#faq_accordion"
                        >
                          <div className="card-body">
                            <p>
                              GZI is currently formatted for Interactive
                              Brokers. International equities data is only
                              compatible with Trader Workstation due to clearing
                              restrictions for international securities.
                            </p>
                            <p>
                              Data can still be downloaded, but not uploaded to
                              other platforms.
                            </p>
                            <a href="#" className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="general_faq_hd2">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#general_collapse2"
                              aria-expanded="false"
                              aria-controls="general_collapse2"
                            >
                              <span className="gstn_hd">
                                What is alternative data and why do I want to
                                use it?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="general_collapse2"
                          className="collapse"
                          aria-labelledby="general_faq_hd2"
                          data-parent="#faq_accordion"
                        >
                          <div className="card-body">
                            <p>
                              Alternative data is powered by synthetic AI that
                              combines alternative factors with quantamentals.
                              We process everything on the backend and keep the
                              front-end simple.
                            </p>
                            <p>
                              Simply pick whatever is of interest to you and
                              enjoy. This is elegance in simplicity for your
                              investments.
                            </p>
                            <a href=" " className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* //mobile view */}
              </div>
              <div
                className="tab-pane fade"
                id="crypto_tab"
                role="tabpanel"
                aria-labelledby="crypto_id"
              >
                <div className="owl-slider general_tab_slider common_slider hide_in_mob">
                  <div id="faq_carousel_crypto" className="owl-carousel">
                    <div className="item">
                      <h3>Does GZI handle securities or cryptocurrenices?</h3>
                      <p>
                        No, GZI offers algorithmically pre-screened financial
                        data and analytics for structuring financial products and portfolios. We
                        do not make investment reccomendations.
                      </p>
                      <a href="#" className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                    <div className="item">
                      <h3>How can I stream data on GZI?</h3>
                      <p>
                        Visit our open source API on Github by clicking the link below.
                      </p>
                      <a href="https://github.com/seantzu/gzifinance" className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                {/*mobile view */}
                <div className="view_in_mob">
                  <div className="faq_collapse_mob">
                    <div id="faq_accordion1">
                      <div className="card">
                        <div className="card-header" id="crypto_faq_hd1">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#crypto_collapse1"
                              aria-expanded="false"
                              aria-controls="crypto_collapse1"
                            >
                              <span className="gstn_hd">
                                Does GZI handle securities or cryptocurrenices?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="crypto_collapse1"
                          className="collapse"
                          aria-labelledby="crypto_faq_hd1"
                          data-parent="#faq_accordion1"
                        >
                          <div className="card-body">
                            <p>
                              No, GZI offers algorithmically pre-screened
                              financial data and analytics for structuring financial products
                              and portfolios. We do not make investment reccomendations.
                            </p>
                            <a href=" " className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="crypto_faq_hd2">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#crypto_collapse2"
                              aria-expanded="false"
                              aria-controls="crypto_collapse2"
                            >
                              <span className="gstn_hd">
                                How can I stream data on GZI?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="crypto_collapse2"
                          className="collapse"
                          aria-labelledby="crypto_faq_hd2"
                          data-parent="#faq_accordion1"
                        >
                          <div className="card-body">
                            <p>
                              Visit our open source API on Github by clicking the link below.
                            </p>
                            <a href="https://github.com/seantzu/gzifinance" className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* mobile view*/}
              </div>
              <div
                className="tab-pane fade"
                id="subscription_tab"
                role="tabpanel"
                aria-labelledby="subscription_id"
              >
                <div className="owl-slider general_tab_slider common_slider hide_in_mob">
                  <div id="faq_carousel_subscription" className="owl-carousel">
                    <div className="item">
                      <h3>What does each subscription tier give me?</h3>
                      <p>
                        Presight grants you access to alternative financial data
                        and financial data.
                      </p>
                      <p>
                        Insight gives you the ability to backtest and customize
                        the data.
                      </p>
                      <p>
                        Foresight is a customized offer tailored to the needs of
                        individual firms and requries you to contact a customer
                        support representative.
                      </p>
                      <a href="#" className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                    <div className="item">
                      <h3>How long is the free trial?</h3>
                      <p>
                        GZI provides everyone a complimentary free
                        trial. We will remove the free trial once you love it.
                      </p>
                      <a href="#" className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                {/* mobile view */}
                <div className="view_in_mob">
                  <div className="faq_collapse_mob">
                    <div id="faq_accordion2">
                      <div className="card">
                        <div className="card-header" id="subscription_faq_hd1">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#subscription_collapse1"
                              aria-expanded="false"
                              aria-controls="subscription_collapse1"
                            >
                              <span className="gstn_hd">
                                What does each subscription tier give me?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="subscription_collapse1"
                          className="collapse"
                          aria-labelledby="subscription_faq_hd1"
                          data-parent="#faq_accordion2"
                        >
                          <div className="card-body">
                            <p>
                              Presight grants you access to alternative
                              financial data and financial data.
                            </p>
                            <p>
                              Insight gives you the ability to backtest and
                              customize the data.
                            </p>
                            <p>
                              Foresight is a customized offer tailored to the
                              needs of individual firms and requries you to
                              contact a customer support representative.
                            </p>
                            <a href=" " className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="subscription_faq_hd2">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#subscription_collapse2"
                              aria-expanded="false"
                              aria-controls="subscription_collapse2"
                            >
                              <span className="gstn_hd">
                                How long is the free trial?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="subscription_collapse2"
                          className="collapse"
                          aria-labelledby="subscription_faq_hd2"
                          data-parent="#faq_accordion2"
                        >
                          <div className="card-body">
                            <p>
                              GZI provides everyone a complimentary free trial. We will remove the free trial once you love it.
                            </p>
                            <a href=" " className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* //mobile view */}
              </div>
              <div
                className="tab-pane fade"
                id="pricing_tab"
                role="tabpanel"
                aria-labelledby="pricing_id"
              >
                <div className="owl-slider general_tab_slider common_slider hide_in_mob">
                  <div id="faq_carousel_pricing" className="owl-carousel">
                    <div className="item">
                      <h3>
                        What is the minimum ticket size for purchasing shares of
                        GZI?
                      </h3>
                      <p>
                        Contact customer service if you are intersted in a
                        private offering. GZI is only open to institutions and has
                        a 7-figure minimum.
                      </p>
                      <a href=" " className="learn_more_a">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                {/* mobile view */}
                <div className="view_in_mob">
                  <div className="faq_collapse_mob">
                    <div id="faq_accordion3">
                      <div className="card">
                        <div className="card-header" id="pricing_faq_hd1">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#pricing_collapse1"
                              aria-expanded="false"
                              aria-controls="pricing_collapse1"
                            >
                              <span className="gstn_hd">
                              What is the minimum ticket size for purchasing shares of
                              GZI?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="pricing_collapse1"
                          className="collapse"
                          aria-labelledby="pricing_faq_hd1"
                          data-parent="#faq_accordion3"
                        >
                          <div className="card-body">
                            <p>
                              Contact customer service if you are intersted in a
                              private offering. GZI is only open to institutions and has
                              a 7-figure minimum.
                            </p>
                            <a href=" " className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="pricing_faq_hd2">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#pricing_collapse2"
                              aria-expanded="false"
                              aria-controls="pricing_collapse2"
                            >
                              <span className="gstn_hd">
                                How long is the free trial?
                              </span>
                              <span className="collapse_icon"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="pricing_collapse2"
                          className="collapse"
                          aria-labelledby="pricing_faq_hd2"
                          data-parent="#faq_accordion3"
                        >
                          <div className="card-body">
                            <p>
                              GZI provides everyone a complimentary one month
                              free trial.
                            </p>
                            <a href=" " className="faq_mob_learn_more_acc">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* //mobile view */}
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
      <Modal show={showModal} className="content-modal-popup">
        <Modal.Header>
          <div className="close-btn" onClick={handleClose}>
            <span>
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={popupvid + "?autoplay=1&mute=1"}
            title="Presight Popup"
            frameBorder="0"
            rameborder="0"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContentPage;

//Shifting Popup to comments until youtube link works.
{
  /* <Modal show={showModal} className="content-modal-popup">
	<Modal.Header>
		<div className="close-btn" onClick={handleClose}>
			<span><i className="fa-solid fa-xmark"></i></span>
		</div>
	</Modal.Header>
	<Modal.Body>
		<iframe src={popupvid} title="Presight Popup" frameBorder="0" rameborder="0" allowFullScreen></iframe>
	</Modal.Body>
</Modal> */
}

// import React , {useState} from "react";
// import ContentCardsSection from "./../components/ContentCardsSection";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import HowWorkSection from "../components/home/HowWorkSection";
// import content_ban from "../assets/images/content_ban.jpg";
// import cnt_dtl_pic1 from "../assets/images/cnt_dtl_pic1.png";
// import cnt_dtl_pic2 from "../assets/images/cnt_dtl_pic2.png";
// import cnt_dtl_pic3 from "../assets/images/cnt_dtl_pic3.png";
// import cnt_dtl_pic4 from "../assets/images/cnt_dtl_pic4.png";
// import cnt_dtl_pic5 from "../assets/images/cnt_dtl_pic5.png";
// import cnt_dtl_pic6 from "../assets/images/cnt_dtl_pic6.png";
// import cnt_dtl_pic7 from "../assets/images/cnt_dtl_pic7.png";
// import csvjson_z1 from "../data/json/csvjson_z1.json";
// import csvjson_z2 from "../data/json/csvjson_z2.json";
// import csvjson_z3 from "../data/json/csvjson_z3.json";
// import csvjson_z22 from "../data/json/csvjson_z2.2.json";
// import Z1_InteractiveBrokers from '../data/json/Z1_InteractiveBrokers.json'
// import Z2_2_InteractiveBrokers from '../data/json/Z2.2_2_InteractiveBrokers.json'
// import Z2_InteractiveBrokers from '../data/json/Z2_InteractiveBrokers.json'
// import Z3_InteractiveBrokers from '../data/json/Z3_InteractiveBrokers.json'
// import ContentCardJsonData from "../components/ContentCardJsonData";
// import "../assets/css/gzi.css";

// function IndexPage(props) {
//  return (
//    <Fragment>
//      <div className="main-wrap">
//        <main style={{paddingTop: '5rem'}}>
//            <HowWorkSection />
//        </main>
//      </div>
//    </Fragment>
//  );
//}

// const items = [
//   {
//     image: cnt_dtl_pic1,
//     title: "North America",
//     body:
//       "Stock Exchanges Include: New York Stock Exchange(NYSE), Nasdaq Technology (NASDAQ), Mexico (MEXI),Canada (TSE), Canadian Venture (TSXV)",
//     url: "/post/north-america",
//     data: csvjson_z1,
//     interactiveBroker: Z1_InteractiveBrokers
//   },
//   {
//     image: cnt_dtl_pic2,
//     title: "Western Europe",
//     body:
//       "Stock Exchanges Include: London (LSE), France (SBF),Portugal (BVL), Italy (BMV), Vienna (VSE), SwissElectronic Bourse (EBS), SIX Swiss (VIRTX)",
//     url: "/post/western_europe",
//     data: csvjson_z2,
//     interactiveBroker: Z2_InteractiveBrokers
//   },
//   {
//     image: cnt_dtl_pic3,
//     title: "Scandanvaia and Eastern Europe",
//     body:
//       "Stock Exchanges Include: Sweeden (SFB), Norway (OSE),Russia (MOEX), Ukraine (WSE)",
//     url: "/post/eastern_europe",
//     data: csvjson_z22,
//     interactiveBroker: Z2_2_InteractiveBrokers
//   },
//   {
//     image: cnt_dtl_pic4,
//     title: "Asia, Oceania, and the Middle East",
//     body:
//       "Stock Exchanges Include: Isreal (TASE), Shanghai(SSE), Shenzhen (SZE), Hong Kong (SEHK), Singapore(SGX), Japan (TSEJ), Australia (ASX)",
//     url: "/post/asia",
//     data: csvjson_z3,
//     interactiveBroker: Z3_InteractiveBrokers
//   },
//   {
//     image: cnt_dtl_pic5,
//     title: "Biotechnology",
//     body:
//       "Our specialty. Applies worldwide.",
//     url: "/post/biotech",
//     data: csvjson_z22,
//     interactiveBroker: Z2_2_InteractiveBrokers
//   },
//   {
//     image: cnt_dtl_pic6,
//     title: "Cryptocurrency",
//     body:
//       "For those who want to avoid direct exposure. Applies worldwide.",
//     url: "/post/crypto",
//     data: csvjson_z22,
//     interactiveBroker: Z2_2_InteractiveBrokers
//   },
//   {
//     image: cnt_dtl_pic7,
//     title: "Psychedelics",
//     body:
//       "Feed your head and your wallet. Applies worldwide.",
//     url: "/post/psych",
//     data: csvjson_z22,
//     interactiveBroker: Z2_2_InteractiveBrokers
//   },

// ]

// function ContentPage(props) {
//   const [data,setData] = useState(csvjson_z1)
//   const [interactiveBrokers, setInteractiveBrokers] = useState(Z1_InteractiveBrokers)
//   const [activeIndex,setActiveIndex] = useState(0)
//   const [render,setRender] = useState(false)

//   console.log("rerender")
//   console.log(data[0])
//   return (
//     <>
//       <Header
//         {...props}
//         pageName={"content"}
//         bgImage={content_ban}
//       />
//      <div className="main-inv-section">
//       <div className="investement">
//           <div className="invest-content">
//             <span>Investments so Hot They Are on Fire</span>
//             <h2>Hot Investments</h2>
//             <p></p>
//           </div>

//        </div>

//       <div className="investement-tabs">
//       <div className="nav" style={{justifyContent:'center'}}>
//           {items.map((item,index)=>
//             <div key={index}
//             onClick={()=> {
//               setActiveIndex(index)
//               setData(item.data)
//               setInteractiveBrokers(item.interactiveBroker)
//               setRender(true)
//             }}
//             className= {`investement-tabs-option ${index == activeIndex ? "activeClass" : "inactiveClass"}`}

//             >
//               {item.title}
//             </div>
//           )}

//         </div>
//         <div className="tab-content">

//       <div id="included_service" className="tab-pane in active">
//         <div className="root-inv">
//             <div className="main-col">
//              <ContentCardJsonData data={data} interactiveBroker={interactiveBrokers} render={render}/>
//             </div>
//         </div>
//       </div>

//      </div>
//     </div>

//       </div>
//       <Footer {...props} pageName={""} />
//     </>
//   );
// }

// export default ContentPage;
