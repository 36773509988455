import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AspectRatio from "./AspectRatio";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 1px ${emphasize(theme.palette.background.paper, 0.06)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "auto",
    maxWidth: "200px",
    marginBottom: "0px",
    "& img": {
      width: "70%",
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Alternative Insights",
      body:
        "We resell the industry agnostic and sector specific alternative data used to outperform some of the top funds on Wall St., back to clients across more than 4 continents and 16 countries.",
      image:
        "https://mcusercontent.com/078d8cc252dde215e3779a5b2/images/81a1eba8-99c2-48f7-b216-bb4fc8ca73dc.png",
    },
    {
      title: "Outperform",
      body:
        "GZI delivers cutting edge alternative data to users in search of higher returns and less volatility. While we don’t actually do the investing for you, we provide the necessary resources for you to make informed decisions. Alternative data helps investors get more accurate, faster, and granular insights/metrics.",
      image:
        "https://mcusercontent.com/078d8cc252dde215e3779a5b2/images/885ee065-2c83-40b1-94dc-5f526be3f570.png",
    },
    {
      title: "Put the World in Your Pocket",
      body:
        "Our results for 2020 and 2021 are respectivly 51% and 23% higher than robo advisors,and on par with the top hedge funds in the world at a fraction of the price.",
      image:
        "https://mcusercontent.com/078d8cc252dde215e3779a5b2/images/56a562df-2359-44fd-825e-c3444363c40b.png",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          textAlign="center"
        />
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={3 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
