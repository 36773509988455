import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    accordion: {
      // Remove shadow
      boxShadow: "none",
      "&:before": {
        // Remove default divider
        display: "none",
      },
      // Add a custom border
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    expanded: {
      margin: `0 !important`,
    },
    summary: {
      minHeight: 78,
    },
    summaryContent: {
      margin: "0 !important",
    },
    ft0: {
      font: "bold 16px",
      textDecoration: "underline",
      lineHeight: "19px",
    },
    ft1: {
      font: "16px",
      lineHeight: "19px",
    },
    ft2: {
      font: "bold 16px",
      lineHeight: "20px",
    },
    ft3: {
      font: "16px",
      lineHeight: "20px",
    },
    ft4: {
      font: "bold 16px",
      lineHeight: "19px",
    },
    ft5: {
      font: "italic bold 16px",
      lineHeight: "19px",
    },
    ft6: {
      font: "italic 16px",
      lineHeight: "19px",
    },
    ft7: {
      font: "16px",
      marginLeft: "12px",
      lineHeight: "19px",
    },
    ft8: {
      font: "16px",
      marginLeft: "12px",
      lineHeight: "20px",
    },
    ft9: {
      font: "16px",
      lineHeight: "18px",
    },
    ft10: {
      font: "bold 16px",
      lineHeight: "18px",
    },
    ft11: {
      font: "16px",
      marginLeft: "12px",
      lineHeight: "18px",
    },
    ft12: {
      font: "16px",
      marginLeft: "4px",
      lineHeight: "18px",
    },
    ft13: {
      font: "16px",
      marginLeft: "4px",
      lineHeight: "19px",
    },
    ft14: {
      font: "italic bold 16px",
      lineHeight: "20px",
    },
    ft15: {
      font: "16px",
      textDecoration: "underline",
      color: "#0000ff",
      lineHeight: "19px",
    },
    ft16: {
      font: "16px,color: #0000ff",
      lineHeight: "19px",
    },
    ft17: {
      font: "16px,marginLeft: 17px",
      lineHeight: "20px",
    },
    ft18: {
      font: "16px,marginLeft: 17px",
      lineHeight: "18px",
    },
    ft19: {
      font: "16px,marginLeft: 17px",
      lineHeight: "19px",
    },
    ft20: {
      font: "italic 16px",
      lineHeight: "18px",
    },
    ft21: {
      font: "bold 16px",
      lineHeight: "21px",
    },
    ft22: {
      font: "italic 16px",
      lineHeight: "20px",
    },
    ft24: {
      font: "16px",
      marginLeft: "5px",
      lineHeight: "19px",
    },
    ft23: {
      font: "16px",
      marginLeft: "6px",
      lineHeight: "19px",
    },
    ft25: {
      font: "16px",
      backgroundColor: "#ffff00",
      lineHeight: "19px",
    },
    ft26: {
      font: "italic bold 16px",
      lineHeight: "19px",
    },
    ft27: {
      font: "italic 16px",
      lineHeight: "19px",
    },
    ft28: {
      font: "14px",
      lineHeight: "16px",
    },
    ft29: {
      font: "16px",
      lineHeight: "20px",
    },
    ft30: {
      font: "bold 16px",
      marginLeft: "18px",
      lineHeight: "19px",
    },
    ft31: {
      font: "bold 16px",
      marginLeft: "18px",
      lineHeight: "18px",
    },
    ft32: {
      font: "bold 16px",
      lineHeight: "18px",
    },
    ft33: {
      font: "16px",
      marginLeft: "18px",
      lineHeight: "20px",
    },
    p0: {
      textAlign: "left",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p1: {
      textAlign: "left",
      marginTop: "18px",
      marginBottom: "0px",
    },
    p2: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "18px",
      marginBottom: "0px",
    },
    p3: {
      textAlign: "left",
      marginTop: "14px",
      marginBottom: "0px",
    },
    p4: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "32px",
      marginBottom: "0px",
    },
    p5: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "14px",
      marginBottom: "0px",
    },
    p6: {
      textAlign: "left",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p7: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "35px",
      marginBottom: "0px",
    },
    p8: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p9: {
      textAlign: "justify",
      paddingRight: "97px",
      marginTop: "18px",
      marginBottom: "0px",
    },
    p10: {
      textAlign: "left",
      marginTop: "37px",
      marginBottom: "0px",
    },
    p11: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p12: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "18px",
      marginBottom: "0px",
    },
    p13: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "29px",
      marginBottom: "0px",
    },
    p14: {
      textAlign: "left",
      marginTop: "35px",
      marginBottom: "0px",
    },
    p15: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p16: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "19px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p17: {
      textAlign: "left",
      marginTop: "15px",
      marginBottom: "0px",
    },
    p18: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "15px",
      marginBottom: "0px",
    },
    p19: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "4px",
      marginBottom: "0px",
    },
    p20: {
      textAlign: "left",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p21: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "13px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p22: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p23: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "0px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p24: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p25: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "1px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p26: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "36px",
      marginBottom: "0px",
    },
    p27: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "12px",
      marginBottom: "0px",
    },
    p28: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "13px",
      marginBottom: "0px",
    },
    p29: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p30: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "13px",
      marginBottom: "0px",
    },
    p31: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p32: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "27px",
      marginBottom: "0px",
    },
    p33: {
      textAlign: "left",
      marginTop: "13px",
      marginBottom: "0px",
    },
    p34: {
      textAlign: "left",
      marginTop: "34px",
      marginBottom: "0px",
    },
    p35: {
      textAlign: "left",
      marginTop: "0px",
      marginBottom: "0px",
      whiteSpace: "nowrap",
    },
    p36: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "11px",
      marginBottom: "0px",
    },
    p37: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p38: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "14px",
      marginBottom: "0px",
    },
    p39: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "3px",
      marginBottom: "0px",
    },
    p40: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p41: {
      textAlign: "left",
      marginTop: "33px",
      marginBottom: "0px",
    },
    p42: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "11px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p43: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "16px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p44: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "2px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p45: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p46: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "1px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p47: {
      textAlign: "left",
      marginTop: "24px",
      marginBottom: "0px",
    },
    p48: {
      textAlign: "left",
      paddingRight: "97px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p49: {
      textAlign: "left",
      paddingRight: "96px",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p50: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "35px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p51: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "1px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p52: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "0px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p53: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "3px",
      marginBottom: "0px",
      textIndent: -"24px",
    },
    p54: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "30px",
      marginBottom: "0px",
    },
    p55: {
      textAlign: "left",
      marginTop: "11px",
      marginBottom: "0px",
    },
    p56: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "1px",
      marginBottom: "0px",
    },
    p57: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "26px",
      marginBottom: "0px",
    },
    p58: {
      textAlign: "justify",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p59: {
      textAlign: "justify",
      paddingRight: "1px",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p60: {
      textAlign: "justify",
      marginTop: "14px",
      marginBottom: "0px",
    },
    p61: {
      textAlign: "justify",
      marginTop: "33px",
      marginBottom: "0px",
    },
    p62: {
      textAlign: "justify",
      paddingRight: "1px",
      marginTop: "13px",
      marginBottom: "0px",
    },
    p63: {
      textAlign: "justify",
      paddingRight: "1px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p64: {
      textAlign: "justify",
      marginTop: "15px",
      marginBottom: "0px",
    },
    p65: {
      textAlign: "left",
      paddingRight: "1px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p66: {
      textAlign: "justify",
      paddingRight: "1px",
      marginTop: "32px",
      marginBottom: "0px",
    },
    p67: {
      textAlign: "justify",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p68: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p69: {
      textAlign: "left",
      marginTop: "14px",
      marginBottom: "0px",
    },
    p70: {
      textAlign: "left",
      marginTop: "13px",
      marginBottom: "0px",
    },
    p71: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "18px",
      marginBottom: "0px",
    },
    p72: {
      textAlign: "left",
      marginTop: "36px",
      marginBottom: "0px",
    },
    p73: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p74: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "33px",
      marginBottom: "0px",
    },
    p75: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p76: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p77: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "1px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p78: {
      textAlign: "left",
      paddingRight: "97px",
      marginTop: "36px",
      marginBottom: "0px",
    },
    p79: {
      textAlign: "left",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p80: {
      textAlign: "left",
      paddingRight: "96px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p81: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "13px",
      marginBottom: "0px",
    },
    p82: {
      textAlign: "left",
      paddingLeft: "48px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p83: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "1px",
      marginBottom: "0px",
    },
    p84: {
      textAlign: "left",
      marginTop: "19px",
      marginBottom: "0px",
    },
    p85: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p86: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "15px",
      marginBottom: "0px",
    },
    p87: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "25px",
      marginBottom: "0px",
    },
    p88: {
      textAlign: "left",
      marginTop: "15px",
      marginBottom: "0px",
    },
    p89: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "15px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p90: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "17px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p91: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p92: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p28: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "16px",
      marginBottom: "0px",
    },
    p93: {
      textAlign: "left",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p94: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "10px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p95: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "15px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p96: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p97: {
      textAlign: "left",
      paddingRight: "97px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p98: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "95px",
      marginTop: "13px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p99: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "11px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p100: {
      textAlign: "justify",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "8px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p101: {
      textAlign: "left",
      marginTop: "35px",
      marginBottom: "0px",
    },
    p102: {
      textAlign: "left",
      paddingRight: "96px",
      marginTop: "18px",
      marginBottom: "0px",
    },
    p103: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "97px",
      marginTop: "13px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p104: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p105: {
      textAlign: "left",
      paddingLeft: "24px",
      marginTop: "11px",
      marginBottom: "0px",
    },
    p106: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    p43: {
      textAlign: "left",
      paddingLeft: "48px",
      paddingRight: "96px",
      marginTop: "11px",
      marginBottom: "0px",
      textIndent: "-24px",
    },
    p107: {
      textAlign: "justify",
      paddingRight: "95px",
      marginTop: "17px",
      marginBottom: "0px",
    },
    p108: {
      textAlign: "left",
      marginTop: "29px",
      marginBottom: "0px",
    },
    p109: {
      textAlign: "left",
      marginTop: "33px",
      marginBottom: "0px",
    },
    p110: {
      textAlign: "justify",
      paddingRight: "96px",
      marginTop: "36px",
      marginBottom: "0px",
    },
    td0: {
      width: "104px",
      verticalAlign: "bottom",
    },
    td1: {
      width: "56px",
      verticalAlign: "bottom",
    },
    td2: {
      width: "69px",
      verticalAlign: "bottom",
    },
    td3: {
      width: "71px",
      verticalAlign: "bottom",
    },
    td4: {
      width: "73px",
      verticalAlign: "bottom",
    },
    td5: {
      width: "57px",
      verticalAlign: "bottom",
    },
    td6: {
      width: "89px",
      verticalAlign: "bottom",
    },
    td7: {
      width: "77px",
      verticalAlign: "bottom",
    },
    td8: {
      width: "27px",
      verticalAlign: "bottom",
    },
    tr0: {
      height: "19px",
    },
    t0: {
      width: "623px",
      font: "16px",
    },
}));

export default useStyles;