import { useRouter } from "./../util/router.js";
import {useDispatch} from 'react-redux';
import SignIn from '../pages/signIn/';
import SignUp from '../pages/signup/';
// import ForgotPassword from '../pages/forgotPassword/';
// import ResetPassword from '../pages/resetPassword/';

function Auth(props) {
  const { afterAuthPath, type } = props;
  const router = useRouter();
  const dispatch =useDispatch();
  const handleAuth = (user) => {
    // console.log('data>',user);
    dispatch({type:"AUTHENTICATE_USER",payload:true})
    router.push(afterAuthPath);
  };

  return (
    <>
      {type === "signin" && <SignIn onAuth={handleAuth}/>}
      {type === "signup" && <SignUp onAuth={handleAuth}/>}
    </>
  );
}

export default Auth;
