import { useAuth } from "../../util/auth.js";

const PaymentLinksSection = (props) => {
    const auth = useAuth();

    return (
        <div class="table-row table-footer-row">
            <div class="column clumn1 detail-column">
            </div>
            <div class="column clumn2 card-column">
                <a
                    href="#"
                    class="default-button"
                    onClick={() => {
                        props.history.push(
                            auth.user
                            ? `/purchase/${"basic"}`
                            : `/auth/signup?next=/purchase/${"basic"}`
                        );
                    }}
                >
                    Get Started
                </a>
            </div>
            <div class="column clumn3 card-column heightlight">
                <a
                    href="#"
                    class="default-button"
                    onClick={() => {
                        props.history.push(
                            auth.user
                            ? `/purchase/${"business"}`
                            : `/auth/signup?next=/purchase/${"business"}`
                        );
                    }}
                >
                    Get Started
                </a>
            </div>
            <div class="column clumn4 card-column">
                <a
                    href="#"
                    class="button default-button default-button--border default-button--border-black"
                    onClick={() => {
                        props.history.push(
                            auth.user
                            ? `/purchase/${"business"}`
                            : `/auth/signup?next=/purchase/${"pro"}`
                        );
                    }}
                >
                    Contact Sales
                </a>
            </div>
        </div>
    );
}

export default PaymentLinksSection;