




import React from "react";
import SignUpForm from '../../components/signUp/SignUpForm'

const SignUp = ({onAuth}) => {
  return (

      <SignUpForm onAuth={onAuth}/>

  );
};

export default SignUp;






// import React from "react";
// import SignUpForm from '../../components/signUp/SignUpForm'
// import FormLayout from '../../components/FormLayout'

// const SignUp = ({onAuth}) => {
//   return (
//     <FormLayout>
//       <SignUpForm onAuth={onAuth}/>
//     </FormLayout>
//   );
// };

// export default SignUp;
