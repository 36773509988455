const Page2 = ({}) => {
  return (
    <div id="page_2">
      <div className='col-md-12 content'>
      <p className="terms-content d-flex">
        Please note that certain information may be required to register with us
        or to take advantage of some of our Services.
      </p>
      <p className="terms-content">Information Collected Automatically</p>
      <p className="terms-content">
        Whenever you interact with our Services, we automatically receive and
        record information on our server logs from your browser or device, which
        may include the following:
      </p>

        <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>IP address</p>

           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Device identifiers and information</p>

           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Web browser information</p>

           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Page view statistics</p>

           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Browsing history</p>

           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>Usage information</p>
   
           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              Transaction information (e.g. transaction amount, date and time
              such transaction occurred)
            </p>

           <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              Cookies and certain other tracking technologies (e.g. web beacons,
              pixel tags, SDKs, etc.)
            </p>
     <p className="terms-content d-flex">— for more information, see below</p>
       <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              Location information (e.g. IP address, zip code), including
              geolocation/GPS data – for more information, see below
            </p>
            <p className="terms-content d-flex"><i className="fa-solid fa-circle-check pr-2 pt-1"></i>
              Log data (e.g. access times, hardware and software information)
            </p>
        
      <h5>
        <strong className="text-white">Geolocation Data</strong>
      </h5>
      <p className="terms-content ">
        If you are accessing the Services from a mobile device or through a
        mobile application, you may be asked to share your precise geolocation
        information with us so we can customize your experience with the
        Services. We will ask you to <nobr>opt-in</nobr> before we use
        geolocation information to identify your precise location. If you agree
        to such collection, in most cases, you will be able to turn off such
        data collection at any time by accessing the privacy settings of your
        mobile device.
      </p>
      <h5>
        <strong className="text-white">
          {" "}
          Additional Information about Cookies
        </strong>
      </h5>
      <p>
        The Services use cookies and similar technologies such as pixel tags,
        web beacons, clear GIFs, and JavaScript (collectively, “
        <span>Cookies</span>”) to enable our servers to recognize your web
        browser and tell us how and when you visit and use our Services, to
        analyze trends, learn about our user base and operate and improve our
        Services. Cookies are small files – usually consisting of letters and
        numbers – placed on your computer, tablet, phone, or similar device when
        you use that device to visit our Services. We sometimes combine
        information collected through Cookies that is not Personal Data with
        Personal Data that we have about you, for example, to tell us who you
        are or whether you have an account with us. We may also supplement the
        information we collect from you with information received from third
        parties.
      </p>
      </div>
    </div>
  );
}

export default Page2;