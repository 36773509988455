import signin1 from "../../assets/images/signin-icon1.svg";
import signin2 from "../../assets/images/signin-icon2.svg";
import signin3 from "../../assets/images/signin-icon3.svg";
import signin4 from "../../assets/images/signin-icon4.svg";
import signin11 from "../../assets/images/signin-icon5.svg";
import signin22 from "../../assets/images/signin-icon6.svg";
import signin33 from "../../assets/images/signin-icon7.svg";
import signin44 from "../../assets/images/signin-icon8.svg";
import signin5 from "../../assets/images/email_icon.png";
import signin6 from "../../assets/images/password_lock_icon.png";
import "../../assets/css/gzi.css";
import content4 from "../../assets/images/Info2.svg";
import content5 from "../../assets/images/minus-signn.png";
import content6 from "../../assets/images/pricing_check.png";
import React, { useEffect, useRef } from "react";
import { useAuth } from "./../../util/auth.js";
import { useForm } from "react-hook-form";
import { useRouter } from "../../util/router.js";
import { useState } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/gzi.css";
import { Dropdown, Modal, Button } from 'react-bootstrap';
import content1 from "../../assets/images/connection1.png";
import content2 from "../../assets/images/connection2.png";
import content3 from "../../assets/images/connection3.png";
import content7 from "../../assets/images/plus.svg";
import content8 from "../../assets/images/info.svg";
import content9 from "../../assets/images/remove.svg";
import content10 from "../../assets/images/circle2.svg";
import content11 from "../../assets/images/circle3.svg";
import content12 from "../../assets/images/circle1.png";
import content13 from "../../assets/images/circle4.svg";
import content14 from "../../assets/images/circle5.svg";
import content15 from "../../assets/images/circle6.svg";
import content16 from "../../assets/images/circle7.svg";
import contentchart from "../../assets/images/content-char.svg";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Slider from "react-slick";
import { connect } from "react-redux";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";
import Chart from "../../components/Chart";
import trading_currency_banner from "../../assets/images/trading_currency_banner.jpg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import HowWorkSection from "../../components/home/HowWorkSection";
import content_ban from "../../assets/images/content_ban.jpg";
import cnt_dtl_pic1 from "../../assets/images/cnt_dtl_pic1.png";
import cnt_dtl_pic2 from "../../assets/images/cnt_dtl_pic2.png";
import cnt_dtl_pic3 from "../../assets/images/cnt_dtl_pic3.png";
import cnt_dtl_pic4 from "../../assets/images/cnt_dtl_pic4.png";
import cnt_dtl_pic5 from "../../assets/images/cnt_dtl_pic5.png";
import cnt_dtl_pic6 from "../../assets/images/cnt_dtl_pic6.png";
import cnt_dtl_pic7 from "../../assets/images/cnt_dtl_pic7.png";



const SignInForm = ({ onAuth }) => {
	const auth = useAuth();
	const router = useRouter();
	const { handleSubmit, register, errors, getValues } = useForm();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [keepSignedIn, setKeepSignedIn] = useState(false);


	// Handle form submission
	const onSubmit = async () => {
		// Call submit handler for auth type
		return auth.signup(email, password)
			.then((user) => {
				// Call auth complete handler
				onAuth(user);
			})
			.catch((error) => {
				alert('Error Occured')
				console.log(error.message)
				// Show error alert message
				// props.onFormAlert({
				//     type: "error",
				//     message: error.message,
				// });
			});
	};

	return (
    <>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0"
        />
        <link rel="stylesheet" type="text/css" href="css/style.css" />

        <meta data-react-helmet="true" name="docsearch:version" content="2.0" />
        <meta
          data-react-helmet="true"
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover,user-scalable=0"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
        />
        <>
          <script src="https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.slim.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js"></script>
          <script type="text/javascript" src="js/sticky_header.js"></script>
        </>
      </head>
      <section className="page_bg grid_section_component all_main_sec_padding">
        <section class="login_form_sec">
          <div class="container">
            <div class="div_for_border">
              <div class="row small_screen_row">
                <div class="col-md-7 col-sm-12 col-xs-12">
                  <div class="left_side_text">
                    <h1>Welcome to GZI</h1>
                    <h3>
                      Pioneering alternative data that’s on par with the top
                      hedge funds across the globe.
                    </h3>
                    <p>
                      Sign up for a free introductory trial. Follow the GZI Blog
                      on Medium and join GZI Community on LinkedIn to stay under
                      our wing with the latest updates.
                    </p>
                    <p class="last_p">
                      Over <strong>3</strong> moonshots called across two
                      continents and over <strong>10</strong> enterprise clients
                      added.
                    </p>
                    <ul class="svg_logo_ul">
                      <li>
                        <a href="#">
                          <img src={signin1} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin2} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin3} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin4} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin22} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin11} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin33} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={signin44} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-5 col-sm-12 col-xs-12">
                  <div class="right_side_text">
                    <h2>Sign Up</h2>
                    <p>Enter your account details below</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div class="form-group">
                        <span class="icon_span">
                          <img src={signin5} />
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                      <div class="form-group">
                        <span class="icon_span">
                          <img src={signin6} />
                        </span>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        <a
                          href="/auth/forgotpass"
                          onClick={() => router.push("/auth/forgotpass")}
                          class="forgot_password_a"
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <div class="form-group">
                        <label class="container_checkbox">
                          <input
                            type="checkbox"
                            checked={keepSignedIn}
                            onChange={(event) =>
                              setKeepSignedIn(event.target.checked)
                            }
                          />
                          <span class="checkmark"></span>
                          Keep me signed in
                        </label>
                      </div>
                      {/* <a href="/auth/signup" class="login_btn">
																	Login
											</a> */}
                      <input
                        type="submit"
                        className="login_btn"
                        defaultValue="Get Started"
                      />
                      <p class="create_account_p">
                        Don’t have an account?{" "}
                        <a href="/auth/signup">Create for free</a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="pricing_section">
            <div className="container">
              <div className="pricing_hdng">
                <h2 className="pricing_title">
                  Foresight Gives You the Ultimate Package for Investment Mastery
                </h2>
              </div>
              {/* mobile view start */}
              <table className="prize-package">
                <thead>
                  <tr>
                    <th>Basic</th>
                  </tr>
                  <tr>
                    <th className="sub-desc">Presight</th>
                  </tr>

                  <th className="prize">
                    <sup>$</sup>99<sub>/month</sub>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>Access to Data on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Analytics on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="">
                        <img src={content5} alt="" />
                      </a>
                    </td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Access to Financial Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Access to Alternative Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="">
                        <img src={content5} alt="" />
                      </a>
                    </td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="">
                        <img src={content5} alt="" />
                      </a>
                    </td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12 text-center">
                {/* <button className="prize-package-btn">Get Started</button> */}
                <button
                  className="prize-package-btn"
                  onClick={() => {
                    router.history.push(
                      auth.user
                        ? `/purchase/${"basic"}`
                        : `/auth/signup?next=/purchase/${"basic"}`
                    );
                  }}
                >
                  Get Started
                </button>
              </div>

              <table className="prize-package">
                <thead>
                  <tr>
                    <th>
                      {" "}
                      <span className="heading-badge">Popular</span>
                    </th>
                  </tr>

                  <tr>
                    <th className="heading-text">Business</th>
                  </tr>
                  <tr>
                    <th className="sub-desc">Insight</th>
                  </tr>

                  <th className="prize">
                    <sup>$</sup>999<sub>/month</sub>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>Access to Data on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Analytics on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>API Data Streaming</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Access to Financial Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Access to Alternative Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Backtesting on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>API Access to Livestreamed Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12 text-center">
                {/* <button className="prize-package-btn2">Get Started</button> */}
                <button
                  className="prize-package-btn2"
                  onClick={() => {
                    router.history.push(
                      auth.user
                        ? `/purchase/${"business"}`
                        : `/auth/signup?next=/purchase/${"business"}`
                    );
                  }}
                >
                  Get Started
                </button>
              </div>

              <table className="prize-package">
                <thead>
                  <tr>
                    <th>Pro</th>
                  </tr>
                  <tr>
                    <th className="sub-desc">Foresight</th>
                  </tr>

                  <th className="prize">
                    <sup>$</sup>9,999<sub>/month</sub>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>Access to Data on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Analytics on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>API Data Streaming</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Access to Financial Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Access to Alternative Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Backtesting on GZI</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>API Access to Livestreamed Data</td>
                    <td>
                      <a href="">
                        <img src={content4} alt="" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12 text-center">
                {/* <button className="prize-package-btn">Contact Sales</button> */}
                <button
                  className="prize-package-btn"
                  onClick={() => {
                    router.history.push(
                      auth.user
                        ? `/purchase/${"pro"}`
                        : `/auth/signup?next=/purchase/${"pro"}`
                    );
                  }}
                >
                  Contact Sales
                </button>
              </div>
              {/* mobile view ends */}
              <div className="pricing_table_div">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <h3>Basic</h3>
                        <p>Presight</p>
                        <h2>
                          <sup>$</sup>99<sub>/month</sub>
                        </h2>
                      </th>

                      <th>
                        <div className="badge_div">
                          <span className="badge">Popular</span>
                        </div>
                        <h3 className="business_text">Business</h3>
                        <p>Insight</p>
                        <h2>
                          <sup>$</sup>999<sub>/month</sub>
                        </h2>
                      </th>

                      <th>
                        <h3 className="">Pro</h3>
                        <p>Foresight</p>
                        <h2>
                          <sup>$</sup>9,999<sub>/month</sub>
                        </h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" ultimate-package-topic">
                        Access to Data on GZI
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                    </tr>

                    <tr>
                      <td className=" ultimate-package-topic">
                        Analytics on GZI{" "}
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                    </tr>

                    <tr>
                      <td className=" ultimate-package-topic">
                        API Data Streaming{" "}
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <img src={content5} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                    </tr>

                    <tr>
                      <td className=" ultimate-package-topic">
                        Access to Financial Data{" "}
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <img src={content5} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                    </tr>
                    <tr>
                      <td className=" ultimate-package-topic">
                        Access to Alternative Data
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <img src={content5} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                    </tr>

                    <tr>
                      <td className=" ultimate-package-topic">
                        Backtesting on GZI
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <img src={content5} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                      <td>
                        <img src={content6} />
                      </td>
                    </tr>
                    <tr>
                      <td className="ultimate-package-topic ultimate-package-topic-last">
                        API Access to Livestreamed Data
                        <span>
                          <i className="far fa-info-circle"></i>
                        </span>
                      </td>
                      <td className="ultimate-package-topic-last">
                        <img src={content5} />
                      </td>
                      <td className="ultimate-package-topic-last">
                        <img src={content6} />
                      </td>
                      <td className="ultimate-package-topic-last">
                        <img src={content6} />
                      </td>
                    </tr>

                    <tr>
                      <td className="ultimate-package-topic-last"></td>
                      <td className="ultimate-package-topic-last">
                        <button
                          className="ultimate-package-btn"
                          onClick={() => {
                            router.history.push(
                              auth.user
                                ? `/purchase/${"basic"}`
                                : `/auth/signup?next=/purchase/${"basic"}`
                            );
                          }}
                        >
                          Get Started
                        </button>
                      </td>
                      <td className="ultimate-package-topic-last">
                        <button
                          className="ultimate-package-btn-fill"
                          onClick={() => {
                            router.history.push(
                              auth.user
                                ? `/purchase/${"business"}`
                                : `/auth/signup?next=/purchase/${"business"}`
                            );
                          }}
                        >
                          Get Started
                        </button>
                      </td>
                      <td className="ultimate-package-topic-last">
                        <button
                          className="ultimate-package-btn"
                          onClick={() => {
                            router.history.push(
                              auth.user
                                ? `/purchase/${"pro"}`
                                : `/auth/signup?next=/purchase/${"pro"}`
                            );
                          }}
                        >
                          Contact Sales
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default SignInForm;
