const Page7 = ({}) => {
  return (
    <div id="page_7">
      <div className="col-md-12 content">
        <p>
          We also share Personal Data when necessary to complete a transaction
          initiated or authorized by you or provide you with a product or
          service you have requested. In addition to those set forth above,
          these parties also include:
        </p>
        <p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Other users (where you post information publicly or as otherwise
              necessary to effect a transaction initiated or authorized by you
              through the Services)
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Social media services (if you interact with such services through
              your use of the Services)
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Social media services (if you interact with such services through
              your use of the Services)
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Third party business partners who you access through the Services
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Data providers and vendors</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Other parties authorized by you</span>
          </p>
        </p>
        <h5>
          <strong className="text-white">User Profiles and Submissions</strong>
        </h5>
        <p>
          Certain user profile information that such user has uploaded to the
          Services, including your name, location, video or image content and
          Stock Tracking Information, may be displayed to other users to
          facilitate user interaction within the Services or address your
          request for our Services. Your account privacy settings may allow you
          to limit the other users who can see the Personal Data in your user
          profile and/or what information in your user profile is visible to
          others. Please remember that any content you upload to your public
          user profile, along with any Personal Data or content that you
          voluntarily disclose online in a manner other users can view (on
          discussion boards, in messages and chat areas, etc.) becomes publicly
          available, and can be collected and used by anyone. Your full name and
          GZI user name may also be displayed to other users if and when you
          send or receive messages or comments or upload images or videos
          through the Services.. Additionally, if you sign into the Services
          through a third party social networking site or service, your list of
          “friends” from that site or service may be automatically imported to
          the Services, and such “friends,” if they are also registered users of
          the Services, may be able to access certain non- public information
          you have entered in your Services user profile. Again, we do not
          control the policies and practices of any other third party site or
          service.
        </p>
        <h5>
          <strong className="text-white">Business Transfers</strong>
        </h5>
        <p>
          We may choose to buy or sell assets and may share and/or transfer
          customer information in connection with the evaluation of and entry
          into such transactions. Also, if we (or our assets) are acquired, or
          if we go out of business, enter bankruptcy, or go through some other
          change of control, Personal Data could be one of the assets
          transferred to or acquired by a third party.
        </p>
        <h5>
          <strong className="text-white">
            Protection of Company and Others
          </strong>
        </h5>
        <p>
          We reserve the right to access, read, preserve, and disclose any
          information that we believe is necessary to comply with law or court
          order, enforce or apply our <a href="">Terms of Use </a>
          and other agreements, or protect the rights, property, or safety of
          GZI, our employees, our users, or others.
        </p>
        <h5>
          <strong className="text-white">
            Is Personal Data About Me Secure?
          </strong>
        </h5>
        <p>
          Your account is protected by a password for your privacy and security.
          If you access your account via a third party site or service, you may
          have additional or different <nobr>sign-on</nobr> protections via that
          third party site or service. You must prevent unauthorized access to
          your account and Personal Data by selecting and protecting your
          password and/or other <nobr>sign-on</nobr> mechanism appropriately and
          limiting access to your computer or device and browser by signing off
          after you have finished accessing your account.
        </p>
      </div>
    </div>
    
  );
}

export default Page7;