const Page8 = ({}) => {
  return (
    <div id="page_8">
      <div className="col-md-12 content">
        <p>
          We endeavor to protect the privacy of your account and other Personal
          Data that we hold in our records, but unfortunately, we cannot
          guarantee complete security. Unauthorized entry or use, hardware or
          software failure, and other factors, may compromise the security of
          user information at any time.
        </p>
        <h5>
          <strong className="text-white">
            How Long Do We Retain Your Personal Data?
          </strong>
        </h5>
        <p>
          We retain Personal Data about you for as long as you have an open
          account with us or as otherwise necessary to provide you Services. In
          some cases we retain Personal Data for a longer period of time, if
          doing so is necessary to comply with our legal obligations, resolve
          disputes or collect fees owed, or is otherwise permitted or required
          by applicable law, rule, or regulation or our policies (including
          without limitation this Privacy Policy and our Terms of Use). We also
          retain some information in a depersonalized or aggregated form but not
          in a way that would identify you personally.
        </p>
        <h5>
          <strong className="text-white">
            What Personal Data Can I Access?
          </strong>
        </h5>
        <p>
          Through your account settings, you may access, and, in some cases,
          edit or delete the following information you have provided to us
          (provided that we may retain a backup copy for continued use in
          accordance with this Privacy Policy):
        </p>

        <p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> First and last name </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> Username </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>Password</span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> Email address </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> Mailing address </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> Telephone number </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> Occupation and title </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              {" "}
              Employer, company, association or other institutional affiliation{" "}
            </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span> Financial or payment information </span>
          </p>
          <p className="terms-content d-flex">
            <i className="fa-solid fa-circle-check pr-2 pt-1"></i>
            <span>
              Anything you upload, post or choose to share (for example,
              comments and posts you submit publicly, etc.), which may include
              Personal Data if you include Personal Data in such content
            </span>
          </p>
        </p>
        <p>
          The information you can view, update, and delete may change as the
          Services change. If you have any questions about viewing or updating
          information we have on file about you, please contact us at
          [sean.adler@gzi.finance].
        </p>
      </div>
      <div className="col-md-12 content">
        <h5>
          <strong className="text-white">What Choices Do I Have?</strong>
        </h5>
      </div>
    </div>
  );
}

export default Page8;