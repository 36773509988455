import * as d3 from "d3";

class Sunbursts {
  constructor({ el, data, filterbase }) {
    this.el = el;
    this.data = data;
    this.filterbase = filterbase;
    this.init();
  }

  init() {
    this.container = d3.select(this.el).classed("sunbursts", true);
    this.container.selectAll("*").remove();
    console.log(this.data);

    this.data
      .filter((d) => d["Symbol"]?d["Symbol"]==this.filterbase:d["Financial Instrument"] == this.filterbase)
      .slice(0, 1)
      .forEach((data) => {
        return new Sunburst({
          el: this.container.append("div").node(),
          data,
        });
      });
  }
}

class Sunburst {
  constructor({ el, data }) {
    this.el = el;
    this.data = data;
    this.resized = this.resized.bind(this);
    this.clicked = this.clicked.bind(this);
    this.overed = this.overed.bind(this);
    this.moved = this.moved.bind(this);
    this.outed = this.outed.bind(this);
    this.init();
  }

  init() {
    this.marginRight = this.marginBottom = this.marginLeft = this.marginTop = 0;
    this.padding = 1;

    this.arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .innerRadius((d) => d.y0)
      .outerRadius((d) => d.y1 - this.padding);

    this.container = d3.select(this.el).classed("sunburst", true);

    const titleAccessor = (d) => d["Financial Instrument"];
    this.title = this.container
      .append("div")
      .attr("class", "title")
      .text(titleAccessor(this.data));

    this.svg = this.container.append("svg");
    this.g = this.svg
      .append("g")
      .attr("class", "cells")
      .attr("fill", "currentColor")
      .on("click", this.clicked);
    if (window.matchMedia("(hover: hover)")) {
      this.g
        .on("pointerover", this.overed)
        .on("pointermove", this.moved)
        .on("pointerout", this.outed);
    }

    this.tooltip = this.container.append("div").attr("class", "tip");

    this.wrangle();

    this.resizeObserver = new ResizeObserver(this.resized);
    this.resizeObserver.observe(this.el);
  }

  wrangle() {
    const valueByName = new Map(Object.entries(this.data));
    const hierarchy = {
      name: "",
      children: [
        {
          name: "Stock and Sector",
          children: [
            {
              name: "Financial Instrument",
            },
            { name: "Sector" },
            { name: "Industry" },
            { name: "Category" },
            { name: "Issuer Country" },
            { name: "Trading Currency" },
            { name: "Symbol" },
          ],
        },
        {
          name: "Pricing Information",
          children: [
            { name: "Last ($)" },
            { name: "Last" },
            { name: "Low Price" },
            { name: "High Price" },
            { name: "Change %" },
            { name: "Relative (S&P500) price percent change - YTD" },
          ],
        },
        {
          name: "Volatility and Asset Ratios",
          children: [
            { name: "Beta" },
            { name: "Quick ratio" },
            { name: "Current ratio" },
          ],
        },
        {
          name: "Valuation Data",
          children: [
            {
              name: "Market Cap",
            },
            {
              name: "Current Enterprise Value",
            },
            {
              name: "Enterprise Value to EBITDA - TTM",
            },
          ],
        },
        {
          name: "Share Ownership",
          children: [
            { name: "Shares outstanding" },
            { name: "Inst. Shares Held" },
            { name: "Insider Shares Owned" },
          ],
        },
        {
          name: "Earnings Data",
          children: [
            { name: "EPS Growth %" },
            { name: "EPS Growth (current year)" },
            { name: "EPS Change Y/Y %" },
            { name: "EPS Change TTM %" },
            { name: "EPS excluding extraordinary items - MRY" },
            { name: "EPS exc. extr. items" },
            { name: "P/E" },
            { name: "EPS Normalized" },
            { name: "P/E Normalized" },
            { name: "Earnings per share (current year)" },
            { name: "P/E (current year)" },
            { name: "Forward P/E" },
            { name: "P/E to Growth (current year)" },
          ],
        },
        {
          name: "Book Value",
          children: [
            { name: "Book Value (Common Equity) per share" },
            { name: "Price/Book" },
            { name: "Price/Book Value (current year)" },
            { name: "Book value per share (current year)" },
          ],
        },
        {
          name: "Revenue",
          children: [
            { name: "Revenue growth rate %" },
            { name: "Revenue Change TTM %" },
            { name: "Revenue Change Y/Y %" },
            { name: "Revenue/share" },
            { name: "Revenue/share - MRY" },
            { name: "Price to revenues - MRQ" },
            { name: "Revenue/Employee - TTM" },
          ],
        },
        {
          name: "Cash Flow",
          children: [
            { name: "Free Cash Flow per share - TTM" },
            { name: "Price to Free Cash Flow per share - TTM" },
            { name: "Cash per share" },
            { name: "Cash Flow per share" },
            { name: "Forward Price/Cash Flow" },
            { name: "Cash Flow per share - MRY" },
            { name: "Price to Cash Flow per share" },
            { name: "Price to Cash Flow per share - MRQ" },
            { name: "Price/Cash Flow (current year)" },
          ],
        },
        {
          name: "Debt Levels",
          children: [
            { name: "Net Debt" },
            { name: "LFI" },
            { name: "Total debt/total equity" },
            { name: "LT debt/equity" },
            { name: "Total long-term debt - MRQ" },
            { name: "Notes payable/short term debt - MRY" },
            { name: "Notes payable/short term debt - MRQ" },
            { name: "Total common equity - MRQ" },
          ],
        },
        {
          name: "Margins",
          children: [
            { name: "Net Income/Employee - TTM" },
            { name: "Net Income Available to Common" },
            { name: "Normalized" },
            { name: "Net Income available to common" },
            { name: "Net Profit Margin %" },
            { name: "Gross Margin %" },
            { name: "Operating margin %" },
            { name: "Pretax margin %" },
            { name: "Pretax margin - TTM" },
          ],
        },
        {
          name: "Issuance of Stock, Debt, and Investments",
          children: [
            { name: "Cash and short term investments - MRQ" },
            { name: "Cash from operating activities - MRY" },
            { name: "Cash from operating activities - MRQ" },
            { name: "Investing cash flow items - MRY" },
            { name: "Investing cash flow items - MRQ" },
            { name: "Financing cash flow items - MRY" },
            { name: "Financing cash flow items - MRQ" },
            { name: "Total current assets - MRY" },
            { name: "Total current assets - MRQ" },
            { name: "Total assets - MRQ" },
            { name: "Total current liabilities - MRY" },
            { name: "Total current liabilities - MRQ" },
            { name: "Total liabilities - MRQ" },
            { name: "Net issuance of debt - MRY" },
            { name: "Net issuance of debt - MRQ" },
            { name: "Net issuance of stock - MRY" },
            { name: "Net issuance of stock - MRQ" },
            { name: "Return on Equity (current year)" },
            { name: "Return on Equity %" },
            { name: "Return on investment - MRY %" },
            { name: "Return on investment %" },
            { name: "Return on average assets - MRY %" },
            { name: "Return on average assets %" },
            { name: "Imbalance" },
            { name: "Imbalance %" },
          ],
        },
        {
          name: "Dividends",
          children: [
            { name: "Payout ratio %" },
            { name: "Dividend Yield - 5 Yr. Avg." },
            { name: "Dividend Yield TTM %" },
            { name: "Dividend Yield %" },
            { name: "Dividend per share - 5 Yr. Avg." },
            { name: "Dividend Yield" },
            { name: "Dividend Date" },
            { name: "Dividends" },
            { name: "Dividends TTM" },
            { name: "Forward Dividend Yield" },
            { name: "Dividend Yield (current year)" },
            { name: "Dividends per share (current year)" },
            { name: "% of Net Liq" },
          ],
        },
        {
          name: "Volatility Ratios",
          children: [
            { name: "Implied Vol. %" },
            { name: "Hist. Vol. %" },
            { name: "Implied Vol./Hist. Vol %" },
            { name: "Opt. Implied Volatility %" },
            { name: "52 Week IV Rank" },
            { name: "52 Week HV Rank" },
            { name: "52 Week IV Percentile" },
            { name: "52 Week HV Percentile" },
            { name: "52 Week IV High" },
            { name: "52 Week HV High" },
            { name: "52 Week IV Low" },
            { name: "52 Week HV Low" },
            { name: "26 Week IV Rank" },
            { name: "26 Week HV Rank" },
            { name: "26 Week IV Percentile" },
            { name: "26 Week HV Percentile" },
            { name: "26 Week IV High" },
            { name: "26 Week HV High" },
            { name: "26 Week IV Low" },
            { name: "26 Week HV Low" },
            { name: "13 Week IV Rank" },
            { name: "13 Week HV Rank" },
            { name: "13 Week IV Percentile" },
            { name: "13 Week HV Percentile" },
            { name: "13 Week IV High" },
            { name: "13 Week HV High" },
            { name: "13 Week IV Low" },
            { name: "13 Week HV Low" },
          ],
        },
      ],
    };
    this.root = d3.hierarchy(hierarchy).count();

    const layer1Count = this.root.children.length;
    const maxLayer2Count = d3.max(this.root.children, (d) => d.children.length);

    const style = window.getComputedStyle(this.el);

    const layer1Colors = d3.quantize(
      d3.interpolateHcl(
        style.getPropertyValue("--color-blue-dark"),
        style.getPropertyValue("--color-blue-light")
      ),
      layer1Count
    );
    const layer2Colors = d3.quantize(
      d3.interpolateHcl(
        style.getPropertyValue("--color-cyan-dark"),
        style.getPropertyValue("--color-cyan-light")
      ),
      maxLayer2Count
    );

    const layerColors = {
      1: layer1Colors,
      2: layer2Colors,
    };

    this.root.each((d) => {
      d.data.value = valueByName.get(d.data.name) || "";
      if (d.depth) {
        d.color = layerColors[d.depth][d.parent.children.indexOf(d)];
      }
    });
  }

  resized() {
    this.width = this.el.clientWidth;
    this.radius = (this.width - this.marginLeft - this.marginRight) / 2;
    this.height = this.radius * 2 + this.marginTop + this.marginBottom;

    this.arc
      .padAngle((d) =>
        Math.min((d.x1 - d.x0) / 2, (2 * this.padding) / this.radius)
      )
      .padRadius(this.radius / 2);

    this.partition = d3.partition().size([2 * Math.PI, this.radius]);
    this.partition(this.root);

    this.svg.attr("viewBox", [
      -this.marginLeft - this.radius,
      -this.marginTop - this.radius,
      this.width,
      this.height,
    ]);

    this.render();
  }

  render() {
    this.cell = this.g
      .selectAll(".cell")
      .data(this.root.descendants().slice(1))
      .join((enter) =>
        enter
          .append("path")
          .attr("class", "cell")
          .attr("fill", (d) => d.color)
      )
      .attr("d", this.arc);
  }

  clicked(event) {
    if (!event.target.classList.contains("cell")) return;
    const d = d3.select(event.target).datum();
    this.container.dispatch("sunburst-click", {
      detail: {
        cellData: { [d.data.name]: d.data.value },
        sunburstData: this.data,
      },
      bubbles: true,
      cancelable: true,
    });
  }

  overed(event) {
    if (!event.target.classList.contains("cell")) return;
    const d = d3.select(event.target).datum();
    this.g.classed("is-highlighting", true);
    this.cell.classed("is-highlighted", (e) => e.descendants().includes(d));

    let tooltipContent = `<div class="tip__key">${d.data.name}</div>`;
    if (d.data.value !== "") {
      tooltipContent += `<div class="tip__value">${d.data.value}</div>`;
    }
    this.tooltip.html(tooltipContent).classed("is-visible", true);
    this.tRect = this.tooltip.node().getBoundingClientRect();
  }

  moved(event) {
    if (!event.target.classList.contains("cell")) return;
    const xMargin = 16;
    const yMargin = 16;
    let x = event.x - this.tRect.width / 2;
    if (x < xMargin) {
      x = xMargin;
    } else if (x > window.innerWidth - this.tRect.width - xMargin) {
      x = window.innerWidth - this.tRect.width - xMargin;
    }
    let y = event.y - this.tRect.height - yMargin;
    if (y < 0) {
      y = event.y + yMargin;
    }
    this.tooltip.style("transform", `translate(${x}px,${y}px)`);
  }

  outed(event) {
    if (!event.target.classList.contains("cell")) return;
    this.g.classed("is-highlighting", false);
    this.cell.classed("is-highlighted", false);
    this.tooltip.classed("is-visible", false);
  }
}

export default Sunbursts;
