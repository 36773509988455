import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./home/";
import AboutPage from "./aboutUs/";
import ContentPage from "./content";
import PricingPage from "./pricing/";
import ContactPage from "./contactUs/";
import PurchasePage from "./purchase";
import Sunburst from "../components/sunbursts/App";
import AuthPage from "./auth";
import FaqPage from "./faq/";
import TermsPage from "./termsAndCondition/";
import ContentCardJsonData from "../components/ContentCardJsonData";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import StatsSection from "./../components/StatsSection";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { ThemeProvider } from "./../util/theme.js";
import csvjson_z1 from "../data/json/csvjson_z1.json";
import csvjson_z2 from "../data/json/csvjson_z2.json";
import csvjson_z3 from "../data/json/csvjson_z3.json";
import csvjson_z22 from "../data/json/csvjson_z2.2.json";
import biotech from "../data/json/biotech.json";
import biotech_highrisk from "../data/json/biotech_highrisk.json";
import crypto from "../data/json/crypto.json";
import real_estate from "../data/json/real_estate.json";
import Z1_InteractiveBrokers from "../data/json/Z1_InteractiveBrokers.json";
import Z2_2_InteractiveBrokers from "../data/json/Z2.2_2_InteractiveBrokers.json";
import Z2_InteractiveBrokers from "../data/json/Z2_InteractiveBrokers.json";
import Z3_InteractiveBrokers from "../data/json/Z3_InteractiveBrokers.json";
import Biotech from "../data/json/biotech.json";
import Biotech_Highrisk from "../data/json/biotech_highrisk.json";
import Crypto from "../data/json/crypto.json";
import Real_Estate from "../data/json/real_estate.json";



function App(props) {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <>
            {/* <Navbar
              // color="default"
              // logo="https://mcusercontent.com/078d8cc252dde215e3779a5b2/images/7e602d33-402a-49b1-8da6-393484268996.png"
              // logoInverted="https://mcusercontent.com/078d8cc252dde215e3779a5b2/images/7e602d33-402a-49b1-8da6-393484268996.png"
            /> */}

            <Switch>
              <Route exact path='/' component={IndexPage} />
              <Route exact path='/home' component={IndexPage} />

              <Route exact path='/sunburst' component={Sunburst} />

              <Route exact path='/about-us' component={AboutPage} />

              <Route exact path='/content' component={ContentPage} />

              <Route exact path='/pricing' component={PricingPage} />

              <Route exact path='/contact-us' component={ContactPage} />

              <Route exact path='/purchase/:plan' component={PurchasePage} />

              <Route exact path='/auth/:type' component={AuthPage} />

              <Route exact path='/faq' component={FaqPage} />

              <Route exact path='/terms-and-condition' component={TermsPage} />

              <Route
                exact
                path='/post/north-america'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={csvjson_z1}
                    interactiveBroker={Z1_InteractiveBrokers}
                  />
                )}
              />
              <Route
                exact
                path='/post/western_europe'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={csvjson_z2}
                    interactiveBroker={Z2_InteractiveBrokers}
                  />
                )}
              />
              <Route
                exact
                path='/post/eastern_europe'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={csvjson_z22}
                    interactiveBroker={Z2_2_InteractiveBrokers}
                  />
                )}
              />
              <Route
                exact
                path='/post/asia'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={csvjson_z3}
                    interactiveBroker={Z3_InteractiveBrokers}
                  />
                )}
              />
              <Route
                exact
                path='/post/biotech'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={biotech}
                    interactiveBroker={Biotech}
                  />
                )}
              />
              <Route
                exact
                path='/post/highrisk-bio'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={biotech_highrisk}
                    interactiveBroker={Biotech_Highrisk}
                  />
                )}
              />

              <Route
                exact
                path='/post/crypto'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={crypto}
                    interactiveBroker={Crypto}
                  />
                )}
              />
              <Route
                exact
                path='/post/real-estate'
                component={(props) => (
                  <ContentCardJsonData
                    {...props}
                    data={real_estate}
                    interactiveBroker={Real_Estate}
                  />
                )}
              />

              <Route
                exact
                path='/firebase-action'
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
