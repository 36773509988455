import React, { useState } from "react";
import Section from "./Section";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "./../util/router.js";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useAuth } from "./../util/auth.js";
import useDarkMode from "use-dark-mode";
import { makeStyles } from "@material-ui/core/styles";
import banimg from "../assets/images/ban.png";
import logo from "../assets/images/156x114.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 96,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar(props) {
  const classes = useStyles();

  const auth = useAuth();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified
  // and we are in dark mode
  // const logo =
  //   props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <div class="banner_cont">
      <div class="fxd ">
        <nav class="navbar navbar-expand-lg znavbar-dark zbg-dark ">
          <div class="container-xl">
            {" "}
            <a class="navbar-brand" href="#">
              <img class="img-fluid" src={logo} alt="" />
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample07XL"
                aria-controls="navbarsExample07XL" aria-expanded="false" aria-label="Toggle navigation">
                {/* <!-- <span  class="navbar-toggler-icon"></span>  --> */}
                <i class="fa fa-bars"></i>
              </button>
            <div class="collapse navbar-collapse" id="navbarsExample07XL">
              <ul class="navbar-nav ml-auto text-capitalize">
                <li class="nav-item active">
                  {" "}
                  <a class="nav-link" href="#">
                    Home{" "}
                  </a>
                </li>
                <li class="nav-item  ">
                  {" "}
                  <a class="nav-link" href="#">
                    About{" "}
                  </a>{" "}
                </li>
                <li class="nav-item ">
                  {" "}
                  <a class="nav-link" href="#">
                    Content{" "}
                  </a>{" "}
                </li>
                <li class="nav-item">
                  {" "}
                  <a class="nav-link" href="#">
                    {" "}
                    Pricing{" "}
                  </a>{" "}
                </li>
                <li class="nav-item">
                  {" "}
                  <a class="nav-link" href="#">
                    {" "}
                    Sign in
                  </a>{" "}
                </li>
                <li class="nav-item">
                  {" "}
                  <a class="nav-link" href="#">
                    {" "}
                    <button class=" btn grd_btn" type="submit">
                      Sign Up
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  
  );
}

export default Navbar;
