const Page9 = ({}) => {
  return (
    <div id="page_9">
      <div className="col-md-12 content">
        <p>
          You can always opt not to disclose information to us, but keep in mind
          some information may be needed to register with us or to take
          advantage of some of our features.
        </p>
        <p>
          You may be able to add, update, or delete information as explained
          above. When you update information, however, we may maintain a copy of
          the unrevised information in our records. You may request deletion of
          your account by emailing us at [sean.adler@gzi.finance]. Some
          information may remain in our records after your deletion of such
          information from your account. We may use any aggregated data derived
          from or incorporating your Personal Data after you update or delete
          it, but not in a manner that would identify you personally.
        </p>
         <h5>
          <strong className="text-white">What About The Personal Data Of Children? </strong></h5>
        <p>
          As noted in our Terms of Use, we do not knowingly collect or solicit
          personally identifiable information from children under 13 years of
          age. If you are a child under 13 years of age, please do not attempt
          to register for or otherwise use the Services or send us any personal
          information. If we learn we have collected personal information from a
          child under 13 years of age, we will delete that information as
          quickly as possible. If you believe that a child under 13 years of age
          may have provided us personal information, please contact us at
          [sean.adler@gzi.finance].
        </p>
         <h5>
          <strong className="text-white">Will GZI Ever Change This Privacy Policy? </strong></h5>
        <p>
          We are constantly trying to improve our Services, so we may need to
          change this Privacy Policy from time to time as well. We will alert
          you to changes by placing a notice on our <a href="">website, </a>by
          sending you an email, and/or by some other means. Please note that if
          you have opted not to receive legal notice emails from us (or you have
          not provided us with your email address), those legal notices will
          still govern your use of the Services, and you are still responsible
          for reading and understanding them. If you use the Services after any
          changes to the Privacy Policy have been posted, that means you agree
          to all of the changes. Use of information we collect is subject to the
          most <nobr>up-to-date</nobr> Privacy Policy in effect.
        </p>
         <h5>
          <strong className="text-white">What If I live In California? </strong></h5>
        <p>
          California residents are entitled to ask us for a notice (i)
          identifying the categories of Personal Information which we share with
          our affiliates and/or third parties for marketing purposes, and (ii)
          providing contact information for such affiliates and/or third
          parties. If you are a California resident and would like a copy of
          this notice, please submit a written request to
          [sean.adler@gzi.finance].
        </p>
         <h5>
          <strong className="text-white">What If I Have Questions About This Policy? </strong></h5>
        <p>
          If you have any questions or concerns regarding our Privacy Policy,
          please send us a detailed message to [sean.adler@gzi.finance] and we
          will try to resolve your concerns.
        </p>
      </div>
    </div>
  );
}

export default Page9;