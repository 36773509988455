import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  Fragment,
} from "react";
import Header from "../../components/Header";
// import TestimonialSection from "../../components/home/TestimonialSection";
// import BrandSection from "../../components/home/BrandSection"
// import HeroSection from "../../components/home/HeroSection";
// import Footer from "../../components/Footer"
import Section from "../../components/home/Section";
// import Footer from "../../layouts/Footer/"

// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import AddReview from "../components/AddReview";
// import Review from "../components/Review";
// import banimg from "../assets/images/ban.png";
// import com_logo1 from "../assets/images/com_logo1.png";
// import com_logo2 from "../assets/images/com_logo2.png";
// import com_logo3 from "../assets/images/com_logo3.png";
// import gzi_full_no_audio from "../assets/images/gzi_full_no_audio.mp4";
// import Slider from "react-slick";
// import { Grid, Button } from '@material-ui/core'
// import Popup from "../components/PopUp";
// import { useAuth } from "./../util/auth.js";
// import { useRouter } from "./../util/router.js";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import firebase from "./../util/firebase";

function IndexPage(props) {
  return (
    <Fragment>
      <div>
        <div className='main-wrap'>
          <main style={{ paddingTop: "5rem" }}>
            <Header className='index_header' />
            {/* <HeroSection />
              <TestimonialSection />
  <BrandSection />*/}
            <Section />
          </main>
          {/*<Footer/>*/}
        </div>
      </div>
    </Fragment>
  );
}

export default IndexPage;

// function IndexPage(props) {
//   const [sldr, setsldr] = useState(400);
//   const myRef = useRef(null);
//   const [open,setOpen]= useState(false)
//   const router = useRouter();
//   const auth = useAuth();
//   const [modalVisible,setModalVisible]= useState(false)
//   const [ staticReviews, setStaticReviews ] = useState([]);
//   const [ reviews, setReviews ] = useState([]);
//   const [ reviewAdded, setReviewAdded ]= useState(false);

//   useEffect(()=>{
//     if(!auth.user){
//       setOpen(true)
//     }

//     const staticReviews = [
//       {
//         name: 'Shawn C.',
//         review: `"GZI's proprietary strategies enabled me to achieve a
//         return of 56.05% on my portfolio from October 2019 to
//         March 2021. I'll definitely be conserving his methods
//         for future decisions."`
//       },
//       {
//         name: 'Wonkyung Lee',
//         review: ` "I met Sean at dinner with some friends back in November
//         2019 where he introduced us to his company and its
//         trading methodology. When I asked for a name to invest
//         in, he mentioned Moderna (MRNA) as a solid pharma
//         company. At the time, it was trading at $18. My husband
//         and I talk about Sean every time we see the stock
//         achieve new highs."`
//       }
//     ]
//     setStaticReviews([...staticReviews])
//     firebase.firestore().collection("reviews").orderBy('createdAt', 'desc').limit(2).get().then(reviews => {
//       setReviews(reviews.docs.map(doc => doc.data()))
//     })
//   },[])

//   useEffect(()=>{
//     if(auth.user) {
//       let { reviewAdded:isReviewAdded } = auth.user;
//       setReviewAdded(isReviewAdded)
//     }
//   },[auth.user])

//   useEffect(() => {
//     if (window.matchMedia("(min-width: 575px)").matches) {
//       // /* The viewport is less than, or equal to, 700 pixels wide */
//       // let xa = document.querySelector(".sldr");
//       // let elemHeight = xa.offsetHeight;
//       // document.getElementsByClassName("evene")[0].style.height = sldr + "px";
//       // setsldr(elemHeight);
//     }
//     return () => {
//       // setsldr(0)
//     };
//   }, [sldr]);

//   const handleSetIsOpen = () => setOpen(!open);

//   const loggedIn = auth.user && Object.keys(auth.user).length

//   const ReviewButton = () => {
//     return loggedIn ? reviewAdded ?
//             <Button variant="contained" style={{backgroundColor: "#2e7d32", pointerEvents: "none"}} >
//             <CheckCircleIcon fontSize="small" color="string" style={{marginTop: "0px", paddingRight: '2px'}}/>
//               Review Added
//             </Button>
//             :
//             <button className=" bg-white bg_pic_left grd_btnbg_pic_left  p-3" type="button"
//             onClick={()=>setModalVisible(true)}>
//               Add Review
//             </button>
//           :  ''
//   }

//   const settings = {
//     // autoplay: true,
//     className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "60px",
//     slidesToShow: 3,
//     speed: 500,
//     arrows: true,
//     speed: 300,
//     dots: true,
//     slidesToShow:2.3,
//     slidesToScroll:1,
//     responsive: [
//       {
//         breakpoint: 990,
//         settings: {
//           className: "center",
//           centerMode: true,
//           arrows: true,
//           slidesToShow: 1.5,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 968,
//         settings: {
//           className: "center",
//           centerMode: true,
//           arrows: true,
//           slidesToShow: 1.5,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           className: "center",
//           centerMode: true,
//           arrows: true,
//           slidesToShow: 1,
//           slidesToScroll: 1.8,
//         },
//       },]
//   };

//   const handleModalClose = () =>{
//     setModalVisible(false)
//   }

//   return (
//     <Fragment>
//       <div>
//         <HeaderOne />
//         <div className="main-wrap">
//           <main>
//               <HeroSection />
//               <TestimonialSection />
//               <BrandSection />
//           </main>
//           <Footer/>
//         </div>
//       </div>
//     </Fragment>
//   );
// }

// export default IndexPage;
