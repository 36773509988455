import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useStyles from "../../assets/js/termsCustomStyle.js";
import terms_con from "../../assets/images/trms_ban.jpg";

//Sub sections
import TermsAndConditionSection from '../../components/termsAndCondition/TermsAndConditionSection';
import PrivacyAndPolicySection from '../../components/termsAndCondition/PrivacyAndPolicySection';
import NavSection from '../../components/termsAndCondition/NavSection';

const TermsAndCondition = (props) => { //TODO: Change TermsAndCondition with Terms
  const classes = useStyles();
  const [showTerms, setShowTerms] = React.useState(true);

  useEffect(()=>{
    if(window.location.href === `${window.location.origin}/terms-and-condition/#privacy-and-policy`){
      document.getElementById("privacy-and-policy").click()
    }
  },[])

  const handleTermsToggle = (status) => {
    window.scroll(0,0)
    setShowTerms(status)
  }

  return (
    <>
      <Header {...props} pageName={"terms"} bgImage={terms_con} />
      <div className="main-wrap">
        <main style={{paddingTop: '7rem'}}>
          <section className="terms-section page_bg">
            <div className="container">
              <div className="terms-section-inner">
                {/*<div className="section-title dark-section-title">
                  {
                    <h2>
                      {
                        showTerms
                          ? 'Terms & Conditions'
                          : 'Privacy Policy'
                      }
                    </h2>
                  }
                  {
                    <p>
                      {
                        showTerms
                          ? ''
                          : ''
                      }
                    </p>
                  }
                </div>*/}
                <div className="terms-section-main">
                  <div className="terms-nav terms-btn">
                    <ul className="nav terms-nav-btns">
                      <li className="terms-nav-btn"><a className={`${showTerms ? 'active' : ''}`} onClick={()=>handleTermsToggle(true)}>Terms & Conditions</a></li>
                      <li className="policy-nav-btn"><a className={`${showTerms ? '' : 'active'}`} onClick={()=>handleTermsToggle(false)}>Privacy Policy</a></li>
                    </ul>
                  </div>
                  <div className="terms-tab-content">
                    <div className="terms-tab">
                      {showTerms
                        ? ( <TermsAndConditionSection handleTermsToggle={ handleTermsToggle } /> )
                        : ( <PrivacyAndPolicySection handleTermsToggle={ handleTermsToggle }/> )
                      }
                    </div>
                  </div>
                </div>
              </div>
			      </div>
          </section>
        </main>
		  </div>
      <Footer {...props} pageName={"terms"}/>
    </>
  );
};

export default TermsAndCondition;
