import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import dataFormat from '../data/json/crypto_format.json';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding : theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true}  style={{ transitionDelay: '100ms'}} ref={ref} {...props} />;
});

function CarouselValueModal(props) {
    const handleClose = () => {
        props.onHide()
    };

  return (
      <Dialog 
      TransitionComponent={Transition}
      onClose={handleClose} 
      aria-labelledby="customized-dialog-title" 
      open={props.show}>
      {(props.data!== null) &&
        <DialogTitle className="trandingbox righttext" id="customized-dialog-title" onClose={handleClose}>
          <Typography style={{"font-size":"18px","font-weight":"bold"}}>
            {props.data.issuer_country.value} Country Details
          </Typography>
        </DialogTitle>
      }  
        <DialogContent className="trandingbox righttext" dividers>
          <Grid container spacing={2}>
              {(props.data!== null ) &&  dataFormat.map((item, index) => (
                  <Grid item xs={12} sm={6}>
                    <Typography className="abcd">
                      <span style={{"font-weight":"bold","font-size":"15px"}}>{props.data[item].label}</span> 
                      :<span style={{"color":"#333","font-size":"15px"}}>{props.data[item].value}</span>
                    </Typography>
                  </Grid>
              ))}
          </Grid>
        </DialogContent>
    </Dialog>
  );
}

export default CarouselValueModal

